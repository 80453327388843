import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ADMIN_DASHBOARD, CHANGE_PASSWORD, FORGOT_PASSWORD, PAYMENT_STATUS, PROFILE, RESET_PASSWORD, SIGNIN, SUBSCRIPTION, USER_DASHBOARD } from './constants/constants';
import { ToastContainer } from 'react-toastify';
import Signin from './pages/Auth/Signin';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import AdminDashboard from './pages/SuperAdmin/AdminDashboard';
import Header from './layout/Header';
import ChangePassword from './pages/Auth/ChangePassword';
import AvatarCanvas from './pages/AvatarCanvas/AvatarCanvas';
import UserDashboard from './pages/User/UserDashboard';
import Profile from './pages/Profile/Profile';
import PaymentStatus from './pages/PaymentStatus/PaymentStatus';
import UserLayout from './layout/UserLayout';
import Subscription from './pages/Subscription/Subscription';

function App() {
  const { user, admin, isUser, isAdmin } = useSelector((state: RootState) => state.auth);
  const isOwner = user?.user?.roles[0]?.name === "OWNER"
  let token = user?.token || admin?.token;

  return (
    <div className="App bg-light">
      <ToastContainer
        position="top-right"
        autoClose={2500}
      />
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="*" caseSensitive={false} element={<Navigate to={SIGNIN} />} />
          <Route path={SIGNIN} caseSensitive={false} element={<Signin />} />
          {/* <Route path={SIGNUP} caseSensitive={false} element={<Signup />} /> */}
          <Route path={FORGOT_PASSWORD} caseSensitive={false} element={<ForgotPassword />} />
          <Route path={RESET_PASSWORD + '/:token'} element={<ResetPassword />} />
          <Route path={`bot/:tenantCode/:avatarCode`} element={<AvatarCanvas />} />

          {token &&
            <>
              {isAdmin &&
                <>
                  <Route path="/" element={<Navigate to={ADMIN_DASHBOARD} />} />
                  <Route path={ADMIN_DASHBOARD} element={<AdminDashboard />} />
                  {/* <Route path={`bot/:tenantCode/:avatarCode`} element={<AvatarCanvas />} /> */}
                </>
              }
              {isUser &&
                // @todo: update user for forceChangePassword false after succees of CHANGE_PASSWORD
                // forceChangePassword ?


                <Route element={<UserLayout />}>
                  <Route index path="/" element={<Navigate to={USER_DASHBOARD} />} />
                  <Route path={CHANGE_PASSWORD + '/:userId'} caseSensitive={false} element={<ChangePassword />} />
                  <Route path={USER_DASHBOARD} element={<UserDashboard />} />
                  <Route path={CHANGE_PASSWORD + '/:userId'} caseSensitive={false} element={<ChangePassword />} />
                  <Route path={PROFILE} element={<Profile />} />
                  {isOwner && <Route path={SUBSCRIPTION} element={<Subscription />} />}
                  <Route path={PAYMENT_STATUS} element={<PaymentStatus />} />
                  {/* <Route path={`bot/:tenantCode/:avatarCode`} element={<AvatarCanvas />} /> */}
                </Route>
              }
            </>
          }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
