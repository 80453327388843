import AuthWrapper from './AuthWrapper'
import { Field, Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import ErrorMessageFormik from 'src/components/ErrorMessageFormik'
import { SIGNIN } from 'src/constants/constants'
import * as Yup from "yup"
import { forgetPasswordHandler, IForgotPassword } from 'src/store/slices/authSlice'
import { useAppDispatch } from 'src/store/store'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'

const ForgotPassword = () => {
    const dispatch = useAppDispatch()

    const [showLoader, setShowLoader] = useState<boolean>(false)

    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required("This field is required")
    })

    const handleForgotPassword = async (formValue: IForgotPassword) => {
        setShowLoader(true)
        const { email } = formValue
        await dispatch(forgetPasswordHandler(email))
        setShowLoader(false)
    }

    return (
        <AuthWrapper title='Forgot Password' bannerClassName='banner-3'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleForgotPassword}
            >
                <Form className='vstack gap-4'>
                    <div>
                        <div className='px-1'>
                            <label htmlFor="email" className='mb-1 fw-500'>Email</label>
                        </div>
                        <Field type="email" id="email" name="email" className="form-control form-control-lg rounded-4 fs-6" />
                        <div className='mt-1'>Password reset link will be sent to this email</div>
                        <ErrorMessageFormik name="email" />
                    </div>

                    <button className='btn btn-primary btn-lg mt-3 hstack justify-content-center gap-3' type='submit' disabled={showLoader}>
                        Get reset link
                        {showLoader && <Spinner />}
                    </button>
                </Form>
            </Formik>
            <div className='hstack gap-2 px-1'>
                <span>or</span>
                <Link to={SIGNIN}>Sign in</Link>
            </div>
        </AuthWrapper>
    )
}

export default ForgotPassword