import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { RootState, useAppDispatch } from 'src/store/store'
import * as Yup from "yup"
import { adminHandler, forceChangePwdHandler, ISignin, signInHandler, userHandler } from 'src/store/slices/authSlice'
import { ADMIN_DASHBOARD, CHANGE_PASSWORD, FULFILLED, SIGNIN, USER_DASHBOARD } from 'src/constants/constants'
import { toast } from 'react-toastify'
import AuthWrapper from './AuthWrapper'
import PasswordField from '../../components/PasswordField'
import FormField from 'src/components/FormField'
import { setActiveTab } from 'src/store/slices/settingsSlice'
import { Spinner } from 'react-bootstrap'

const Signin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isUser, isAdmin, forceChangePwd } = useSelector((state: RootState) => state.auth);

    const [showLoader, setShowLoader] = useState<boolean>(false)

    const initialValues: ISignin = {
        email: "",
        password: ""
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .email("Please enter valid email address"),
        password: Yup.string()
            .required("Password is required")
            .transform((value, originalValue) => {
                if (typeof originalValue === "string") {
                    return originalValue.trim();
                }
                return value;
            }),
    });

    const handleSignin = (formValue: ISignin) => {
        setShowLoader(true)
        dispatch(signInHandler(formValue)).then((res: any) => {
            if (res?.type === (SIGNIN + FULFILLED)) {
                const payload = res?.payload
                const isForceChangePwd = !!payload?.user.forceChngPwd
                dispatch(forceChangePwdHandler(isForceChangePwd))

                if (payload.roleUser) {
                    dispatch(userHandler(payload))
                    if (isForceChangePwd) {
                        navigate(CHANGE_PASSWORD + '/' + payload.user.uuid)
                        const today: any = new Date();
                        const dbDateObj: any = new Date(payload?.user?.lastPwdChngAt);
                        const timeDiff = Math.abs(today - dbDateObj);
                        const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                        if (payload?.user?.lastPwdChngAt === null) {
                            toast.warning("Please Change Your Password");
                        }
                        else if (dayDiff >= 90) {
                            toast.warning("You need to change your password as last password was changed about 90 days ago");
                        }
                    } else {
                        dispatch(setActiveTab('user'));
                        navigate(USER_DASHBOARD);
                        toast.success("Login Succesfull");
                    }
                } else {
                    dispatch(adminHandler(payload))
                    navigate(ADMIN_DASHBOARD)
                    toast.success("Login Succesfull");
                }
            }
            setShowLoader(false)
        });
    }

    useEffect(() => {
        if (!forceChangePwd) {
            if (isAdmin) {
                navigate(ADMIN_DASHBOARD);
            }
            if (isUser) {
                navigate(USER_DASHBOARD);
            }
        }
    }, [isAdmin, isUser, forceChangePwd]);

    return (
        <AuthWrapper title='Sign in to Avatars' bannerClassName='banner-1'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSignin}
            >
                <Form className='vstack gap-4'>
                    <FormField type="email" name="email" label="Email" />
                    <PasswordField name="password" label="Password" hasLink />
                    <button className='btn btn-primary btn-lg hstack justify-content-center gap-3 mt-3' type='submit' disabled={showLoader}>
                        Sign In
                        {showLoader && <Spinner />}
                    </button>
                </Form>
            </Formik>
            {/* <div className='hstack gap-2 px-1'>
                <span>or</span>
                <Link to={SIGNUP}>Sign up</Link>
            </div> */}
        </AuthWrapper>
    )
}

export default Signin