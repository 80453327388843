import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "src/store/store";
import Chat from "./Chat/Chat";
import WebGLNotSupported from "./Chat/WebGLNotSupported";
import {
  RefContextProvider,
  useRefContext,
} from "./Context/RefContextProvider";
import { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  checkForAvatarPwdHandler,
  getAvatarUpdateTimeHandler,
  resetAvatarDataHandler,
  setAvatarDataHandler,
  validateAvatarPwd,
} from "src/store/slices/avatarSlice";
import {
  colorOption,
  imageOption,
  skyboxOption,
} from "src/components/Modals/CreateAvatarModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { updateCamera } from "src/common/Babylon/babylonUtils";
import { Scene } from "babylonjs";
import { handleInitErrorMessage } from "src/store/slices/settingsSlice";
import { initBabylon } from "src/common/Babylon/initBabylon";
import { Humanoid } from "src/common/Babylon/Humanoid";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import PasswordField from "src/components/PasswordField";
import { Spinner } from "react-bootstrap";
import { FULFILLED, GET_AVATAR_UPDATE_TIME } from "src/constants/constants";

export type AvatarPasswordData = {
  password: string;
};

const AvatarCanvas = () => {
  const dispatch = useAppDispatch();
  let { tenantCode, avatarCode } = useParams();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { humanoidRef, currentSoundRef } = useRefContext();

  const { initErrorMessage } = useSelector((state: RootState) => state.setting);
  const { avatarData, isAvatarValidated } = useSelector(
    (state: RootState) => state.avatar
  );

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAvatarLoader, setShowAvatarLoader] = useState<boolean>(false);
  const [didInit, setDidInit] = useState<boolean>(false);
  const [scene, setScene] = useState<Scene>();
  const [canvasbg, setCanvasbg] = useState<any>({});

  const initialValues: AvatarPasswordData = { password: "" };
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
  });

  const handleAvatarLoading = (isLoading: boolean) => {
    setShowAvatarLoader(isLoading);
  };

  const loadAvatar = (payload: any) => {
    const backgroundOption = payload.backgroundOption;
    const background = payload.background;
    const bg =
      backgroundOption === skyboxOption
        ? {}
        : backgroundOption === colorOption
          ? { background: background }
          : backgroundOption === imageOption
            ? { backgroundImage: `url(${payload.background})` }
            : {};

    try {
      if (canvasRef.current) {
        const cameraConfig = payload.config.cameraConfig;
        const skyboxInput =
          backgroundOption === skyboxOption ? background : null;

        const scene = initBabylon(
          handleAvatarLoading,
          cameraConfig,
          canvasRef.current,
          skyboxInput
        );
        setScene(scene);
        humanoidRef.current = new Humanoid(scene, payload, () => {
          console.log("After import callback called!");

          // XXX: For debugging purposes:
          // @ts-ignore
          window.humanoid = humanoidRef.current;

          setDidInit(true);
          setCanvasbg(bg);
          dispatch(setAvatarDataHandler(payload));
          setShowAvatarLoader(false);
        });
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setShowAvatarLoader(false);
        dispatch(handleInitErrorMessage(error.message));
      }
    }
  };

  const handleSubmit = async (formValue: AvatarPasswordData) => {
    setShowLoader(true);
    const validateData = {
      tenantCode,
      avatarCode,
      password: formValue.password,
    };
    await dispatch(validateAvatarPwd(validateData));
    setShowLoader(false);
  };

  useEffect(() => {
    if (didInit) {
      console.warn(
        "Warning - tried to initialize twice. Will skip 2nd initialization."
      );
      return;
    }
    if (tenantCode && avatarCode) {
      const reqData = { tenantCode, avatarCode };
      if (avatarData?.code === avatarCode && isAvatarValidated) {
        dispatch(getAvatarUpdateTimeHandler(reqData)).then((res: any) => {
          if (res.type === GET_AVATAR_UPDATE_TIME + FULFILLED) {
            if (avatarData?.updatedAt === res.payload.data.updatedAt) {
              loadAvatar(avatarData);
            } else {
              dispatch(resetAvatarDataHandler());
            }
          }
        });
      } else {
        dispatch(checkForAvatarPwdHandler(reqData));
      }
    }
  }, [didInit, tenantCode, avatarCode, avatarData, isAvatarValidated]);

  if (initErrorMessage?.includes("WebGL not supported")) {
    return <WebGLNotSupported />;
  }

  return (
    <>
      <RefContextProvider>
        <div className="canvas-wrapper">
          {showAvatarLoader && (
            <div className="canvas-loader">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div
            className={`canvas-parent ${avatarData?.backgroundOption === imageOption ? "canvas-image" : ""
              }`}
            style={canvasbg}
          >
            <canvas ref={canvasRef} className="canvas-element" />
            {!showAvatarLoader && scene && (
              <>
                <Chat humanoidRef={humanoidRef} currentSoundRef={currentSoundRef} />
                {avatarData?.config?.cameraConfig && (
                  <button
                    className="btn bg-primary-subtle hstack gap-2 reset-view-btn shadow-sm"
                    onClick={() =>
                      updateCamera(avatarData?.config?.cameraConfig, scene)
                    }
                  >
                    <span className="d-none d-md-block">Reset View</span>
                    <span className="d-md-none hstack gap-1">
                      <FontAwesomeIcon icon={faStreetView} />
                      <FontAwesomeIcon icon={faArrowsRotate} />
                    </span>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </RefContextProvider>

      {!isAvatarValidated && (
        <>
          {tenantCode && avatarCode ? (
            <div className="position-absolute top-0 end-0 bottom-0 start-0">
              <div className="container mt-5 pt-5">
                <div className="hstack flex-wrap gap-3 justify-content-between mb-5">
                  <div className="fs-1 text-center">Avatars</div>
                  {
                    <div className="fs-2">
                      {avatarCode.replace("-", " ").toUpperCase()}
                    </div>
                  }
                </div>
                {/* @done : when avatar password is remembered, handled when password is changed by admin/user  */}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form className="vstack gap-3">
                    <PasswordField
                      label={`Enter password to access further`}
                      name="password"
                      autoComplete="new-password"
                    />
                    <div>
                      Password will be remembered for easy access next time
                    </div>
                    <button
                      className="btn btn-primary btn-lg hstack justify-content-center gap-3"
                      type="submit"
                      disabled={showLoader}
                    >
                      Submit
                      {showLoader ? <Spinner /> : ""}
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          ) : (
            <Navigate to={"/"} />
          )}
        </>
      )}
    </>
  );
};

export default AvatarCanvas;
