import { FC } from 'react'
import { Spinner } from 'react-bootstrap'

interface LoaderProps {
    className?: string
}
export const Loader: FC<LoaderProps> = ({ className }) => {
    return (
        <div className={`position-absolute w-100 h-100 translate-middle top-50 start-50 overflow-hidden rounded-4 z-3 ${className ?? 'bg-black bg-opacity-10'}`}>
            <div className="position-absolute translate-middle top-50 start-50">
                <Spinner />
            </div>
        </div>
    )
}
