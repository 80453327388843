import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AVATAR, AVATAR_LABEL_VALUE, AWS, CHECK_FOR_AVATAR_PWD, CREATE_AVATAR, DELETE_AVATAR, GET_ALL_ADMIN_AVATARS, GET_ALL_AVATARS, GET_AVATAR_BY_ID, GET_AVATAR_UPDATE_TIME, UPDATE_AVATAR, UPLOAD_TO_S3, VALIDATE_AVATAR_PWD } from 'src/constants/constants';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { PaginationData } from 'src/types/types';
import { ENUM_BACKGROUND_OPTION } from 'src/types/enum';
import { toast } from 'react-toastify';
import { AvatarPasswordData } from 'src/pages/AvatarCanvas/AvatarCanvas';
import { FileUpload } from 'src/components/Modals/CreateAvatarModal';

export type MorphTargets = {
    leftEyeClose: string | null
    rightEyeClose: string | null
    mouthMovement: string | null
}

export type Config = {
    cameraConfig: {
        alpha: string
        beta: string
        radius: number
        positionX: number
        positionY: number
        positionZ: number
    },
    positionOffset: {
        positionOffsetX: number
        positionOffsetY: number
        positionOffsetZ: number
    }
}

export type Animations = {
    idleAnimations: string[]
    talkingAnimations: string[]
}

export type AvatarData = {
    animations: Animations
    avatarStorageLink: string
    background: string
    backgroundOption: ENUM_BACKGROUND_OPTION
    baseAvatarId: null
    code: string
    comment: string | null
    config: Config
    createdAt: string
    createdBy: string | null
    deletedAt: string | null
    deletedBy: string | null
    faceMeshName: string
    initialAnimation: string
    initialMessage: string
    morphTargets: MorphTargets
    name: string
    openAiAstId: string
    openAiVectorStoreId: string | null
    prompt: string
    pwd: string
    role: string
    tenantId: string | null
    updatedAt: string
    updatedBy: string | null
    uuid: string
    voice: string
}
interface AvatarState {
    avatarData: AvatarData | any
    isAvatarValidated: boolean
}

// Define the initial state
const initialState: AvatarState = {
    avatarData: null,
    isAvatarValidated: false
};

export const createAvatarHandler = createAsyncThunk(
    CREATE_AVATAR,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AVATAR}/create`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllAvatarsHandler = createAsyncThunk(
    GET_ALL_AVATARS,
    async (data: PaginationData, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/all`, {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAvatarByIdHandler = createAsyncThunk(
    GET_AVATAR_BY_ID,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllAdminAvatarsHandler = createAsyncThunk(
    GET_ALL_ADMIN_AVATARS,
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/all-admin-avatars`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

// multipart formdata

export const updateAvatarHandler = createAsyncThunk(
    UPDATE_AVATAR,
    async ({ id, data }: { id: string, data: any }, { rejectWithValue }) => {
        try {
            const response = await api.put(`${AVATAR}/${id}`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const deleteAvatarHandler = createAsyncThunk(
    DELETE_AVATAR,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.delete(`${AVATAR}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);


export const uploadToS3Handler = createAsyncThunk(
    UPLOAD_TO_S3,
    async (data: FileUpload, { rejectWithValue }) => {
        try {
            const response = await api.get(AWS + '/getPutUrl', {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const checkForAvatarPwdHandler = createAsyncThunk(
    CHECK_FOR_AVATAR_PWD,
    async ({ tenantCode, avatarCode }: { tenantCode: string, avatarCode: string }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/check-for-pwd/${tenantCode}/${avatarCode}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const validateAvatarPwd = createAsyncThunk(
    VALIDATE_AVATAR_PWD,
    async (validateData: AvatarPasswordData, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AVATAR}/validate-pwd`, validateData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAvatarUpdateTimeHandler = createAsyncThunk(
    GET_AVATAR_UPDATE_TIME,
    async ({ tenantCode, avatarCode }: { tenantCode: string, avatarCode: string }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/get-update-time/${tenantCode}/${avatarCode}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAvatarLabelValue = createAsyncThunk(
    AVATAR_LABEL_VALUE,
    async (_: any, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/label-value`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(handleErrors(error));
        }
    }
)


// Create the slice
const avatarSlice = createSlice({
    name: AVATAR,
    initialState,
    reducers: {
        setAvatarDataHandler: (state, { payload }: PayloadAction<any>) => {
            state.avatarData = payload
        },
        isAvatarValidatedHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isAvatarValidated = payload
        },
        resetAvatarDataHandler: (state) => {
            state.avatarData = null
            state.isAvatarValidated = false
        },
    },
    extraReducers: (builder) => {
        builder
            //createAvatarHandler
            .addCase(createAvatarHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(createAvatarHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //updateAvatarHandler
            .addCase(updateAvatarHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(updateAvatarHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //getAllAvatarsHandler
            .addCase(getAllAvatarsHandler.pending, (state) => {
            })
            .addCase(getAllAvatarsHandler.fulfilled, (state) => {
            })
            .addCase(getAllAvatarsHandler.rejected, (state, action: PayloadAction<any>) => {
                // toast.error(action.payload)
            })
            //deleteAvatarHandler
            .addCase(deleteAvatarHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })

            //checkForAvatarPwdHandler
            .addCase(checkForAvatarPwdHandler.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload.data) {
                    state.isAvatarValidated = true
                    state.avatarData = action.payload.data
                } else {
                    state.isAvatarValidated = false
                }
            })
            .addCase(checkForAvatarPwdHandler.rejected, (state, action: PayloadAction<any>) => {
                state.isAvatarValidated = false
                toast.error(action.payload)
            })

            //validateAvatarPwd
            .addCase(validateAvatarPwd.fulfilled, (state, action: PayloadAction<any>) => {
                state.isAvatarValidated = true
                state.avatarData = action.payload.data
            })
            .addCase(validateAvatarPwd.rejected, (state, action: PayloadAction<any>) => {
                state.isAvatarValidated = false
                toast.error(action.payload)
            })
            // uploadToS3Handler
            .addCase(uploadToS3Handler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //getAvatarUpdateTimeHandler
            .addCase(getAvatarUpdateTimeHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })

    },
});

export const { isAvatarValidatedHandler, setAvatarDataHandler, resetAvatarDataHandler } = avatarSlice.actions
export default avatarSlice.reducer;
