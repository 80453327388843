import { FC, PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";

interface ICommonModal {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmBtnText?: string;
    cancelBtnText?: string;
    modalSize?: 'sm' | 'lg' | 'xl';
    showFooter?: boolean;
    scrollable?: boolean;
}

export const CommonModal: FC<PropsWithChildren<ICommonModal>> = ({
    title = '',
    isOpen,
    onClose,
    onConfirm,
    children,
    modalSize = 'lg',
    confirmBtnText = 'Save',
    cancelBtnText = 'Cancel',
    showFooter = true,
    scrollable = true
}) => {
    return (
        <Modal
            centered
            backdrop="static"
            scrollable={scrollable}
            show={isOpen}
            onHide={onClose}
            // dialogClassName={showAvaturn ? "modal-90w" : ''}
            size={modalSize}>
            <Modal.Header closeButton>
                <div className="d-flex gap-3 flex-wrap justify-content-between flex-fill">
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                    {/* <button className='btn btn-primary me-3' onClick={() => setShowAvaturn(!showAvaturn)}>{showAvaturn ? 'Hide' : 'Make'} Custom Avatar</button> */}
                </div>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {
                showFooter && (
                    <Modal.Footer className='justify-content-center'>
                        <button
                            className='btn btn-primary btn-lg hstack gap-3'
                            type='button'
                            onClick={onConfirm}
                        >
                            {/* <Spinner /> */}
                            {confirmBtnText}

                        </button>
                        <button
                            className='btn btn-primary btn-lg hstack gap-3'
                            type='button'
                            onClick={onClose}
                        >
                            {/* <Spinner /> */}
                            {cancelBtnText}
                        </button>
                    </Modal.Footer>
                )
            }
        </Modal>
    )
}