import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import authReducer, { logoutHandler } from "src/store/slices/authSlice"
import modalReducer from "src/store/slices/modalSlice"
import conversationReducer from "src/store/slices/conversationSlice"
import tenantReducer from "src/store/slices/tenantSlice"
import avatarReducer from "src/store/slices/avatarSlice"
import userReducer from "src/store/slices/userSlice"
import settingsReducer from "src/store/slices/settingsSlice"
import { useDispatch } from 'react-redux';
import rolesReducer from "src/store/slices/roleSlice"
import permissionsReducer from "src/store/slices/permissionSlice"
import subscriptionReducer from 'src/store/slices/subscriptionSlice';


//Reducers 
const appReducer = combineReducers({
    auth: authReducer,
    modal: modalReducer,
    conversation: conversationReducer,
    setting: settingsReducer,
    tenant: tenantReducer,
    avatar: avatarReducer,
    user: userReducer,
    roles: rolesReducer,
    permission: permissionsReducer,
    subscription: subscriptionReducer
})

//persist configuration
const persistConfig: any = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'avatar', 'setting'],
    // blacklist: ['conversation', 'model', 'modals'],
    // transforms: [
    //     createBlacklistFilter('conversation', ['isLoadingMessage'])
    // ]
}

const rootReducer = (state: any, action: any) => {
    if (action.type === logoutHandler.type) {
        // Clear everything except the settings slice
        storage.removeItem('persist:root');
        // window.location.href = process.env.REACT_APP_URL + SIGNIN
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

//store configuration
export const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
