import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { TableColumn } from "src/types/types";

const avatarTableColumns: TableColumn[] = [
    {
        label: "name",
        accessor: "name",
        sortable: true
    },
    {
        label: "role",
        accessor: "role",
        sortable: true
    },
    {
        label: "createdBy",
        accessor: "createdBy",
        sortable: true
    },
    {
        label: "actions",
        accessor: "actions",
        sortable: false
    }
];

export const useAvatarTableColumns = () => {
    // Redux State
    const { isAdmin } = useSelector((state: RootState) => state?.auth);

    // Hook State
    const [columns, setColumns] = useState<TableColumn[]>(avatarTableColumns);

    //Manipulate the columns
    useEffect(() => {
        // Remove the actions column.
        if (!isAdmin) {
            const updatedCols = [...columns].filter(el => el?.accessor !== 'createdBy');
            setColumns(updatedCols);
        }
        //eslint-disable-next-line
    }, [isAdmin]);

    return {
        columns
    }
}