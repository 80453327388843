/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik, FormikState } from "formik";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  createAvatarModalHandler,
  updateAvatarIdHandler,
} from "src/store/slices/modalSlice";
import { RootState, useAppDispatch } from "src/store/store";
import FormField from "../FormField";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import {
  CREATE_AVATAR,
  FULFILLED,
  GET_ALL_ADMIN_AVATARS,
  GET_AVATAR_BY_ID,
  UPDATE_AVATAR,
  UPLOAD_TO_S3,
} from "src/constants/constants";
import { toast } from "react-toastify";
import {
  createAvatarHandler,
  getAllAdminAvatarsHandler,
  getAvatarByIdHandler,
  updateAvatarHandler,
  uploadToS3Handler,
} from "src/store/slices/avatarSlice";
import ErrorMessageFormik from "../ErrorMessageFormik";
import { Engine, Scene, SceneLoader } from "babylonjs";
import {
  DEFAULT_INITIAL_MESSAGE,
  DEFAULT_PROMPT,
  DEFAULT_ROLE,
  DEFAULT_VOICE,
  MAX_PROMPT_LENGTH,
} from "src/config/modelConfig";
import { Option } from "src/types/types";
import FormSelectField from "../FormSelectField";
import {
  getDifferences,
  isValidColor,
  mbTobytes,
  validateFileSizeExceeds,
} from "src/common/commonUtils";
import Avaturn from "src/pages/Avaturn/Avaturn";
import PasswordField from "../PasswordField";
import { ENUM_BACKGROUND_OPTION } from "src/types/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faDownload,
  faSquareArrowUpRight,
  faTimesSquare,
} from "@fortawesome/free-solid-svg-icons";
import { HexColorPicker } from "react-colorful";
import { Loader } from "../Loader";
import { allVoiceOptions, VoiceCode, voiceOptions } from "src/constants/voices";

const MAX_NO_OF_AST_FILE = 3;
const MAX_FILE_SIZE = 20; //in MB
const ALLOWED_TYPES = ["application/pdf"];

export const imageOption = ENUM_BACKGROUND_OPTION.IMAGE;
export const colorOption = ENUM_BACKGROUND_OPTION.COLOR;
export const skyboxOption = ENUM_BACKGROUND_OPTION.SKYBOX;

// change this to '' if needed
const defaultBgImage =
  "https://images.unsplash.com/photo-1642336186832-522a31a4d12f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const defaultBgColor = "#ffffff";

const skyboxOptions: Option[] = [
  {
    value: "https://d33yhpagh5wzlm.cloudfront.net/scene1.env",
    label: "Scene1",
  },
  {
    value: "https://d33yhpagh5wzlm.cloudfront.net/scene2.env",
    label: "Scene2",
  },
  {
    value: "https://d33yhpagh5wzlm.cloudfront.net/scene3.env",
    label: "Scene3",
  },
];

const defaultAvatarOption: Option = {
  value: "0",
  label: "Upload new avatar",
};

export type FileUpload = {
  fileName: string;
  contentType: string;
};

export type MorphTargets = {
  leftEyeClose: string | null;
  rightEyeClose: string | null;
  mouthMovement: string | null;
};

export type Config = {
  cameraConfig: {
    alpha: string;
    beta: string;
    radius: number;
    positionX: number;
    positionY: number;
    positionZ: number;
  };
  positionOffset: {
    positionOffsetX: number;
    positionOffsetY: number;
    positionOffsetZ: number;
  };
};

export type Animations = {
  idleAnimations: string[];
  talkingAnimations: string[];
};

export type AvatarRequestData = {
  avatarStorageLink?: string;
  selectedAssistantFiles?: string[];
  name?: string;
  voice?: string;
  faceMeshName?: string;
  morphTargets?: MorphTargets;
  initialAnimation?: string;
  config?: Config;
  animations?: Animations;
  role?: string;
  initialMessage?: string;
  prompt?: string;
  pwd?: string;
  backgroundOption?: string;
  background?: string;
  baseAvatarId?: string;
  questionLimit?: number;
  filesToDelete?: string[];
};

export type AssistantFiles = File[] | undefined;
export type AstFileStorageLinks = {
  url: string;
  name: string;
  id: string;
};
export type CreateAvatar = {
  avatarFile: File | undefined;
  avatarStorageLink: string | null; //we send file name in post req and recieve file url in get req in avatarStorageLink and astFileStorageLinks[]

  assistantFiles: AssistantFiles;
  selectedAssistantFiles: string[];
  astFileStorageLinks: AstFileStorageLinks[];

  idleAnimations: string[];
  initialAnimation: string;

  talkingAnimations: string[];
  // selectedTalkingAnimations: any

  meshNames: string[]; //for selecting faceMeshName
  faceMeshName: string;

  morphTargets: string[];
  leftEyeClose: string;
  rightEyeClose: string;
  mouthMovement: string;

  ////camera config
  alpha: string;
  beta: string;
  radius: number;
  //target
  positionX: number;
  positionY: number;
  positionZ: number;

  //positionOffset
  positionOffsetX: number;
  positionOffsetY: number;
  positionOffsetZ: number;

  name: string;
  voice: VoiceCode;
  initialMessage: string;
  role: string;
  prompt: string;

  pwd: string | null;
  backgroundOption: ENUM_BACKGROUND_OPTION;
  imageInput: string;
  colorInput: string;
  skyboxInput: string;

  baseAvatarId: string | null;
  questionLimit: number;
};

export const defaultAvatarValues: CreateAvatar = {
  avatarFile: undefined,
  avatarStorageLink: null,

  assistantFiles: [],
  selectedAssistantFiles: [],
  astFileStorageLinks: [],

  idleAnimations: [],
  initialAnimation: "",

  talkingAnimations: [],
  // selectedTalkingAnimations: [],

  meshNames: [], //for selecting faceMeshName
  faceMeshName: "",

  morphTargets: [],
  leftEyeClose: "",
  rightEyeClose: "",
  mouthMovement: "",

  ////camera config
  alpha: "Math.PI / 2",
  beta: "Math.PI / 2.5",
  radius: 3,
  //target
  positionX: 0,
  positionY: 1,
  positionZ: 0,

  //positionOffset
  positionOffsetX: 0,
  positionOffsetY: 0,
  positionOffsetZ: 0,

  name: "",
  voice: DEFAULT_VOICE,
  initialMessage: DEFAULT_INITIAL_MESSAGE,
  role: DEFAULT_ROLE,
  prompt: DEFAULT_PROMPT,

  pwd: "", //null in db
  backgroundOption: imageOption,
  imageInput: defaultBgImage,
  colorInput: defaultBgColor,
  skyboxInput: skyboxOptions[0].value,

  baseAvatarId: null,
  questionLimit: 100,
};

interface CreateAvatarModalProps {
  getAllAvatarsList: () => void;
}
const CreateAvatarModal: FC<CreateAvatarModalProps> = ({
  getAllAvatarsList,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");

  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

  const { user, isUser } = useSelector((state: RootState) => state.auth);
  const { createAvatarModal, updateAvatarId, deleteModal } = useSelector(
    (state: RootState) => state.modal
  );
  const isGoogleKeyValid = isUser ? user.keys.isGoogleKeyValid : true;
  const availableVoices = isUser
    ? voiceOptions({ isGoogleKey: isGoogleKeyValid })
    : allVoiceOptions;

  const [avatarLoaded, setAvatarLoaded] = useState<boolean>(false);
  const [showAvatarLoader, setShowAvatarLoader] = useState<boolean>(true);
  const [showAvaturn, setShowAvaturn] = useState<boolean>(false);
  const [isFileInvalid, setIsFileInvalid] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [avatarError, setAvatarError] = useState<string | null>(null);
  const [allAdminAvatars, setAllAdminAvatars] = useState<any[]>([]);
  const [isExistingAvatar, setIsExistingAvatar] = useState<boolean>(false);

  const [initialValues, setInitialValues] =
    useState<CreateAvatar>(defaultAvatarValues);

  const validationSchema = Yup.object().shape({
    // avatarFile: Yup.mixed()
    //     .when('baseAvatarId', {
    //         is: (value: string) => { return value === '0' },
    //         then: (schema: any) => { ; return schema.required("This field is required") }
    //     })
    // .test(
    //     'required',
    //     'File is required',
    //     (value: any) => {
    //         if (updateAvatarId) {
    //             return true; // No validation needed if we have an avatar (update scenario)
    //         }
    //         return value;
    //     }
    // )
    // .test("fileSize", `Max allowed file size is ${MAX_FILE_SIZE}MB`,
    //     (value: any) => {
    //         const isValid = !value || (value && value.size <= mbTobytes(MAX_FILE_SIZE))

    //         // if (isValid === false) {
    //         //     setAvatarLoaded(false)
    //         // }
    //         console.log("isValid", isValid);

    //         return isValid
    //     })
    baseAvatarId: Yup.string()
      .nullable()
      .when("avatarStorageLink", (avatarStorageLink, schema) => {
        return avatarStorageLink[0] !== null
          ? schema
          : schema.required("Dropdown is required");
      }),
    avatarFile: Yup.mixed().when(
      ["baseAvatarId", "avatarStorageLink"],
      ([baseAvatarId, avatarStorageLink], schema) => {
        return avatarStorageLink !== null || baseAvatarId !== null
          ? schema.nullable()
          : schema
            .required("File is required")
            .test(
              "fileType",
              "Only files are allowed",
              (value) => !value || value instanceof File
            );
      }
    ),
    name: Yup.string()
      .trim()
      .min(3, t("validation.firstNameMin"))
      .max(50, t("validation.firstNameMax"))
      .required(t("validation.required")),
    initialMessage: Yup.string().trim().required("This field is required"),
    role: Yup.string().trim().required("This field is required"),
    prompt: Yup.string()
      .trim()
      .required("This field is required")
      .test(
        "max-characters",
        `Max ${MAX_PROMPT_LENGTH} characters are allowed`,
        (value: string) => value.length <= MAX_PROMPT_LENGTH
      ),

    pwd: Yup.string()
      .trim()
      .notRequired()
      .min(3, "Minimum three characters required"),
    backgroundOption: Yup.string().trim().required("This field is required"),
    imageInput: Yup.string()
      .trim()
      .when("backgroundOption", {
        is: (value: any) => value === imageOption,
        then: (schema: any) => schema.required("This field is required"),
      }),
    colorInput: Yup.string()
      .trim()
      .when("backgroundOption", {
        is: (value: string) => value === colorOption,
        then: (schema: any) =>
          schema
            .required("This field is required")
            .test(
              "Check Color",
              "Please enter a valid color code",
              (value: string) => isValidColor(value)
            ),
      }),
    // skyboxInput: Yup.string().trim().when('backgroundOption', {
    //     is: (value: string) => value === skyboxOption,
    //     then: (schema: any) => schema.required("This field is required")
    //         .test(
    //             "Check Extension",
    //             "Please enter a valid url (.env extension)",
    //             (value: string) => value.slice(-4) === ".env"
    //         )
    // }),
    // assistantFiles: Yup.mixed()
    //     .nullable()
    //     .notRequired()
    //     .test("file-limit", `Max ${MAX_NO_OF_AST_FILE} files allowed`, (value: any) => {
    //         return !value || value.length <= MAX_NO_OF_AST_FILE;
    //     })
    //     .test("file-Type", `Invalid FileType`, (value: any) => {
    //         if (!value) return true; // No files, valid case
    //         return Array.from(value).every((file: any) => ALLOWED_TYPES.includes(file.type));
    //     })
    //     .test("file-Size", `Max allowed file size is ${MAX_FILE_SIZE}MB`, (value: any) => {
    //         if (!value) return true; // No files, valid case
    //         return Array.from(value).every((file: any) => file.size <= mbTobytes(MAX_FILE_SIZE));
    //     })
    questionLimit: Yup.string()
      .required("Question limit is required!")
      .matches(/^[0-9]+$/, "Must be only digits"),
  });

  const handleSubmit = async (formValue: CreateAvatar) => {
    const {
      avatarFile,
      avatarStorageLink,
      assistantFiles,
      selectedAssistantFiles,
      astFileStorageLinks,
      alpha,
      beta,
      radius,
      positionX,
      positionY,
      positionZ,
      positionOffsetX,
      positionOffsetY,
      positionOffsetZ,
      idleAnimations,
      talkingAnimations,
      name,
      voice,
      faceMeshName,
      leftEyeClose,
      rightEyeClose,
      mouthMovement,
      initialAnimation,
      role,
      initialMessage,
      prompt,
      pwd,
      backgroundOption,
      imageInput,
      colorInput,
      skyboxInput,
      baseAvatarId,
      questionLimit,
    } = formValue;

    // console.log("formValue", formValue);

    const diffValues = isExistingAvatar ? defaultAvatarValues : initialValues;
    const differences = getDifferences(diffValues, formValue);
    if (differences === false) {
      hideModal();
      return;
    }

    // Validate the assistance file size.
    if (assistantFiles) {
      if (validateFileSizeExceeds(assistantFiles)) {
        return;
      }
    }

    setShowLoader(true);

    // Handle avatar update or create flow
    try {
      // @todo: store result of handleFileUpload in redux , if api fails somewhere after uploading, then on next submit, that will not be called again
      if (avatarFile) {
        console.log(`Uploading avatar file: ${avatarFile.name}...`);
        const isAvatarFileUploaded = await handleFileUpload(avatarFile);
        if (!isAvatarFileUploaded) {
          console.error(`Failed to upload avatar file: ${avatarFile.name}`);
          return;
        }
      }

      if (assistantFiles && assistantFiles.length > 0) {
        for (const assistantFile of assistantFiles) {
          if (assistantFile.size > 0) {
            console.log(`Uploading assistant file: ${assistantFile.name}...`);
            const isAssistantFileUploaded = await handleFileUpload(
              assistantFile
            );
            if (!isAssistantFileUploaded) {
              console.error(
                `Failed to upload assistant file: ${assistantFile.name}`
              );
              return;
            }
          }
        }
      }

      //determine background in use
      const background: string =
        backgroundOption === imageOption
          ? imageInput
          : backgroundOption === colorOption
            ? colorInput
            : backgroundOption === skyboxOption
              ? skyboxInput
              : "";

      // Create configuration object
      const config: Config = {
        cameraConfig: { alpha, beta, radius, positionX, positionY, positionZ },
        positionOffset: { positionOffsetX, positionOffsetY, positionOffsetZ },
      };

      // Create animations object
      const animations: Animations = { idleAnimations, talkingAnimations };

      //Create morphTargets object
      const morphTargets: MorphTargets = {
        leftEyeClose,
        rightEyeClose,
        mouthMovement,
      };

      // Prepare the data object
      const jsonData: AvatarRequestData = {};

      const values =
        baseAvatarId && !updateAvatarId ? defaultAvatarValues : initialValues;
      const configData: Config = {
        cameraConfig: {
          alpha: values.alpha,
          beta: values.beta,
          radius: values.radius,
          positionX: values.positionX,
          positionY: values.positionY,
          positionZ: values.positionZ,
        },
        positionOffset: {
          positionOffsetX: values.positionOffsetX,
          positionOffsetY: values.positionOffsetY,
          positionOffsetZ: values.positionOffsetZ,
        },
      };
      const animationsData: Animations = {
        idleAnimations: values.idleAnimations,
        talkingAnimations: values.talkingAnimations,
      };
      const morphTargetsData: MorphTargets = {
        leftEyeClose: values.leftEyeClose,
        rightEyeClose: values.rightEyeClose,
        mouthMovement: values.mouthMovement,
      };

      if (filesToDelete.length > 0) jsonData.filesToDelete = filesToDelete;
      if (avatarStorageLink && avatarStorageLink !== values.avatarStorageLink)
        jsonData.avatarStorageLink = avatarStorageLink;
      if (selectedAssistantFiles && selectedAssistantFiles.length > 0)
        jsonData.selectedAssistantFiles = selectedAssistantFiles;
      if (name !== values.name) jsonData.name = name;
      if (voice !== values.voice) jsonData.voice = voice;
      if (faceMeshName !== values.faceMeshName)
        jsonData.faceMeshName = faceMeshName;
      if (JSON.stringify(morphTargets) !== JSON.stringify(morphTargetsData))
        jsonData.morphTargets = morphTargets;
      if (initialAnimation !== values.initialAnimation)
        jsonData.initialAnimation = initialAnimation;
      if (JSON.stringify(config) !== JSON.stringify(configData))
        jsonData.config = config;
      if (JSON.stringify(animations) !== JSON.stringify(animationsData))
        jsonData.animations = animations;
      if (role !== values.role) jsonData.role = role;
      if (initialMessage !== values.initialMessage)
        jsonData.initialMessage = initialMessage;
      if (prompt !== values.prompt) jsonData.prompt = prompt;
      if (pwd && pwd.trim() !== "" && pwd !== values.pwd) jsonData.pwd = pwd;
      if (backgroundOption !== values.backgroundOption) {
        jsonData.backgroundOption = backgroundOption;
        jsonData.background = background;
      }
      if (baseAvatarId && baseAvatarId !== values.baseAvatarId)
        jsonData.baseAvatarId = baseAvatarId;
      if (questionLimit && questionLimit !== values.questionLimit)
        jsonData.questionLimit = questionLimit;

      // console.log("jsonData", jsonData);

      await processAvatar(jsonData);
    } catch (err: any) {
      console.error("Error during form submission:", err);
    } finally {
      setShowLoader(false);
    }
  };

  // Function to handle the avatar upload logic
  const handleFileUpload = async (file: File) => {
    const fileName = file.name.replaceAll(" ", "_");
    const isGLB = fileName.split(".")[fileName.split(".").length - 1] === "glb";
    const contentType = isGLB ? "model/gltf-binary" : file.type;
    const data: FileUpload = { fileName, contentType };
    // return true
    const res = await dispatch(uploadToS3Handler(data));
    if (res.type === UPLOAD_TO_S3 + FULFILLED) {
      const putUrl = res.payload.data;
      const response = await fetch(putUrl, {
        method: "PUT",
        headers: { "Content-Type": contentType },
        body: file,
      });
      if (response.ok) return true;
    } else {
      return false;
    }
  };

  // Process update or create based on avatar ID
  const processAvatar = async (data: any) => {
    const action = updateAvatarId ? updateAvatarHandler : createAvatarHandler;
    const actionData = updateAvatarId
      ? { id: updateAvatarId, data: data }
      : data;

    const res = await dispatch(action(actionData));
    if (
      res.type ===
      (updateAvatarId ? UPDATE_AVATAR : CREATE_AVATAR) + FULFILLED
    ) {
      handleSuccess();
    }
    setShowLoader(false);
  };

  const handleSuccess = () => {
    hideModal();
    getAllAvatarsList();
  };

  const hideModal = () => {
    setAvatarError(null);
    dispatch(updateAvatarIdHandler(null));
    dispatch(createAvatarModalHandler(false));
    setInitialValues(defaultAvatarValues);
    setAvatarLoaded(false);
    setShowAvatarLoader(false);
    setIsExistingAvatar(false);
    setFilesToDelete([]);
  };

  // @todo : maxfile file validation for avatar glb file, also invalid file validation
  // @todo : maxfile file validation for assiatnt pdf and multiple pdf upload and edit wrt openai and aws
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    setFieldTouched: (
      field: string,
      touched: boolean,
      shouldValidate?: boolean
    ) => void,
    setFieldError: (field: string, message: string) => void
  ) => {
    const file = event.currentTarget.files?.[0] || null;
    setFieldValue("avatarFile", file);
    setFieldValue("baseAvatarId", null);
    // setFieldTouched('avatarFile', true); // Mark field as touched for validation
    if (file && file.size <= mbTobytes(MAX_FILE_SIZE)) {
      const url = URL.createObjectURL(file); // Create a URL for the file
      const scene = new Scene(
        new Engine(document.createElement("canvas"), true)
      );

      // Load the GLB file using Babylon.js
      SceneLoader.ImportMesh(
        "",
        url,
        "",
        scene,
        (meshes, particleSystems, skeletons, animationGroups) => {
          // console.log("meshes", meshes);

          setFieldValue("name", file.name.split(".")[0]);
          const allAnimations = animationGroups.map(
            (animation) => animation.name
          ); // Get animation names

          const allIdleAnimations = allAnimations.filter((animation) =>
            animation.toLowerCase().startsWith("idle")
          ); // Get animation names
          const idleAnimations =
            allIdleAnimations.length > 0 ? allIdleAnimations : allAnimations;

          setFieldValue(
            "idleAnimations",
            idleAnimations.length > 0 ? idleAnimations : allAnimations
          );
          setFieldValue("initialAnimation", idleAnimations[0] || "");

          const allTalkingAnimations = allAnimations.filter((animation) =>
            animation.toLowerCase().startsWith("talk")
          ); // Get animation names
          const talkingAnimations =
            allTalkingAnimations.length > 0
              ? allTalkingAnimations
              : allAnimations;
          setFieldValue("talkingAnimations", talkingAnimations);
          // setFieldValue('selectedTalkingAnimations', idleAnimations[0] || '');

          const allMeshNames = meshes.map((meshes) => meshes.id); // Get meshes names
          allMeshNames.filter((meshName) =>
            meshName.toLowerCase().includes("head")
          );
          const faceMeshName = allMeshNames[allMeshNames.length > 1 ? 5 : 0];
          setFieldValue("meshNames", allMeshNames);
          setFieldValue("faceMeshName", faceMeshName); //should have eye and mouthMovement movement

          let morphTargets: string[] = [];
          // Iterate through all the loaded meshes
          meshes.forEach((mesh) => {
            console.log(`found for mesh: ${mesh.id}`);
            if (mesh.id === faceMeshName) {
              if (mesh.morphTargetManager) {
                // If the mesh has a morphTargetManager, it means it has morph targets
                const morphTargetManager = mesh.morphTargetManager;
                console.log(`Morph targets for mesh: ${mesh.id}`);

                // Get the number of morph targets
                const numTargets = morphTargetManager.numTargets;

                for (let i = 0; i < numTargets; i++) {
                  const morphTarget = morphTargetManager.getTarget(i); // Access each morph target
                  console.log(
                    `Morph Target Index: ${i}, Name: ${morphTarget.name}`
                  );
                  morphTargets.push(morphTarget.name);
                }
                setAvatarError(null);
              } else {
                setAvatarError("No morph targets found");
                console.log(`No morph targets found for mesh: ${mesh.id}`);
              }
            }
          });
          const findMouth = morphTargets.filter(
            (target: string) =>
              target.toLowerCase().includes("mth") ||
              target.toLowerCase().includes("mouth")
          );
          const findEye = morphTargets.filter((target: string) =>
            target.toLowerCase().includes("eye")
          );
          setFieldValue("morphTargets", morphTargets);
          setFieldValue("leftEyeClose", findEye[0]);
          setFieldValue("rightEyeClose", findEye[0]);
          setFieldValue("mouthMovement", findMouth[0]);
          setFieldValue("avatarStorageLink", file.name.replaceAll(" ", "_")); //s3 link
          setIsFileInvalid(false);
          setAvatarLoaded(true);
          setFieldTouched("avatarFile", true);
        },
        undefined,
        (scene, errorMessage, errorObj) => {
          setAvatarError("Error loading .glb file");
          console.error(
            "Error loading mesh:",
            errorMessage,
            "\nerrorObj:",
            errorObj
          );
          setIsFileInvalid(true);
          // setFieldValue('avatarFile', '');
          // setFieldError('avatarFile', 'Invalid file')
          setAvatarLoaded(false);
        },
        ".glb"
      );
    } else {
      // setFieldTouched('avatarFile', true);
      // setFieldError('avatarFile', `Max allowed file size is ${MAX_FILE_SIZE}MB`)
      // setAvatarLoaded(false)
    }
  };

  const getAvatarDetails = (
    updateAvatarId: string,
    isChangeAvatar?: boolean
  ) => {
    setShowAvatarLoader(true);
    dispatch(getAvatarByIdHandler(updateAvatarId)).then((res: any) => {
      if (res.type === GET_AVATAR_BY_ID + FULFILLED) {
        // console.log("res", res.payload);
        const payload = res.payload.data;
        const setOptionValue = (option: string, defaultValue: string) => {
          return payload.backgroundOption === option
            ? payload.background
            : defaultValue;
        };

        const url = payload.avatarStorageLink;
        const scene = new Scene(
          new Engine(document.createElement("canvas"), true)
        );

        // Load the GLB file using Babylon.js
        SceneLoader.ImportMesh(
          "",
          url,
          "",
          scene,
          (meshes) => {
            // console.log("meshes", meshes);

            const allMeshNames = meshes.map((meshes) => meshes.id); // Get meshes names

            let morphTargets: string[] = [];
            // Iterate through all the loaded meshes
            meshes.forEach((mesh) => {
              console.log(`found for mesh: ${mesh.id}`);
              if (mesh.id === payload.faceMeshName) {
                if (mesh.morphTargetManager) {
                  // If the mesh has a morphTargetManager, it means it has morph targets
                  const morphTargetManager = mesh.morphTargetManager;
                  console.log(`Morph targets for mesh: ${mesh.id}`);

                  // Get the number of morph targets
                  const numTargets = morphTargetManager.numTargets;

                  for (let i = 0; i < numTargets; i++) {
                    const morphTarget = morphTargetManager.getTarget(i); // Access each morph target
                    console.log(
                      `Morph Target Index: ${i}, Name: ${morphTarget.name}`
                    );
                    morphTargets.push(morphTarget.name);
                  }
                  setAvatarError(null);
                } else {
                  setAvatarError("No morph targets found");
                  console.log(`No morph targets found for mesh: ${mesh.id}`);
                }
              }
            });

            const data: CreateAvatar = {
              avatarFile: undefined,
              avatarStorageLink: payload.avatarStorageLink,

              assistantFiles: undefined,
              selectedAssistantFiles: [],
              astFileStorageLinks: payload.astFileStorageLinks,

              idleAnimations: payload.animations.idleAnimations,
              initialAnimation: payload.initialAnimation,

              talkingAnimations: payload.animations.talkingAnimations,
              // selectedTalkingAnimations: [],

              meshNames: allMeshNames,
              faceMeshName: payload.faceMeshName,

              morphTargets: morphTargets,
              leftEyeClose: payload.morphTargets.leftEyeClose,
              rightEyeClose: payload.morphTargets.rightEyeClose,
              mouthMovement: payload.morphTargets.mouthMovement,

              ////camera config
              alpha: payload.config.cameraConfig.alpha,
              beta: payload.config.cameraConfig.beta,
              radius: payload.config.cameraConfig.radius,
              //target
              positionX: payload.config.cameraConfig.positionX,
              positionY: payload.config.cameraConfig.positionY,
              positionZ: payload.config.cameraConfig.positionZ,

              //positionOffset
              positionOffsetX: payload.config.positionOffset.positionOffsetX,
              positionOffsetY: payload.config.positionOffset.positionOffsetY,
              positionOffsetZ: payload.config.positionOffset.positionOffsetZ,

              name: isChangeAvatar ? "" : payload.name,
              voice: payload.voice,
              initialMessage: payload.initialMessage,
              role: payload.role,
              prompt: payload.prompt,

              pwd: payload.pwd ?? "",
              backgroundOption: payload.backgroundOption,
              imageInput: setOptionValue(imageOption, defaultBgImage),
              colorInput: setOptionValue(colorOption, defaultBgColor),
              skyboxInput: setOptionValue(skyboxOption, skyboxOptions[0].value),

              baseAvatarId: isChangeAvatar
                ? updateAvatarId
                : payload.baseAvatarId,
              questionLimit: payload?.questionLimit,
            };

            setInitialValues(data);
            setAvatarLoaded(true);
            setShowAvatarLoader(false);
          },
          undefined,
          (scene, errorMessage, errorObj) => {
            setAvatarError("Error loading .glb file");
            console.error(
              "Error loading mesh:",
              errorMessage,
              "\nerrorObj:",
              errorObj
            );
            setIsFileInvalid(true);
            // setFieldValue('avatarFile', '');
            // setFieldError('avatarFile', 'Invalid file')
            setAvatarLoaded(false);
            setShowAvatarLoader(false);
          },
          ".glb"
        );
      } else {
        setShowAvatarLoader(false);
      }
    });
  };

  const syncColorInputs =
    (setFieldValue: (field: string, value: string) => void) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setFieldValue("colorInput", value);
      };

  const syncColorInput = (
    color: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    setFieldValue("colorInput", color);
  };

  const getAllAdminAvatars = () => {
    dispatch(getAllAdminAvatarsHandler()).then((res: any) => {
      if (res.type === GET_ALL_ADMIN_AVATARS + FULFILLED) {
        const data = res.payload.data.map((item: any) => {
          return {
            value: item.uuid,
            label: item.name,
          };
        });
        const newData = [defaultAvatarOption, ...data];
        setAllAdminAvatars(newData);
      }
    });
  };

  useEffect(() => {
    if (createAvatarModal && updateAvatarId) {
      getAvatarDetails(updateAvatarId);
    } else {
      setShowAvatarLoader(false);
    }
  }, [createAvatarModal, updateAvatarId]);

  useEffect(() => {
    if (createAvatarModal) {
      getAllAdminAvatars();
    }
  }, [createAvatarModal]);

  const handleChangeAdminAvatar = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue("avatarFile", undefined);
    if (event.target.value === "0") {
      if (updateAvatarId) {
        getAvatarDetails(updateAvatarId);
      } else {
        setAvatarLoaded(false);
        setInitialValues(defaultAvatarValues);
      }
    } else {
      setIsExistingAvatar(true);
      getAvatarDetails(event.target.value, true);
    }
  };

  const handleChangeAstFiles = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const files: any = event.currentTarget.files;
    setFieldValue("assistantFiles", files);
    const fileNames: string[] = [];
    for (const file of files) {
      fileNames.push(file.name.replaceAll(" ", "_"));
    }
    setFieldValue("selectedAssistantFiles", fileNames);
  };

  const removeAllNewFiles = (
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue("selectedAssistantFiles", []);
    setFieldValue("assistantFiles", undefined);
  };

  const removeSpecificNewFile = (
    index: number,
    valuesAssistantFiles: AssistantFiles,
    valuesSelectedAssistantFiles: string[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (valuesAssistantFiles) {
      const fileArray = Array.from(valuesAssistantFiles);

      // Filter out the file at the specified index
      const updatedFileArray = fileArray.filter((_, i) => i !== index);
      // Convert the array back to a new FileList
      const dataTransfer = new DataTransfer();
      updatedFileArray.forEach((file: any) => dataTransfer.items.add(file));

      const filteredAssistantFiles = dataTransfer.files;
      setFieldValue("assistantFiles", filteredAssistantFiles);

      const filteredSelectedAssistantFiles =
        valuesSelectedAssistantFiles.filter((_, i) => i !== index);
      setFieldValue("selectedAssistantFiles", filteredSelectedAssistantFiles);
    }
  };

  const removeAllOldFiles = (
    setFieldValue: (field: string, value: any) => void
  ) => {
    const allFilesToDelete = initialValues.astFileStorageLinks.map(
      (link: AstFileStorageLinks) => link.id
    );
    setFilesToDelete(allFilesToDelete);
    setFieldValue("astFileStorageLinks", []);
  };

  const removeSpecificOldFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    valuesAstFileStorageLinks: AstFileStorageLinks[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setFilesToDelete([...filesToDelete, id]);
    const filteredAstFileStorageLinks = valuesAstFileStorageLinks.filter(
      (link: AstFileStorageLinks) => link.id !== id
    );
    setFieldValue("astFileStorageLinks", filteredAstFileStorageLinks);
  };

  const handleResetForm = (
    resetForm: (
      nextState?: Partial<FormikState<CreateAvatar>> | undefined
    ) => void
  ) => {
    setFilesToDelete([]);
    resetForm({
      values: updateAvatarId ? initialValues : defaultAvatarValues,
    });
  };

  return (
    <Modal
      centered
      backdrop="static"
      scrollable
      show={createAvatarModal}
      onHide={hideModal}
      className={deleteModal ? "has-modal-above" : ""}
      dialogClassName={showAvaturn ? "modal-90w" : ""}
      size="lg"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ resetForm, setFieldValue, values, setFieldError }) => (
          <Form className="modal-content">
            <Modal.Header closeButton>
              <div className="d-flex gap-3 flex-wrap justify-content-between flex-fill">
                <Modal.Title>
                  {updateAvatarId
                    ? "Update Avatar details"
                    : "Enter Avatar details"}
                </Modal.Title>
                <button
                  className="btn btn-primary me-3"
                  onClick={() => setShowAvaturn(!showAvaturn)}
                >
                  {showAvaturn ? "Hide" : "Make"} Custom Avatar
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className={showAvatarLoader ? "overflow-hidden" : ""}>
              {showAvatarLoader && <Loader className="bg-light" />}
              {showAvaturn ? (
                <Avaturn />
              ) : (
                <div className="vstack gap-4">
                  {updateAvatarId && initialValues.avatarStorageLink && (
                    <div>
                      <label className="custom-form-label">
                        Avatar File in use
                      </label>
                      <a
                        href={initialValues.avatarStorageLink}
                        target="_blank"
                        className="custom-form-control lh-lg position-relative disabled"
                        rel="noreferrer"
                      >
                        <GetFileName url={initialValues.avatarStorageLink} />
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="position-absolute translate-middle-y top-50 end-0 me-3 text-info"
                          title="download"
                        />
                      </a>
                    </div>
                  )}

                  {/* @todo: if avatar file then clear dropdown and viceversa*/}
                  <div className="d-flex gap-3 flex-wrap">
                    {isUser && allAdminAvatars.length > 0 && (
                      <div className="flex-50">
                        <label
                          htmlFor="baseAvatarId"
                          className="custom-form-label"
                        >
                          Choose from existing avatars
                        </label>
                        <div className="position-relative">
                          <Field
                            as="select"
                            name="baseAvatarId"
                            className="custom-form-control"
                            onChange={(event: any) =>
                              handleChangeAdminAvatar(event, setFieldValue)
                            }
                          >
                            {allAdminAvatars.map(
                              (option: Option, index: number) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </Field>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="position-absolute translate-middle-y top-50 end-0 me-3 text-black-50"
                          />
                        </div>
                      </div>
                    )}
                    {!values.baseAvatarId && (
                      <div className="flex-50">
                        <label
                          htmlFor="avatarFile"
                          className="custom-form-label"
                        >
                          Upload a new avatar file (.glb)
                        </label>
                        <Field name="avatarFile">
                          {({ field }: { field: any }) => (
                            <input
                              {...field}
                              type="file"
                              id="avatarFile"
                              className="custom-form-control"
                              accept=".glb"
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  setFieldValue,
                                  field.onBlur,
                                  setFieldError
                                )
                              }
                              value={undefined}
                            />
                          )}
                        </Field>
                        <ErrorMessageFormik name="avatarFile" />
                        {avatarError && (
                          <div className="text-info mt-1 px-1">
                            {avatarError}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <FormField type="text" name="name" label="Avatar Name" />
                  {availableVoices.length > 0 && (
                    <FormSelectField
                      name="voice"
                      label="Voice"
                      options={availableVoices}
                    />
                  )}
                  <FormField
                    type="text"
                    name="initialMessage"
                    label="Initial Message"
                  />
                  <FormField type="text" name="role" label="Role" />
                  <div>
                    <label htmlFor="prompt">Prompt</label>
                    <div className="position-relative">
                      <Field
                        as="textarea"
                        rows={5}
                        name="prompt"
                        className="custom-form-control"
                      />
                      <small className="position-absolute bottom-0 end-0 me-3 mb-1 lh-1 text-muted bg-white">
                        <span
                          className={
                            values.prompt.length > MAX_PROMPT_LENGTH
                              ? "text-danger"
                              : ""
                          }
                        >
                          {values.prompt.length}
                        </span>
                        <span>/{MAX_PROMPT_LENGTH}</span>
                      </small>
                    </div>
                    <ErrorMessageFormik name="prompt" />
                  </div>
                  <div>
                    <label className="custom-form-label">
                      Assistant Files (.pdf){" "}
                    </label>
                    <Field name="assistantFiles">
                      {({ field }: { field: any }) => {
                        const length = values.selectedAssistantFiles?.length;
                        const isSingle = length === 1;
                        return (
                          <>
                            <label
                              htmlFor="assistantFiles"
                              className="custom-form-control py-0 hstack gap-2 cursor"
                            >
                              <div>Choose Files</div>
                              <div className="border-end align-self-stretch me-2 ms-1">
                                &nbsp;
                              </div>
                              <div>
                                {length
                                  ? `${values.selectedAssistantFiles?.length} ${isSingle ? "file" : "files"
                                  } chosen`
                                  : "No file chosen"}
                              </div>
                            </label>
                            <input
                              {...field}
                              type="file"
                              id="assistantFiles"
                              className="custom-form-control visually-hidden"
                              accept=".pdf"
                              multiple
                              onChange={(event) =>
                                handleChangeAstFiles(event, setFieldValue)
                              }
                              value={undefined}
                            />
                            {/* <small className='ms-1 mt-1 text-muted'>You can upload multiple assitant files</small> */}
                          </>
                        );
                      }}
                    </Field>
                    <ErrorMessageFormik name="assistantFiles" />
                  </div>

                  {/* @todo: use DeleteModal component for all file deleting ops  */}
                  {/* new files */}
                  {values.selectedAssistantFiles?.length > 0 && (
                    <>
                      <div>
                        <div className="hstack gap-3 flex-wrap justify-content-between mb-2">
                          <label className="custom-form-label mb-0">
                            New Assistant Files
                          </label>
                          <div className="hstack gap-3 flex-wrap">
                            {/* {values.assistantFiles && values.assistantFiles.length > 0 && initialValues?.selectedAssistantFiles?.length > 0 && <button type='button' className='btn btn-sm btn-dark' onClick={removeNewFilesAstAlert}>Remove new files</button>} */}
                            <button
                              type="button"
                              className="btn btn-sm btn-dark"
                              onClick={() => removeAllNewFiles(setFieldValue)}
                            >
                              Remove all new files
                            </button>
                          </div>
                        </div>
                        <div className="hstack gap-3 flex-wrap">
                          {values.selectedAssistantFiles.map(
                            (fileName: string, index: number) => {
                              const currFile = (
                                values?.assistantFiles as unknown as FileList
                              )?.item(index);
                              return (
                                <div
                                  key={fileName + "/" + index}
                                  className="d-flex flex-column border-danger"
                                >
                                  <div
                                    className={`w-auto custom-form-control lh-lg position-relative disabled pe-5 z-0 d-flex align-items-center ${currFile &&
                                      validateFileSizeExceeds(currFile)
                                      ? "border-danger"
                                      : ""
                                      }`}
                                  >
                                    <div className="pe-4">{fileName}</div>
                                    <button
                                      className="btn p-0 m-0 d-inline position-absolute translate-middle-y top-50 end-0 me-3 text-danger z-3 lh-1 rounded-0"
                                      type="button"
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimesSquare}
                                        onClick={() =>
                                          removeSpecificNewFile(
                                            index,
                                            values.assistantFiles,
                                            values.selectedAssistantFiles,
                                            setFieldValue
                                          )
                                        }
                                        title="remove"
                                        size="lg"
                                      />
                                    </button>
                                  </div>
                                  <p
                                    className={`text-danger mb-0 ${currFile &&
                                      validateFileSizeExceeds(currFile)
                                      ? ""
                                      : "invisible"
                                      }`}
                                  >
                                    File size is too large
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {/* old files */}
                  {values.astFileStorageLinks?.length > 0 && (
                    <>
                      <div>
                        <div className="hstack gap-3 flex-wrap justify-content-between mb-2">
                          <label className="custom-form-label mb-0">
                            Selected Assistant Files
                          </label>
                          <div className="hstack gap-3 flex-wrap">
                            <button
                              type="button"
                              className="btn btn-sm btn-dark"
                              onClick={() => removeAllOldFiles(setFieldValue)}
                            >
                              Remove all old files
                            </button>
                          </div>
                        </div>
                        <div className="hstack gap-3 flex-wrap">
                          {values.astFileStorageLinks.map(
                            (item: AstFileStorageLinks, index: number) => {
                              return (
                                <a
                                  key={item + "/" + index}
                                  href={item.url}
                                  target="_blank"
                                  className="w-auto custom-form-control lh-lg position-relative disabled pe-5 z-0"
                                >
                                  <div className="pe-4">{item.name}</div>
                                  <FontAwesomeIcon
                                    icon={faSquareArrowUpRight}
                                    className="position-absolute translate-middle-y top-50 end-0 me-5 text-info z-0"
                                    title="view"
                                    size="lg"
                                  />
                                  <button
                                    className="btn p-0 m-0 d-inline position-absolute translate-middle-y top-50 end-0 me-3 text-danger z-3 lh-1 rounded-0"
                                    type="button"
                                  >
                                    <FontAwesomeIcon
                                      icon={faTimesSquare}
                                      onClick={(e: any) =>
                                        removeSpecificOldFile(
                                          e,
                                          item.id,
                                          values.astFileStorageLinks,
                                          setFieldValue
                                        )
                                      }
                                      title="remove"
                                      size="lg"
                                    />
                                  </button>
                                </a>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <PasswordField name="pwd" label="Password (optional)" />
                  <FormField
                    type="text"
                    name="questionLimit"
                    label="Question Limit"
                  />

                  <div className="vstack gap-3">
                    <div className="d-flex gap-3 flex-wrap">
                      <label
                        htmlFor="backgroundOption"
                        className="custom-form-label mb-0"
                      >
                        Background
                      </label>
                      <div className="hstack gap-3">
                        <div className="form-check">
                          <Field
                            type="radio"
                            name="backgroundOption"
                            value={imageOption}
                            id="imageOption"
                            className="form-check-input cursor"
                          />
                          <label
                            className="form-check-label cursor"
                            htmlFor="imageOption"
                          >
                            Image url
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            type="radio"
                            name="backgroundOption"
                            value={colorOption}
                            id="colorOption"
                            className="form-check-input cursor"
                          />
                          <label
                            className="form-check-label cursor"
                            htmlFor="colorOption"
                          >
                            Color
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            type="radio"
                            name="backgroundOption"
                            value={skyboxOption}
                            id="skyboxOption"
                            className="form-check-input cursor"
                          />
                          <label
                            className="form-check-label cursor"
                            htmlFor="skyboxOption"
                          >
                            Skybox
                          </label>
                        </div>
                        <ErrorMessageFormik name="backgroundOption" />
                      </div>
                    </div>

                    {values.backgroundOption === imageOption && (
                      <FormField
                        type="text"
                        name="imageInput"
                        label="Image url"
                      />
                    )}
                    {values.backgroundOption === colorOption && (
                      <div className="hstack gap-3">
                        <Field
                          className="custom-form-control"
                          type="text"
                          name="colorInput"
                          onChange={syncColorInputs(setFieldValue)}
                        />
                        <HexColorPicker
                          color={values.colorInput}
                          onChange={(color) =>
                            syncColorInput(color, setFieldValue)
                          }
                        />
                        <ErrorMessageFormik name="colorInput" />
                      </div>
                    )}
                    {values.backgroundOption === skyboxOption && (
                      <FormSelectField
                        name="skyboxInput"
                        label="Skybox"
                        options={skyboxOptions}
                      />
                    )}
                  </div>

                  {avatarLoaded && (
                    <>
                      {values.idleAnimations.length > 0 && (
                        <FormSelectField
                          name="initialAnimation"
                          label="Initial Animation"
                          options={values.idleAnimations}
                        />
                      )}
                      {/* {values.talkingAnimations.length > 0 &&
                                                    <FormSelectField name='selectedTalkingAnimations' label='Talking Animation' options={values.talkingAnimations} />
                                                } */}
                      {values.meshNames.length > 0 && (
                        <FormSelectField
                          name="faceMeshName"
                          label="Face mesh name"
                          options={values.meshNames}
                        />
                      )}

                      {values.morphTargets.length > 0 && (
                        <div className="hstack gap-3">
                          <FormSelectField
                            name="leftEyeClose"
                            label="LeftEyeClose"
                            options={values.morphTargets}
                          />
                          <FormSelectField
                            name="rightEyeClose"
                            label="RightEyeClose"
                            options={values.morphTargets}
                          />
                          <FormSelectField
                            name="mouthMovement"
                            label="MouthMovement"
                            options={values.morphTargets}
                          />
                        </div>
                      )}

                      {/* should have live model preview while setting below options */}
                      <div className="hstack gap-1 mt-3">
                        <label
                          htmlFor="cameraPositions"
                          className="custom-form-label mb-0"
                        >
                          Camera Positions
                        </label>
                        {/* @todo: show/load mini 3d model with live updates for below camera positions , to educate user about these options */}
                        {/* <OverlayTrigger
                                                        trigger="click"
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={popover}
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </OverlayTrigger> */}
                      </div>
                      <div className="hstack gap-3">
                        <FormField
                          className="flex-fill"
                          type="text"
                          name="alpha"
                          label="Alpha"
                          disabled
                        />
                        <FormField
                          className="flex-fill"
                          type="text"
                          name="beta"
                          label="Beta"
                          disabled
                        />
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="radius"
                          label="Radius"
                        />
                      </div>
                      <div className="hstack gap-3">
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="positionX"
                          label="Pos x"
                        />
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="positionY"
                          label="Pos y"
                        />
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="positionZ"
                          label="Pos z"
                        />
                      </div>
                      <div className="hstack gap-3">
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="positionOffsetX"
                          label="Pos Offset x"
                        />
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="positionOffsetY"
                          label="Pos Offset y"
                        />
                        <FormField
                          className="flex-fill"
                          type="number"
                          name="positionOffsetZ"
                          label="Pos Offset z"
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              {!showAvaturn && (
                <>
                  <button
                    className="btn btn-light text-primary btn-lg hstack gap-3"
                    type="button"
                    disabled={showLoader}
                    onClick={() => handleResetForm(resetForm)}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary btn-lg hstack justify-content-center gap-3 me-3"
                    type="submit"
                    disabled={showLoader}
                  >
                    {updateAvatarId ? "Save Changes" : "Create Avatar"}
                    {showLoader && <Spinner />}
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateAvatarModal;

interface GetFileNameProps {
  url: string;
  className?: string;
}
const GetFileName: FC<GetFileNameProps> = ({ url, className }) => {
  return (
    <div className={className}>{url.split("/")[url.split("/").length - 1]}</div>
  );
};
