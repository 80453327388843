import { useEffect, useState } from "react"
import Actions from "src/components/Actions"
import CreateAvatarModal from "src/components/Modals/CreateAvatarModal"
import CreateUserModal from "src/components/Modals/CreateUserModal"
import { DELETE_AVATAR, DELETE_ROLE, DELETE_USER, FULFILLED, GET_ALL_AVATARS, GET_ALL_ROLES_AND_PERMS, GET_ALL_ROLES, GET_ALL_USERS, GET_CONVERSATION_GROUP_BY_THREAD, AVATAR_LABEL_VALUE } from "src/constants/constants"
import { createAvatarModalHandler, createRoleModalHandler, createUserModalHandler, updateRoleIdHandler, updateUserIdHandler, updateAvatarIdHandler } from "src/store/slices/modalSlice"
import { deleteTenantUserByUuid, getAllUsersHandler } from "src/store/slices/userSlice"
import { RootState, useAppDispatch } from "src/store/store"
import { Avatar, Option, PaginationData, SortOrder, TableColumn, TabType } from "src/types/types"
import { useSelector } from "react-redux"
import { deleteRolesAndPerms, getAllRolesByUserId, getAllRolesAndPerms } from "src/store/slices/roleSlice"
import { deleteAvatarHandler, getAllAvatarsHandler, getAvatarLabelValue } from "src/store/slices/avatarSlice"
import CreateRoleModal from "src/components/Modals/CreateRoleModal"
import { getAllPermissionsHandler } from "src/store/slices/permissionSlice"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp, faChevronDown, faEye, faShare } from "@fortawesome/free-solid-svg-icons"
import { getSettingData, setActiveTab } from "src/store/slices/settingsSlice"
import { useDebounce } from "src/hooks/useDebounce"
import { PaginationComponent } from "src/components/PaginationCom"
import { ConversationPaginateData, IConversationsGroupByThreadRes } from "src/types/conversation.type"
import { getAllConversationsGroupByThread } from "src/store/slices/conversationSlice"
import { CommonModal } from "src/components/Modals/Modal"
import { ShareAvatar } from "src/components/Avatar/ShareAvatar"
import { ConversationThreadMessages } from "src/components/Conversations/ConversationThreadMessages"
import { useUserTableColumns } from "./hooks/userUserTableColumn"
import { useRoleTableColumns } from "../SuperAdmin/hooks/useRoleTableColumn"
import { useAvatarTableColumns } from "../SuperAdmin/hooks/useAvatarTable.Column"
import { useTranslation } from "react-i18next"

type User = {
  uuid: string
  fName: string
  lName: string
  email: string
  status: string,
  tenantUuid: string,
  tenantName: string,
}
type RolePermission = any

const pageLimit = 10;
const conversationTableColumns: TableColumn[] = [
  {
    label: 'Avatar Name',
    accessor: 'avatar.name',
    sortable: false
  },
  {
    label: 'User Name',
    accessor: 'user.fName',
    sortable: false
  },
  {
    label: "actions",
    accessor: "actions",
    sortable: false
  },
];
const UserDashboard = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common');

  const { user } = useSelector((state: RootState) => state.auth);
  const { activeTab } = useSelector(getSettingData);
  const userId = user?.user?.uuid

  const [allRoles, setAllRoles] = useState<Option[]>([]);

  const [avatars, setAvatars] = useState<Avatar[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [rolesPermissions, setRolesPermissions] = useState<RolePermission[]>([]);
  const [conversations, setConversations] = useState<IConversationsGroupByThreadRes[]>([])
  const [actionLoader, setActionLoader] = useState<string | null>(null);
  const [sorting, setSorting] = useState<{ field: string; order: SortOrder | string }>({
    field: "",
    order: "ASC"
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [selectedAvatar, setSelectedAvatar] = useState<string>("");
  const [conversationUUID, setConversationUUID] = useState<string | null>(null);
  const [shareAvatar, setShareAvatar] = useState<{ avatarCode: string; tenantCode: string } | null>(null);
  const [avatarLabelValue, setAvatarLabelValue] = useState<Option[]>([]);

  // Custom Hooks
  const deboucedValue = useDebounce(search, 700);
  const { columns: userTableColumns } = useUserTableColumns();
  const { columns: roleTableColumn } = useRoleTableColumns();
  const { columns: avatarTableColumns } = useAvatarTableColumns();

  const getAllAvatarsList = (overridePaginationData?: PaginationData) => {
    const pagination: PaginationData = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...overridePaginationData
    }
    dispatch(getAllAvatarsHandler(pagination)).then((res: any) => {
      if (res.type === (GET_ALL_AVATARS + FULFILLED)) {
        setAvatars(res.payload.data)
        setTotalCount(res?.payload?.totalItems)
      }
    })
  }

  const getAllUsersList = (overridePaginationData?: PaginationData) => {
    const pagination: PaginationData = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      userId: userId,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...overridePaginationData
    }
    dispatch(getAllUsersHandler(pagination)).then((res: any) => {
      if (res.type === (GET_ALL_USERS + FULFILLED)) {
        setUsers(res.payload.data)
        setTotalCount(res?.payload?.totalItems)
      }
    })
  }

  const getAllRolesPermissionsList = async (overridePaginationData?: PaginationData) => {
    const pagination: PaginationData & { id: number } = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      id: userId,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...overridePaginationData
    }
    dispatch(getAllRolesAndPerms(pagination)).then((res: any) => {
      if (res.type === (GET_ALL_ROLES_AND_PERMS + FULFILLED)) {
        const newdata = res.payload.data.map((data: any) => {
          return {
            role: { name: data.name, id: data.uuid },
            permissions: data.permissions.map((p: any) => p.permission)
          }
        })
        setRolesPermissions(newdata)
        setTotalCount(res?.payload?.totalItems)
        getAllRole()
      }
    })
  }

  const getAllRole = () => {
    dispatch(getAllRolesByUserId(userId)).then((res: any) => {
      if (res.type === (GET_ALL_ROLES + FULFILLED)) {
        setAllRoles(res.payload)
      }
    })
  }

  const getAllConversationList = async (overridePaginationData?: ConversationPaginateData) => {
    const pagination: ConversationPaginateData = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...(selectedAvatar && { avatarUuid: selectedAvatar }),
      ...overridePaginationData
    }

    const res = await dispatch(getAllConversationsGroupByThread(pagination));

    if (res && res?.type === GET_CONVERSATION_GROUP_BY_THREAD + FULFILLED) {
      if (res?.payload?.success) {
        setConversations(res.payload?.data.data);
        setCurrentPage(res?.payload?.data?.currentPage)
        setTotalCount(res?.payload?.data?.totalItems)
      }
    }
  }

  const fetchAvatarLabelValue = async () => {
    const res = await dispatch(getAvatarLabelValue(null));

    if (res?.type === AVATAR_LABEL_VALUE + FULFILLED) {
      const payload = res?.payload;
      if (payload?.success) {
        setAvatarLabelValue(payload?.data);
      }
    }
  }

  useEffect(() => {
    if (user?.permissions?.includes('user-read') && activeTab === 'user') getAllUsersList()
    if (user?.permissions?.includes('avatar-read') && activeTab === 'avatar') getAllAvatarsList()
    if (user?.permissions?.includes('role-read') && activeTab === 'role') {
      getAllRolesPermissionsList()
    }
    if (activeTab === 'conversation') {
      getAllConversationList();
    }
    //eslint-disable-next-line
  }, [user, activeTab, deboucedValue, selectedAvatar])

  useEffect(() => {
    dispatch(getAllPermissionsHandler());
    getAllRole();
    //eslint-disable-next-line
  }, [])

  // Fetch the avatar's label-value for conversation tab.
  useEffect(() => {
    if (activeTab === 'conversation') {
      fetchAvatarLabelValue();
    }
    //eslint-disable-next-line
  }, [activeTab])

  const editAvatar = (avatarId: string) => {
    dispatch(updateAvatarIdHandler(avatarId))
    dispatch(createAvatarModalHandler(true))
  }

  const deleteAvatar = (avatarId: string) => {
    setActionLoader(avatarId)
    dispatch(deleteAvatarHandler(avatarId)).then((res: any) => {
      if (res.type === (DELETE_AVATAR + FULFILLED)) {
        getAllAvatarsList()
      }
      setActionLoader(null)
    })
  }

  const editRole = (roleId: string) => {
    if (roleId !== "") {
      dispatch(updateRoleIdHandler(roleId))
      dispatch(createRoleModalHandler(true))
    }
  }

  const deleteRole = (roleId: string) => {
    setActionLoader(roleId)
    dispatch(deleteRolesAndPerms(roleId)).then((res: any) => {
      if (res.type === (DELETE_ROLE + FULFILLED)) {
        getAllRolesPermissionsList()
      }
      setActionLoader(null)
    })
  }

  const editUser = (userId: string) => {
    dispatch(updateUserIdHandler(userId))
    dispatch(createUserModalHandler(true))
  }

  const deleteUser = (userId: string) => {
    setActionLoader(userId)
    dispatch(deleteTenantUserByUuid(userId)).then((res: any) => {
      if (res.type === (DELETE_USER + FULFILLED)) {
        getAllUsersList()
      }
      setActionLoader(null)
    })
  }

  const handleCreateUser = () => {
    if (allRoles?.length > 0) {
      dispatch(createUserModalHandler(true))
    } else {
      alert("Please create a role to continue")
    }
  }

  const handleCreateAvatar = () => {
    if (user.keys.isOpenAIKeyValid) {
      dispatch(createAvatarModalHandler(true))
    } else {
      toast.error(user.keys.message)
    }
  }

  const toggleActiveTab = (tab: TabType) => {
    resetPaginationData();
    dispatch(setActiveTab(tab));
  }

  const activeColumnSortFlag = (accessor: string) => {
    if (accessor === sorting?.field && sorting?.order === 'ASC') {
      return <FontAwesomeIcon className="ms-1" icon={faArrowUp} />
    }
    else if (accessor === sorting?.field && sorting?.order === 'DESC') {
      return <FontAwesomeIcon className="ms-1" icon={faArrowDown} />
    }
    else {
      return <></>
    }
  }

  const handleSorting = (accessor: string) => {
    let sortOrder: SortOrder | string = sorting?.order;
    let sortField = accessor;
    if (accessor === sorting?.field && sorting?.order === 'ASC') {
      sortOrder = 'DESC'
    }
    else if (accessor !== sorting?.field) {
      sortOrder = 'ASC';
    }
    else if (
      accessor === sorting?.field
      && sorting?.order === 'DESC'
    ) {
      sortOrder = "";
      sortField = "";
    }
    else {
      sortOrder = 'ASC';
    }

    setSorting({ ...sorting, order: sortOrder, field: sortField });
    if (activeTab === 'user') {
      getAllUsersList({ sortColumn: sortField, sortType: sortOrder });
    }
    else if (activeTab === 'avatar') {
      getAllAvatarsList({ sortColumn: sortField, sortType: sortOrder });
    }
    else if (activeTab === 'role') {
      getAllRolesPermissionsList({ sortColumn: sortField, sortType: sortOrder });
    }
    else if (activeTab === 'conversation') {
      getAllConversationList({ sortColumn: sortField, sortType: sortOrder });
    }
  }

  const resetPaginationData = () => {
    setSearch("");
    setCurrentPage(1);
    setSorting({ order: 'ASC', field: "" });
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  }

  const handlePageChange = (page: number) => {
    if (Number(page) === Number(currentPage)) {
      return;
    }
    setCurrentPage(page);
    if (activeTab === 'avatar') {
      getAllAvatarsList({ pageNumber: page });
    }
    else if (activeTab === 'role') {
      getAllRolesPermissionsList({ pageNumber: page });
    }
    else if (activeTab === 'user') {
      getAllUsersList({ pageNumber: page });
    }
    else if (activeTab === 'conversation') {
      getAllConversationList({ pageNumber: page });
    }
  }

  const handleConversationThreadId = (uuid: string) => {
    if (uuid) setConversationUUID(uuid);
  }

  const handleShareAvatar = (tenantCode: string, avatarCode: string) => {
    if (tenantCode && avatarCode) setShareAvatar({ ...shareAvatar, tenantCode: tenantCode, avatarCode: avatarCode });
  }

  const handleAvatarDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e?.target?.value;
    setSelectedAvatar(value);
  }

  return (
    <>
      <div className='container-xl h-page'>
        <div className='text-primary fs-2 mb-4'>{t('common.Dashboard')}</div>
        <ul className="nav nav-underline border-bottom mb-3" id="admin-tab" role="tablist">
          {user?.permissions?.includes('user-read') &&
            <li className="nav-item" role="presentation">
              <button className={`nav-link ${activeTab === 'user' ? 'active' : ''}`} onClick={() => toggleActiveTab('user')}>Users</button>
            </li>
          }
          {user?.permissions?.includes('avatar-read') &&
            <li className="nav-item" role="presentation">
              <button className={`nav-link ${activeTab === 'avatar' ? 'active' : ''}`} onClick={() => toggleActiveTab('avatar')}>Avatars</button>
            </li>
          }
          {user?.permissions?.includes('role-read') &&
            <li className="nav-item" role="presentation">
              <button className={`nav-link ${activeTab === 'role' ? 'active' : ''}`} onClick={() => toggleActiveTab('role')} >Roles</button>
            </li>
          }

          {
            user?.permissions?.includes('conversation-read') && (
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 'conversation' ? 'active' : ''}`} onClick={() => toggleActiveTab('conversation')}>Conversations</button>
              </li>
            )
          }
        </ul>
        <div className="tab-content">
          {/* User Table - Start */}
          {(user?.permissions?.includes('user-read') && activeTab === 'user') &&
            <div className="tab-pane fade show active">
              <div className="vstack gap-4">
                <div className="hstack gap-3 justify-content-between">
                  <div>
                    {/* Search Input */}
                    <div className="d-flex gap-2">
                      <input
                        type="search"
                        className="custom-form-control"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearch}
                      />

                    </div>
                  </div>
                  {user?.permissions?.includes('user-create') &&
                    <button type="button" className="btn btn-primary" onClick={handleCreateUser}>Create User</button>
                  }
                </div>
                <div className="table-responsive rounded">
                  <table className="table table-light">
                    <thead>
                      <tr>
                        {userTableColumns?.map((el, i) => <th
                          key={`th-${el.accessor}-${i}`}
                          onClick={() => el?.sortable && handleSorting(el.accessor)}
                        >
                          {el?.label}
                          {activeColumnSortFlag(el?.accessor)}
                        </th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        users && users.length > 0 && users?.map((usr: User) => {
                          return (
                            <tr key={usr.uuid}>
                              <td>{usr.fName} &nbsp; {usr.lName}</td>
                              <td>{usr.email}</td>
                              <td>{usr.status}</td>
                              {
                                (user?.permissions?.includes?.('user-update')
                                  || user?.permissions?.includes?.('user-delete'))
                                && (
                                  <td>
                                    <Actions permissionParent="user" id={usr.uuid} editFn={editUser} deleteFn={deleteUser} actionLoader={actionLoader} />
                                  </td>
                                )
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <PaginationComponent
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      pageSize={pageLimit}
                      siblingCount={1}
                      totalCount={totalCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {/* User Table - End */}

          {/* Avatar Table - Start */}
          {(user?.permissions?.includes('avatar-read') && activeTab === 'avatar') &&
            <div className="tab-pane fade show active">
              <div className="vstack gap-4">
                {user?.permissions?.includes('avatar-create') &&
                  <div className="hstack gap-3 justify-content-between">
                    <div>
                      {/* Search Input */}
                      <div className="d-flex gap-2">
                        <input
                          type="search"
                          className="custom-form-control"
                          placeholder="Search"
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={handleCreateAvatar}>Create Avatar</button>
                  </div>
                }
                <div className="table-responsive rounded">
                  <table className="table table-light">
                    <thead>
                      <tr>
                        {avatarTableColumns?.map((el, i) => <th
                          key={`th-${el.accessor}-${i}`}
                          onClick={() => el?.sortable && handleSorting(el.accessor)}
                        >
                          {el?.label}
                          {activeColumnSortFlag(el?.accessor)}
                        </th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        avatars && avatars.length > 0 && avatars?.map((avatar: Avatar) => {
                          return (
                            <tr key={avatar.uuid}>
                              <td>{avatar.name}</td>
                              <td>{avatar.role}</td>
                              <td>
                                <div className="hstack justify-content-end gap-3">
                                  <Actions permissionParent="avatar" id={avatar.uuid} editFn={editAvatar} deleteFn={deleteAvatar} actionLoader={actionLoader} />
                                  <div className='action-btn'>
                                    <Link to={`/bot/${avatar.tenantCode}/${avatar.avatarCode}`} className="btn bg-secondary-subtle action-btn" title="Preview">
                                      <FontAwesomeIcon icon={faEye} size="lg" className='text-secondary' />
                                    </Link>
                                  </div>
                                  <div className='action-btn'>
                                    <button className="btn bg-secondary-subtle action-btn" title="Share" onClick={() => handleShareAvatar(avatar.tenantCode, avatar.avatarCode)}>
                                      <FontAwesomeIcon icon={faShare} size="lg" className='text-secondary' />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <PaginationComponent
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      pageSize={pageLimit}
                      siblingCount={1}
                      totalCount={totalCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {/* Avatar Table - End */}

          {/* Role Table - Start */}
          {(user?.permissions?.includes('role-read') && activeTab === 'role') &&
            <div className="tab-pane fade show active">
              <div className="vstack gap-4">
                {user?.permissions?.includes('role-create') &&
                  <div className="hstack gap-3 justify-content-between">
                    <div>
                      {/* Search Input */}
                      <div className="d-flex gap-2">
                        <input
                          type="search"
                          className="custom-form-control"
                          placeholder="Search"
                          value={search}
                          onChange={handleSearch}
                        />

                      </div>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={() => dispatch(createRoleModalHandler(true))}>Create Role</button>
                  </div>
                }
                <div className="table-responsive rounded">
                  <table className="table table-light">
                    <thead>
                      <tr>
                        {roleTableColumn?.map((el, i) => <th
                          key={`th-${el.accessor}-${i}`}
                          onClick={() => el?.sortable && handleSorting(el.accessor)}
                        >
                          {el?.label}
                          {activeColumnSortFlag(el?.accessor)}
                        </th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        rolesPermissions && rolesPermissions.length > 0 && rolesPermissions?.map((rolesPermission: RolePermission) => {
                          return (
                            <tr key={rolesPermission.role.id}>
                              <td>{rolesPermission.role.name}</td>
                              {
                                (user?.permissions?.includes('role-update')
                                  || user?.permissions?.includes('role-delete')) && (
                                  <td>
                                    <Actions permissionParent="role" id={rolesPermission.role.id} editFn={editRole} deleteFn={deleteRole} actionLoader={actionLoader} />
                                  </td>
                                )
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <PaginationComponent
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      pageSize={pageLimit}
                      siblingCount={1}
                      totalCount={totalCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {/* Role Table - End */}

          {/* Conversation Table - Start */}
          {
            (user?.permissions?.includes('conversation-read') && activeTab === 'conversation') && (
              <div className="tab-pane fade show active">
                <div className="vstack gap-4">
                  {user?.permissions?.includes('role-create') &&
                    <div className="hstack gap-3 justify-content-between">
                      <div>
                        {/* Search Input */}
                        <div className="d-flex gap-2">
                          <input
                            type="search"
                            className="custom-form-control"
                            placeholder="Search"
                            value={search}
                            onChange={handleSearch}
                          />
                          {
                            activeTab === 'conversation' && (
                              <div className="position-relative w-100">
                                <select
                                  className="form-control custom-form-control text-truncate"
                                  value={selectedAvatar}
                                  onChange={handleAvatarDropdownChange}
                                >
                                  <option value={""}>All Avatar</option>
                                  {
                                    avatarLabelValue?.length > 0 && avatarLabelValue?.map((avtr) => {
                                      return (
                                        <option key={avtr?.value} value={avtr?.value}>{avtr?.label}</option>
                                      )
                                    })
                                  }

                                </select>
                                <FontAwesomeIcon icon={faChevronDown} className="position-absolute translate-middle-y top-50 end-0 me-3 text-black-50" />
                              </div>
                            )
                          }
                        </div>
                      </div>

                    </div>
                  }
                  <div className="table-responsive rounded">
                    <table className="table table-light">
                      <thead>
                        <tr>
                          {conversationTableColumns?.map((el, i) => <th
                            key={`th-${el.accessor}-${i}`}
                            onClick={() => el?.sortable && handleSorting(el.accessor)}
                          >
                            {el?.label}
                            {activeColumnSortFlag(el?.accessor)}
                          </th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          conversations && conversations?.length === 0 && (
                            <tr>
                              <td colSpan={4}>No Conversations Found</td>
                            </tr>
                          )
                        }
                        {
                          conversations && conversations.length > 0 && conversations?.map((conversation: IConversationsGroupByThreadRes) => {
                            return (
                              <tr key={conversation.uuid}>
                                <td>{conversation?.avatar?.name}</td>
                                <td>{conversation?.user?.fName} {conversation?.user?.lName}</td>

                                <td>
                                  <div className="hstack justify-content-end gap-3">
                                    {/* <Actions id={avatar.uuid} editFn={editAvatar} deleteFn={deleteAvatar} actionLoader={actionLoader} /> */}
                                    <div className='action-btn'>
                                      <button onClick={() => handleConversationThreadId(conversation?.uuid)} className="btn bg-secondary-subtle action-btn" title="Preview">
                                        <FontAwesomeIcon icon={faEye} size="lg" className='text-secondary' />
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                      <PaginationComponent
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        pageSize={pageLimit}
                        siblingCount={1}
                        totalCount={totalCount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {/* Conversation Table - End */}
        </div>
      </div>
      <CreateUserModal getAllUsersList={getAllUsersList} allRoles={allRoles} />
      {<CreateAvatarModal getAllAvatarsList={getAllAvatarsList} />}
      <CreateRoleModal getAllRolesPermissionsList={getAllRolesPermissionsList} />

      {/* Share Avatar Modal */}
      <CommonModal
        isOpen={!!(shareAvatar && Object?.keys(shareAvatar)?.length > 0)}
        title="Share Avatar"
        onConfirm={() => null}
        onClose={() => setShareAvatar(null)}
        showFooter={false}
        scrollable={false}
      >
        {
          shareAvatar && (
            <ShareAvatar
              tenantCode={shareAvatar?.tenantCode}
              avatarCode={shareAvatar?.avatarCode}
            />
          )
        }
      </CommonModal>

      {/* Conversation Modal */}
      <CommonModal
        title="Conversation History"
        isOpen={!!conversationUUID}
        onClose={() => setConversationUUID(null)}
        onConfirm={() => null}
        showFooter={false}
      >
        {conversationUUID && <ConversationThreadMessages conversationId={conversationUUID} />}
      </CommonModal>
    </>
  )
}

export default UserDashboard