import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APPNAME } from 'src/constants/constants';
import { TabType } from 'src/types/types';
import { RootState } from '../store';
// Define types

interface SettingState {
    appName: string
    isLoading: boolean
    isVolumeOn: boolean
    initErrorMessage: string
    aboutModalIsOpen: boolean
    settingsModalIsOpen: boolean
    isValidated: boolean
    activeTab: TabType;
}

// Define the initial state
const initialState: SettingState = {
    appName: APPNAME,
    isLoading: false,
    isVolumeOn: true,
    initErrorMessage: "",
    aboutModalIsOpen: false,
    settingsModalIsOpen: false,
    isValidated: false,
    activeTab: 'tenant'
};


// Create the slice
const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        handleLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        handleVolumn: (state, { payload }: PayloadAction<boolean>) => {
            state.isVolumeOn = payload;
        },
        handleInitErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.initErrorMessage = payload;
        },
        handleIsValidated: (state, { payload }: PayloadAction<boolean>) => {
            state.isValidated = payload
        },
        setActiveTab: (state, { payload }: PayloadAction<TabType>) => {
            state.activeTab = payload;
        }
    },
    extraReducers: (builder) => {

    },
});

export const { handleLoading, handleVolumn, handleInitErrorMessage, handleIsValidated, setActiveTab } = settingSlice.actions

export default settingSlice.reducer;

export const getSettingData = (state: RootState) => state?.setting;