import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, useFormikContext } from "formik";
import ErrorMessageFormik from "src/components/ErrorMessageFormik";
import { UpdateIdType } from "src/store/slices/modalSlice";
import { Option } from "src/types/types";

interface FormSelectFieldProps {
    name: string
    label: string
    className?: string
    options: any[]
    disabled?: UpdateIdType
}

const FormSelectField: React.FC<FormSelectFieldProps> = ({ name, label, className, options, disabled = false }) => {
    const { setFieldValue } = useFormikContext();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOption = options?.find(option => (typeof options[0] === 'object' ? option?.value : option) === event.target.value);
        if (selectedOption) {
            setFieldValue(name, typeof options[0] === 'object' ? selectedOption?.value : selectedOption);
        }
    };

    return (
        <div className={className}>
            <label htmlFor={name} className='custom-form-label'>{label}</label>
            <div className="position-relative">
                <Field
                    as="select"
                    name={name}
                    className="custom-form-control text-truncate"
                    onChange={handleChange}
                    disabled={disabled}

                >
                    {typeof options[0] === 'object' ?
                        options.map((option: Option, index: number) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))
                        :
                        options.map((option: string, index: number) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))
                    }
                </Field>
                <FontAwesomeIcon icon={faChevronDown} className="position-absolute translate-middle-y top-50 end-0 me-3 text-black-50" />
            </div>
            <ErrorMessageFormik name={name} />
        </div>
    );
};

export default FormSelectField