import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react'

interface IPlanExpired {
    onPurchase: () => void;
}

const PlanExpired: FC<IPlanExpired> = ({
    onPurchase
}) => {
    return (
        <div className="card custom-card">
            <div className="card-body vstack gap-4 align-items-center">
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    size='4x'
                    className="text-primary"
                />
                <h5 className="card-title fs-3 text-primary">You subscription has expired</h5>
                <p className="fs-5">
                    Reactivate it now to continue using our features and services.
                </p>
                <button className="btn btn-primary btn-lg" onClick={onPurchase}>
                    ReActivate
                </button>
            </div>
        </div>
    )
}

export default PlanExpired
