import { useState, useEffect, useCallback, FC } from "react";
import { useLogger } from "./logger";
import { AvaturnSDK, AssetItem, BodyItem } from "@avaturn/sdk";
import "./Scene.scss";
import { Logger } from "./logger/Logger";
import { ColorPicker } from "./ColorPicker";

type GroupedItems = any;

// You can change this to test no ui mode
const disableUi = false;
const BODYTYPE = 'bodytype'
const Avaturn = () => {
    const { log, clear } = useLogger();
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [sdk] = useState<AvaturnSDK>(new AvaturnSDK());
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [assetList, setAssetList] = useState<GroupedItems>({});
    const [bodyList, setBodyList] = useState<BodyItem[]>([]);

    useEffect(() => {
        if (!containerRef) return;
        setEditorLoaded(false);

        sdk
            .init(containerRef, {
                // url: "<INSERT URL FROM /sessions/new request>"
                iframeClassName: "sdk-iframe",
                disableUi,
            })
            .then(() => {
                sdk
                    .on("load", () => {
                        log("[callback] Page is ready");

                        setEditorLoaded(true);
                        sdk.getBodyList().then(setBodyList);
                        sdk.getAssetList().then((list) => {

                            // Group items by category
                            let categoryItems = list.reduce((acc: GroupedItems, item: AssetItem) => {
                                if (!acc[item.category]) {
                                    acc[item.category] = []
                                }
                                acc[item.category].push(item)
                                return acc
                            }, {})
                            setAssetList(categoryItems)
                        });

                        // hint: You can use code similar to function `handleFilterAssets` here to load only selected assets
                    })
                    .on("assetSet", (data) => {
                        log("[callback] Asset changed: " + data);
                    })
                    .on("bodySet", (data) => {
                        log("[callback] Body changed: " + data);
                    })
                    .on("export", (data) => {
                        log(
                            "[callback] Avatar exported. Check dev console to explore returned value."
                        );
                        console.log(data);
                    })
                    .on("changeParam", (data) => {
                        log(`[callback] Set ${data.key} to ${data.value}`);
                    });
            })
            .catch((reason) => {
                sdk.getBodyList().then(setBodyList);
                sdk.getAssetList().then((list) => {

                    // Group items by category
                    let categoryItems = list.reduce((acc: GroupedItems, item: AssetItem) => {
                        if (!acc[item.category]) {
                            acc[item.category] = []
                        }
                        acc[item.category].push(item)
                        return acc
                    }, {})
                    setAssetList(categoryItems)
                });
                log(`[Avaturn SDK Error]: ${reason}`);
            });

        return () => {
            sdk.destroy();
            setAssetList({});
            setBodyList([]);
            clear();
        };
    }, [sdk, containerRef, log, clear]);

    const handleSetAsset = (id: string) => {
        log(`Calling sdk.setActiveAsset('${id}')`);
        sdk.setActiveAsset(id).then((res) => {
            log("[then] Asset changed: " + res);
        });
    };

    const handleSetBody = (id: string) => {
        log(`Calling sdk.setActiveBody('${id}')`);
        sdk.setActiveBody(id).then((res) => {
            log("[then] Body changed: " + res);
        });
    };

    const handleChangeColor = useCallback(
        (color: string) => {
            sdk.setHairColor(color as `#${string}`);
        },
        [sdk]
    );

    const handleExportAvatar = () => {
        log(`Calling sdk.exportAvatar()`);
        sdk.exportAvatar().then((res) => {
            log("[then] Avatar exported (see console logs for exported object)");
            console.log(res);
        });
    };

    const handleFilterAssets = () => {
        sdk
            .getAssetList()
            .then((list) => {
                const filteredList = list.slice(0, 3).map((x) => x.id);
                return sdk.setAvailableAssets(filteredList);
            })
            .then(() => {
                sdk.getAssetList().then((list) => {

                    // Group items by category
                    let categoryItems = list.reduce((acc: GroupedItems, item: AssetItem) => {
                        if (!acc[item.category]) {
                            acc[item.category] = []
                        }
                        acc[item.category].push(item)
                        return acc
                    }, {})

                    setAssetList(categoryItems)
                });
            });
    };
    const handleGetAssetList = () => {
        sdk.getAssetList().then((list) => {
            log(JSON.stringify(list, null, 2));
        });
    };
    const handleGetBodyList = () => {
        sdk.getBodyList().then((list) => {
            log(JSON.stringify(list, null, 2));
        });
    };

    type AssetItemBlockProps = {
        item: AssetItem
    }
    const AssetItemBlock: FC<AssetItemBlockProps> = ({ item }) => {
        const [active, setActive] = useState<boolean>(false)

        return (
            <div
                key={item.id}
                className={`assets__item border border-3 ${active ? 'border-primary ' : ''}`}
                onClick={() => { handleSetAsset(item.id); setActive(!active) }}
            >
                <img src={item.preview} alt={item.id} />
            </div>
        )
    }

    return (
        <div className="sdk rounded-3 overflow-hidden">
            <div ref={setContainerRef} className="sdk__scene" />
            {disableUi && (
                <div className="sdk__controls">
                    <div className="d-flex flex-row-reverse align-items-start gap-3 position-relative px-3">
                        <div className="position-sticky top-0 py-3">
                            <div className="assets" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                {Object.keys(assetList).map((category: string, index: number) => (
                                    <button
                                        className={`avatar-nav-btn btn p-2 rounded-0 border-0 border-bottom ${index === 0 ? 'active' : ''}`}
                                        id={`v-pills-${category}-tab`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#v-pills-${category}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`v-pills-${category}`}
                                        aria-selected="true"
                                    >
                                        {category}
                                    </button>
                                ))}
                                {bodyList.length > 0 &&
                                    <button
                                        className={`avatar-nav-btn btn rounded-0 border-0`}
                                        id={`v-pills-${BODYTYPE}-tab`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#v-pills-${BODYTYPE}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`v-pills-${BODYTYPE}`}
                                        aria-selected="true"
                                    >
                                        {BODYTYPE}
                                    </button>}
                            </div>
                        </div>
                        <div className="tab-content py-3" id="v-pills-tabContent">
                            {Object.keys(assetList).map((category: string, index: number) => (
                                <div className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                    id={`v-pills-${category}`}
                                    role="tabpanel"
                                    aria-labelledby={`v-pills-${category}-tab`}
                                    tabIndex={0}
                                >
                                    <div key={category} className="assets">
                                        <div className="assets__item-wrapper">
                                            {
                                                assetList[category].map((item: AssetItem) => (
                                                    <AssetItemBlock item={item} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {bodyList.length > 0 &&
                                <div className={`tab-pane fade`}
                                    id={`v-pills-${BODYTYPE}`}
                                    role="tabpanel"
                                    aria-labelledby={`v-pills-${BODYTYPE}-tab`}
                                    tabIndex={0}
                                >
                                    <div className="assets">
                                        <div className="assets__item-wrapper">
                                            {bodyList.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="assets__item"
                                                    onClick={() => handleSetBody(item.id)}
                                                >
                                                    <img src={item.preview} alt={item.id} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {/* {Object.keys(assetList).map((category: string) => (
                        <div key={category} className="assets">
                            <div>{category}</div>
                            <div className="assets__item-wrapper">

                                {
                                    assetList[category].map((item: AssetItem) => (
                                        <div
                                            key={item.id}
                                            className="assets__item"
                                            onClick={() => handleSetAsset(item.id)}
                                        >
                                            <img src={item.preview} alt={item.id} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))} */}
                    {/* <div className="assets">
                        <div>{'Body Shape'}</div>
                        <div className="assets__item-wrapper">
                            {bodyList.map((item) => (
                                <div
                                    key={item.id}
                                    className="assets__item"
                                    onClick={() => handleSetBody(item.id)}
                                >
                                    <img src={item.preview} alt={item.id} />
                                </div>
                            ))}
                        </div>
                    </div> */}
                    {disableUi && sdk && editorLoaded && (
                        <>
                            <h3>Actions</h3>
                            <div className="actions">
                                <button className="btn btn-secondary" onClick={handleExportAvatar}>Export</button>
                                <button className="btn btn-secondary" onClick={handleGetAssetList}>Get asset list</button>
                                <button className="btn btn-secondary" onClick={handleGetBodyList}>Get body list</button>
                                <button className="btn btn-secondary" onClick={handleFilterAssets}>Filter assets</button>
                                <ColorPicker
                                    label="Set hair color"
                                    onChange={handleChangeColor}
                                />
                            </div>
                        </>
                    )}
                    <h3>Events log:</h3>
                    <Logger />
                </div>
            )}
            {/* {!disableUi && <LoggerOwn />} */}
        </div>
    );
};
export default Avaturn