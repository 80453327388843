import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionPaymentForm from "./SubscriptionPaymentForm";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export const StripeSubscriptionCard = () => {
    return (
        <div>
            <Elements stripe={stripe} >
                <SubscriptionPaymentForm />
            </Elements>
        </div>
    )
}