export enum ENUM_COMMON_STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED',
    SUSPENDED = 'SUSPENDED'
}

export enum ENUM_BACKGROUND_OPTION {
    SKYBOX = 'SKYBOX',
    IMAGE = 'IMAGE',
    COLOR = 'COLOR'
}

export enum ENUM_CHAT_BY {
    user = 'user',
    ASSISTANT = 'assistant',
}