import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addMonths } from 'src/common/commonUtils';
import { PROFILE } from 'src/constants/constants';
import { setTenantExpiration } from 'src/store/slices/authSlice';
import { RootState, useAppDispatch } from 'src/store/store';

const PaymentStatus = () => {

    // Redux State
    const { expiredAt } = useSelector((state: RootState) => state.auth?.user?.user?.tenantUser);
    const dispatch = useAppDispatch()


    const navigate = useNavigate();

    //Component State 
    const [paymentStatus, setPaymentStatus] = useState<'success' | 'failed' | 'loading'>('loading');
    const [searchParams] = useSearchParams()


    //Get the payment status from the url.
    useEffect(() => {

        const paymentIntent = searchParams?.get('payment_intent');
        const paymentIntentClientSecret = searchParams?.get('payment_intent_client_secret');
        const redirectStatus = searchParams?.get('redirect_status');

        if (paymentIntent && paymentIntentClientSecret && (redirectStatus === 'succeeded')) {
            setPaymentStatus('success');
            // Update the organization expiration date in the redux.
            const nextExpirationDate = addMonths(new Date(expiredAt), 1);
            dispatch(setTenantExpiration(nextExpirationDate?.toISOString()))
            setTimeout(() => goToUserProfile(), 1000);
        }
        else {
            goToUserProfile();
        }
        //eslint-disable-next-line
    }, [searchParams])


    const goToUserProfile = () => {
        navigate(PROFILE);
    }



    if (paymentStatus === 'loading') {
        return (
            <div className='container-xl h-page'>
                <Spinner />
            </div>
        )
    }

    return (
        <div className='container-xl h-page'>
            Payment completed successfully!, redirecting...
        </div>
    )
}

export default PaymentStatus
