import { ReactNode } from 'react'

type MessageWrapperProps = {
  name: string
  children: ReactNode
  classes?: string
}
const MessageWrapper = ({ name, children, classes }: MessageWrapperProps) => {
  return (
    <div className={classes}>
      <div className="message-wrapper">
        <span className="message-name">{name.replaceAll("_", " ")}:</span>
        {children}
      </div>
    </div>
  )
}

export default MessageWrapper