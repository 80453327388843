import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { MAX_CHARS } from "src/config/modelConfig";
import { RootState } from "src/store/store";

type ChatErrorTextComponentProps = {
    children: ReactNode;
};

const ChatErrorTextComponent = ({ children }: ChatErrorTextComponentProps) => {
    return (
        <div className="text-danger mt-3 mb-1 text-center">
            {children}
        </div>
    );
};

export const ChatErrors = () => {
    const { errorMessage, charCount } = useSelector((state: RootState) => state.conversation);

    if (errorMessage) {
        return <ChatErrorTextComponent>{errorMessage}</ChatErrorTextComponent>;
    }

    if (charCount > MAX_CHARS) {
        return (
            <ChatErrorTextComponent>
                {`Please enter a message with ${MAX_CHARS} characters or less.`}
            </ChatErrorTextComponent>
        );
    }

    return null;
};
