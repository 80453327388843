import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementOptions, StripeElementChangeEvent, StripeElementType } from '@stripe/stripe-js';
import { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FULFILLED, TENANT_SUBSCRIPTION_CREATE } from 'src/constants/constants';
import { createTenantSubscription } from 'src/services/payment.service';
import { RootState, useAppDispatch } from 'src/store/store';
import { setSubscriptionDetails } from 'src/store/slices/subscriptionSlice';

const SubscriptionPaymentForm = () => {

    // Redux State
    const { subscriptionAmount } = useSelector((state: RootState) => state.auth?.user?.user?.tenantUser);
    const { fName, lName, email } = useSelector((state: RootState) => state.auth?.user?.user);
    const dispatch = useAppDispatch();

    // Stripe Hooks
    const stripe = useStripe();
    const elements = useElements();


    // Component State
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [cardErrorMessage, setCardErrorMessage] = useState<{ [key in StripeElementType]?: string } | null>(null);


    const handlePayment = async () => {
        try {
            if (!stripe || !elements) return;

            setShowLoader(true);
            elements.submit();

            // Create Payment Method.
            // create a payment method
            const paymentMethod = await stripe?.createPaymentMethod({
                type: 'card',
                card: elements?.getElement(CardNumberElement)!,
                billing_details: {
                    name: `${fName}${lName}`,
                    email,
                },
            });

            if (paymentMethod && paymentMethod?.paymentMethod?.id) {
                // Integrate the Tenant subscription API
                const response = await dispatch(createTenantSubscription(paymentMethod?.paymentMethod?.id));

                if (response?.type === TENANT_SUBSCRIPTION_CREATE + FULFILLED) {
                    // Confirm the payment by user.
                    const clientSecret = response?.payload?.data?.clientSecret;
                    const actionRequired = response?.payload?.data?.actionRequired;
                    const subscription = response?.payload?.data?.subscription;

                    if (actionRequired) {
                        // confirm the payment by the user
                        const { paymentIntent, error } = await stripe?.confirmCardPayment(clientSecret);

                        if (error) {
                            toast.error(error?.message);
                            return;
                        }

                        if (paymentIntent?.status === 'succeeded') {
                            dispatch(setSubscriptionDetails({ ...subscription }));
                            toast.success('Organization activated successfully!');
                        }
                    }
                    else {
                        dispatch(setSubscriptionDetails({ ...subscription }));
                        toast.success('Organization activated successfully!');
                    }
                }
            }
        } catch (error: any) {
            console.log("Stripe Error: ", error?.message);
        } finally {
            setShowLoader(false);
        }
    }

    const cardElementOptions: StripeCardElementOptions = {
        style: {
            base: {
                color: "#212529", // Text color
                fontFamily: 'Poppins, sans-serif', // Font family
                fontSmoothing: "antialiased", // Anti-aliasing
                fontSize: "16px", // Font size
                "::placeholder": {
                    color: "#212529bf", // Placeholder color
                },
            },
            invalid: {
                color: "#fa755a", // Text color for invalid input
                iconColor: "#fa755a", // Icon color for invalid input
            },
        },
    }

    const handleCardElementChange = (e: StripeElementChangeEvent) => {
        const elementType = e?.elementType;
        if (e.error?.message) {
            setCardErrorMessage({
                ...cardErrorMessage,
                [elementType]: e.error?.message
            })
        }
        else {
            setCardErrorMessage({
                ...cardErrorMessage,
                [elementType]: ""
            })
        }
    }

    return (
        <div>
            <div className="card custom-card">
                <div className="card-header">
                    <div className='fs-4 fw-sembold'>Payment Details</div>
                </div>
                <div className="card-body vstack gap-3">
                    {/* Card Number Field */}
                    <div>
                        <label htmlFor="cardNumber" className="custom-form-label">
                            Card Number
                        </label>
                        <div
                            id="cardNumber"
                            className="custom-form-control vstack justify-content-center"
                        >
                            <CardNumberElement
                                options={{
                                    ...cardElementOptions,
                                    showIcon: true,
                                    iconStyle: "solid",
                                }}
                                onChange={handleCardElementChange}
                            />
                        </div>
                        {cardErrorMessage?.cardNumber && <div className='text-danger mt-1 px-1'>{cardErrorMessage?.cardNumber}</div>}
                    </div>

                    {/* Expiry Date Field */}
                    <div>
                        <label htmlFor="cardExpiry" className="custom-form-label">
                            Expiry Date
                        </label>
                        <div
                            id="cardExpiry"
                            className="custom-form-control vstack justify-content-center"
                        >
                            <CardExpiryElement
                                options={cardElementOptions}
                                onChange={handleCardElementChange}
                            />
                        </div>
                        {cardErrorMessage?.cardExpiry && <div className='text-danger mt-1 px-1'>{cardErrorMessage?.cardExpiry}</div>}
                    </div>

                    {/* CVC Field */}
                    <div>
                        <label htmlFor="cardCvc" className="custom-form-label">
                            CVV
                        </label>
                        <div
                            id="cardCvc"
                            className="custom-form-control vstack justify-content-center"
                        >
                            <CardCvcElement
                                options={{
                                    ...cardElementOptions,
                                }}
                                onChange={handleCardElementChange}
                            />
                        </div>
                        {cardErrorMessage?.cardCvc && <div className='text-danger mt-1 px-1'>{cardErrorMessage?.cardCvc}</div>}
                    </div>
                    <div className='hstack justify-content-between gap-2'>
                        <div className='custom-form-label mb-0'>Total amount:</div>
                        <div className='fs-4 fw-semibold'>$ <span>{subscriptionAmount.split('.')[0]}</span>.<span className='fs-6 text-muted'>{subscriptionAmount.split('.')[1]}</span></div>
                    </div>
                </div>
                <div className="card-footer">
                    <button
                        className="btn btn-primary btn-lg hstack justify-content-center gap-3"
                        disabled={!stripe || !elements || showLoader}
                        onClick={handlePayment}
                    >
                        {showLoader ? 'Payment is in progress' : 'Make Payment'}
                        {showLoader && <Spinner />}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPaymentForm
