import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "src/store/store"
import { TableColumn } from "src/types/types"

const userTableColumns: TableColumn[] = [
    {
        label: "Name",
        accessor: "fName",
        sortable: true
    },
    {
        label: "Email",
        accessor: "email",
        sortable: true
    },
    {
        label: "Status",
        accessor: "status",
        sortable: true
    },
    {
        label: "Actions",
        accessor: "actions",
        sortable: false
    }
]
export const useUserTableColumns = () => {

    // Redux State
    const { user } = useSelector((state: RootState) => state?.auth);

    // Hook State
    const [columns, setColumns] = useState<TableColumn[]>(userTableColumns);

    //Manipulate the columns
    useEffect(() => {
        const permissions: string[] = user?.permissions;
        // Remove the actions column.
        if (
            permissions
            && !permissions?.includes('user-update')
            && !permissions?.includes('user-delete')
        ) {
            const updatedCols = [...columns].filter(el => el?.accessor !== 'actions');
            setColumns(updatedCols);
        }
        //eslint-disable-next-line
    }, [user]);

    return {
        columns
    }
}