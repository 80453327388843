import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define types
export type UpdateIdType = string | null //uuid
export type DeleteIdType = number | null

//note
// deleteId = -1 -> delete all files
// deleteId = -2 -> delete newly selected files
interface ModalState {
    createTenantModal: boolean
    createUserModal: boolean
    createAvatarModal: boolean
    createRoleModal: boolean
    deleteModal: boolean
    updateTenantId: UpdateIdType
    updateUserId: UpdateIdType
    updateAvatarId: UpdateIdType
    updateRoleId: UpdateIdType
    deleteId: DeleteIdType
    deleteMsg: UpdateIdType
}


// Define the initial state
const initialState: ModalState = {
    createTenantModal: false,
    createUserModal: false,
    createAvatarModal: false,
    createRoleModal: false,
    deleteModal: false,
    updateTenantId: null,
    updateUserId: null,
    updateAvatarId: null,
    updateRoleId: null,
    deleteId: null,
    deleteMsg: null
};

// Create async thunks for authentication operations


// Create the slice
const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        createTenantModalHandler: (state, action: PayloadAction<boolean>) => {
            state.createTenantModal = action.payload
        },
        createUserModalHandler: (state, action: PayloadAction<boolean>) => {
            state.createUserModal = action.payload
        },
        createAvatarModalHandler: (state, action: PayloadAction<boolean>) => {
            state.createAvatarModal = action.payload
        },
        createRoleModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.createRoleModal = payload;
        },
        deleteModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.deleteModal = payload;
        },
        updateTenantIdHandler: ((state, { payload }: PayloadAction<UpdateIdType>) => {
            state.updateTenantId = payload
        }),
        updateUserIdHandler: ((state, { payload }: PayloadAction<UpdateIdType>) => {
            state.updateUserId = payload
        }),
        updateAvatarIdHandler: ((state, { payload }: PayloadAction<UpdateIdType>) => {
            state.updateAvatarId = payload
        }),
        updateRoleIdHandler: ((state, { payload }: PayloadAction<UpdateIdType>) => {
            state.updateRoleId = payload
        }),
        deleteIdHandler: ((state, { payload }: PayloadAction<DeleteIdType>) => {
            state.deleteId = payload;
        }),
        deleteMsgHandler: ((state, { payload }: PayloadAction<UpdateIdType>) => {
            state.deleteMsg = payload;
        }),
    }
});

export const { createTenantModalHandler, createUserModalHandler, createAvatarModalHandler, createRoleModalHandler, updateTenantIdHandler, updateUserIdHandler, updateAvatarIdHandler, updateRoleIdHandler, deleteModalHandler, deleteIdHandler, deleteMsgHandler } = modalSlice.actions
export default modalSlice.reducer;
