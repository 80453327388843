import { Humanoid } from "src/common/Babylon/Humanoid";
import { FULFILLED, IDLE, PENDING, REJECTED } from "src/constants/constants";

export type CameraConfig = {
    alpha: string;
    beta: string;
    radius: number;
    // target: Vector3;
    positionX: number
    positionY: number
    positionZ: number
};

export type HumanoidRef = React.MutableRefObject<Humanoid | null>;
export type CurrentSoundRef = React.MutableRefObject<HTMLAudioElement | null>;

export type BotResponse = {
    text: string
    audio: string
    citation: string[]
    thread_id?: string
}

export type StatusType = typeof IDLE | typeof PENDING | typeof FULFILLED | typeof REJECTED
export type StatusError = string | null

export type PaginationData = {
    pageNumber?: number,
    limit?: number,
    searchVal?: string,
    sortColumn?: string,
    sortType?: string
    userId?: string
}

export type Avatar = {
    uuid: string
    name: string
    role: string
    createdByAdmin: string
    tenantCode: string
    avatarCode: string
}

export type Option = {
    value: string
    label: string
}

export type SortOrder = 'ASC' | 'DESC';

export const commonStatus = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
    { value: 'DELETED', label: 'DELETED' },
    { value: 'SUSPENDED', label: 'SUSPENDED' },
]

export type TabType = 'tenant' | 'avatar' | 'conversation' | 'role' | 'user';

export interface TableColumn {
    label: string;
    accessor: string;
    sortable: boolean;
}