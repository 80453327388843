import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { getSubscriptionDetails } from 'src/store/slices/subscriptionSlice'


const PlanActive = () => {
    // Redux State
    const { currentPeriodEndDate } = useSelector(getSubscriptionDetails);
    return (
        <div className="card custom-card">
            <div className="card-body vstack gap-4 align-items-center">
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    size='4x'
                    className="text-primary"
                />
                <h5 className="card-title fs-3 text-primary">Active</h5>
                <p className="fs-5">
                    Your organization is currently active and running smoothly.
                </p>
                {
                    currentPeriodEndDate && (
                        <p className="fs-5 mb-0">
                            Your subscription will expire on:{" "}
                            <strong>{new Date(currentPeriodEndDate)?.toDateString()}</strong>
                        </p>
                    )
                }
            </div>
        </div>
    )
}


export default PlanActive
