import { ErrorMessage } from 'formik'
import { FC } from 'react'


interface ErrorMessageFormikProps {
    name: string
}
const ErrorMessageFormik: FC<ErrorMessageFormikProps> = ({ name }) => {
    return (
        <ErrorMessage name={name} component="div" className="text-danger mt-1 px-1" />
    )
}

export default ErrorMessageFormik