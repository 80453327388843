import { Form, Formik } from 'formik'
import { Modal, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { createTenantModalHandler, updateTenantIdHandler } from 'src/store/slices/modalSlice'
import { RootState, useAppDispatch } from 'src/store/store'
import FormField from '../FormField'
import * as Yup from "yup";
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { CREATE_TENANT, FULFILLED, GET_TENANT_BY_ID, UPDATE_TENANT } from 'src/constants/constants'
import { toast } from 'react-toastify'
import { createTenantHandler, getTenantByIdHandler, updateTenantHandler } from 'src/store/slices/tenantSlice'
import { getDifferences, phoneRegExp } from 'src/common/commonUtils'
import FormSelectField from '../FormSelectField'
import { LanguageCode, languages } from 'src/constants/languages'
import { FormReactDatePicker } from '../Form/ReactDatePicker'

export type CreateTenant = {
    organizationName: string
    organizationEmail: string
    organizationLanguage: LanguageCode
    firstName: string
    lastName: string
    email: string
    lang: LanguageCode
    contact: string
    openAIApiKey?: string | null
    googleVoiceCode?: string | null
    organizationExpiredAt: Date | null;
}

interface CreateTenantModalProps {
    getAllTenantsList: () => void
}
const CreateTenantModal: FC<CreateTenantModalProps> = ({ getAllTenantsList }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common');
    const { createTenantModal, updateTenantId } = useSelector((state: RootState) => state.modal)

    const [showLoader, setShowLoader] = useState<boolean>(false)

    const defaultValues: CreateTenant = {
        organizationName: '',
        organizationEmail: '',
        organizationLanguage: 'en-US',
        firstName: '',
        lastName: '',
        email: '',
        lang: 'en-US',
        contact: '',
        organizationExpiredAt: new Date()
    }

    const [initialValues, setInitialValues] = useState<CreateTenant>(defaultValues)

    const validationSchema = Yup.object().shape({
        organizationName: Yup.string().required(t("validation.required")),
        organizationEmail: Yup.string().required(t("validation.required")).email(t("validation.invalidEmail"))
            .test(
                'hyphen-not-allowed',
                t("validation.invalidEmail"),
                (val) => {
                    const result = val.startsWith('-');
                    return !result
                }
            )
            .notOneOf([Yup.ref('email'), null], t("validation.sameEmail")),
        firstName: Yup.string().min(3, t("validation.firstNameMin")).max(50, t("validation.firstNameMax")).required(t("validation.required")),
        lastName: Yup.string().nullable().min(3, t("validation.lastNameMin")).max(50, t("validation.lastNameMax")),
        email: Yup.string().required(t("validation.required")).email(t("validation.invalidEmail"),)
            .test(
                'hyphen-not-allowed',
                t("validation.invalidEmail"),
                (val) => {
                    const result = val.startsWith('-');
                    return !result
                }
            ).notOneOf([Yup.ref('organizationEmail'), null], t("validation.sameEmail")),
        contact: Yup.string().required("This field is required").matches(phoneRegExp, t("validation.invalidContact")),
        organizationExpiredAt: Yup.date().required('Organization Expire Date Required.')
    })

    const handleSubmit = (formValue: CreateTenant) => {
        setShowLoader(true)
        if (updateTenantId) {
            const differences = getDifferences(initialValues, formValue)
            if (differences === false) {
                hideModal()
            } else {
                const updateData = { id: updateTenantId, data: formValue }
                dispatch(updateTenantHandler(updateData)).then((res: any) => {
                    if (res.type === (UPDATE_TENANT + FULFILLED)) {
                        handleSuccess()
                    }
                    setShowLoader(false)
                })
            }
        } else {
            dispatch(createTenantHandler(formValue)).then((res: any) => {
                if (res.type === (CREATE_TENANT + FULFILLED)) {
                    handleSuccess()
                }
                setShowLoader(false)
            })
        }
    }

    const handleSuccess = () => {
        hideModal()
        getAllTenantsList()
    }

    const hideModal = () => {
        dispatch(updateTenantIdHandler(null))
        dispatch(createTenantModalHandler(false))
        setInitialValues(defaultValues)
    }

    const getTenantDetails = () => {
        if (updateTenantId) {
            dispatch(getTenantByIdHandler(updateTenantId)).then((res: any) => {
                if (res.type === (GET_TENANT_BY_ID + FULFILLED)) {
                    const payload = res.payload.data
                    const data = {
                        organizationName: payload.tenantUser.name,
                        organizationEmail: payload.tenantUser.tenantEmail,
                        organizationLanguage: payload.tenantUser.lang,
                        firstName: payload.fName,
                        lastName: payload.lName,
                        email: payload.email,
                        lang: payload.lang,
                        contact: payload.contact,
                        organizationExpiredAt: payload?.tenantUser?.expiredAt
                    }
                    setInitialValues(data)
                }
            })
        }
    }

    useEffect(() => {
        if (createTenantModal && updateTenantId) {
            getTenantDetails()
        }
    }, [createTenantModal, updateTenantId])

    return (
        <Modal centered backdrop="static" scrollable show={createTenantModal} onHide={hideModal} size='lg'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <Form className='modal-content'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {updateTenantId ? 'Update tenant details' : 'Enter tenant details'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="vstack gap-3">
                            <FormField type="text" name="organizationName" label="Organization's Name" />
                            <FormField type="email" name="organizationEmail" label="Organization's Email" disabled={updateTenantId} />
                            <FormSelectField options={languages} name="organizationLanguage" label="Organization's Language" />
                            <FormField type="text" name="firstName" label="First Name" />
                            <FormField type="text" name="lastName" label="Last Name" />
                            <FormField type="email" name="email" label="Email" disabled={updateTenantId} />
                            <FormSelectField options={languages} name="lang" label="Language" />
                            <FormField type="text" name="contact" label="Contact" />
                            <FormReactDatePicker
                                name='organizationExpiredAt'
                                label='Expiration Date'
                                minDate={new Date()}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <button className='btn btn-primary btn-lg hstack justify-content-center gap-3' type='submit' disabled={showLoader}>
                            {updateTenantId ? 'Save' : 'Create Tenant'}
                            {showLoader && <Spinner />}
                        </button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
    )
}

export default CreateTenantModal