import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import ErrorMessageFormik from "src/components/ErrorMessageFormik";
import { FORGOT_PASSWORD } from "src/constants/constants";

interface PasswordFieldProps {
    name: string;
    label: string;
    autoComplete?: string | undefined
    hasLink?: boolean
}

const PasswordField: React.FC<PasswordFieldProps> = ({ name, label, autoComplete = undefined, hasLink = false }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div>
            <div className='hstack justify-content-between gap-2 px-1'>
                <label htmlFor={name} className='mb-1 fw-500'>{label}</label>
                {hasLink && <Link to={FORGOT_PASSWORD}>Forgot?</Link>}
            </div>
            <div className="position-relative">
                <Field autoComplete={autoComplete} type={showPassword ? "text" : "password"} id={name} name={name} className="form-control form-control-lg rounded-4 pe-4 fs-6" />
                <button type="button" className="btn btn-0 position-absolute translate-middle-y top-50 end-0 me-1" style={{ width: 46, marginTop: 1.5 }} onClick={() => setShowPassword(!showPassword)}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-primary" />
                </button>
            </div>
            <ErrorMessageFormik name={name} />
        </div>
    );
};

export default PasswordField