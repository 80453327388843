import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'

interface ActionsProps {
    id: string
    permissionParent?: string
    editFn: (id: string) => void
    deleteFn: (id: string) => void
    actionLoader: string | null
}
const Actions: FC<ActionsProps> = ({ id, permissionParent, editFn, deleteFn, actionLoader }) => {
    const { user, isAdmin } = useSelector((state: RootState) => state.auth);
    const permissions = user?.permissions

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [mainPermission, setMainPermission] = useState<string[]>([])

    const hideAlert = () => {
        setShowAlert(false)
    }

    useEffect(() => {
        // get specific module permission like (avatar,user,role,etc) and then get specific permission like (read, create, update, delete)
        if (!isAdmin && permissions && permissions.length > 0) {
            const specificModulePermission = permissions.filter((permission: string) => permission.split('-')[0] === permissionParent)   //get first word
            const permissionCrud = specificModulePermission.map((permission: string) => {
                return permission.split('-')[permission.split('-').length - 1] //get last word
            })
            setMainPermission(permissionCrud)
        }
    }, [])

    return (
        <>

            {(isAdmin || mainPermission.includes('read')) &&
                <>
                    <div className="hstack justify-content-end gap-3">
                        {
                            (isAdmin || mainPermission.includes('update')) &&
                            <button className="btn bg-primary-subtle action-btn" title="Edit" onClick={() => editFn(id)}>
                                <FontAwesomeIcon icon={faPen} className='text-primary' />
                            </button>
                        }
                        {
                            (isAdmin || mainPermission.includes('delete')) &&
                            <div className='action-btn'>
                                <button className="btn bg-danger-subtle action-btn" title="Delete" disabled={actionLoader === id} onClick={() => setShowAlert(true)}>
                                    <FontAwesomeIcon icon={faTrash} className='text-danger' />
                                </button>
                                {actionLoader === id && <Spinner className='position-absolute w-100 h-100 top-0 start-0' />}
                            </div>
                        }
                    </div>
                    <Modal centered backdrop="static" scrollable show={showAlert} onHide={hideAlert}>
                        <Modal.Body>
                            <div className='mb-3 text-center fw-medium f-18'>Are you sure you want to delete this?</div>
                            <div className="hstack justify-content-center gap-3">
                                <button className='btn btn-secondary' onClick={hideAlert}>No! Go back.</button>
                                <button className='btn btn-danger' onClick={() => { deleteFn(id); hideAlert() }}>Yes! Delete.</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )
}

export default Actions