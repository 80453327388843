export type Gender = "MALE" | "FEMALE";
export type VoiceProvider = "Google Cloud" | "OpenAI" | "Elevenlabs";

export type VoiceInfo = {
    gender: Gender;
    provider: VoiceProvider;
};

export type VoiceCode = keyof typeof voices;

export const voices = {
    // "en-Drew-elevenlabs": { gender: "MALE", provider: "Elevenlabs" },
    "en-GB-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "en-GB-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "en-GB-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "en-GB-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "en-GB-Standard-F": { gender: "FEMALE", provider: "Google Cloud" },
    "en-US-Neural2-A": { gender: "MALE", provider: "Google Cloud" },
    "en-US-Neural2-C": { gender: "FEMALE", provider: "Google Cloud" },
    "en-US-Neural2-D": { gender: "MALE", provider: "Google Cloud" },
    "en-US-Neural2-E": { gender: "FEMALE", provider: "Google Cloud" },
    "en-US-Neural2-F": { gender: "FEMALE", provider: "Google Cloud" },
    "en-US-Neural2-G": { gender: "FEMALE", provider: "Google Cloud" },
    "en-US-Neural2-H": { gender: "FEMALE", provider: "Google Cloud" },
    "en-US-Neural2-I": { gender: "MALE", provider: "Google Cloud" },
    "en-US-Neural2-J": { gender: "MALE", provider: "Google Cloud" },
    "af-ZA-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "ar-XA-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "ar-XA-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "ar-XA-Standard-C": { gender: "MALE", provider: "Google Cloud" },
    "ar-XA-Standard-D": { gender: "FEMALE", provider: "Google Cloud" },
    "eu-ES-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "bn-IN-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "bn-IN-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "bn-IN-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "bn-IN-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "bg-BG-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "yue-HK-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "yue-HK-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "yue-HK-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "yue-HK-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "cs-CZ-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "da-DK-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "da-DK-Standard-C": { gender: "MALE", provider: "Google Cloud" },
    "da-DK-Standard-D": { gender: "FEMALE", provider: "Google Cloud" },
    "da-DK-Standard-E": { gender: "FEMALE", provider: "Google Cloud" },
    "nl-BE-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "nl-BE-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "nl-NL-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "nl-NL-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "nl-NL-Standard-C": { gender: "MALE", provider: "Google Cloud" },
    "nl-NL-Standard-D": { gender: "FEMALE", provider: "Google Cloud" },
    "nl-NL-Standard-E": { gender: "FEMALE", provider: "Google Cloud" },
    "en-AU-Polyglot-1": { gender: "MALE", provider: "Google Cloud" },
    "fil-PH-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "fil-PH-Standard-B": { gender: "FEMALE", provider: "Google Cloud" },
    "fil-PH-Standard-C": { gender: "MALE", provider: "Google Cloud" },
    "fil-PH-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "fi-FI-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "fr-CA-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "fr-CA-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "fr-CA-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "fr-CA-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "fr-FR-Polyglot-1": { gender: "MALE", provider: "Google Cloud" },
    "fr-FR-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "fr-FR-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "fr-FR-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "fr-FR-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "fr-FR-Standard-E": { gender: "FEMALE", provider: "Google Cloud" },
    "gl-ES-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "de-DE-Polyglot-1": { gender: "MALE", provider: "Google Cloud" },
    "de-DE-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "de-DE-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "de-DE-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "de-DE-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "de-DE-Standard-E": { gender: "MALE", provider: "Google Cloud" },
    "de-DE-Standard-F": { gender: "FEMALE", provider: "Google Cloud" },
    "el-GR-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "gu-IN-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "gu-IN-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "he-IL-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "he-IL-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "he-IL-Standard-C": { gender: "FEMALE", provider: "Google Cloud" },
    "he-IL-Standard-D": { gender: "MALE", provider: "Google Cloud" },
    "hi-IN-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "hi-IN-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "hi-IN-Standard-C": { gender: "MALE", provider: "Google Cloud" },
    "hi-IN-Standard-D": { gender: "FEMALE", provider: "Google Cloud" },
    "hu-HU-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "ca-ES-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "cmn-CN-Standard-A": { gender: "FEMALE", provider: "Google Cloud" },
    "cmn-CN-Standard-B": { gender: "MALE", provider: "Google Cloud" },
    "alloy": { gender: "FEMALE", provider: "OpenAI" },
    "echo": { gender: "MALE", provider: "OpenAI" },
    "fable": { gender: "MALE", provider: "OpenAI" },
    "onyx": { gender: "MALE", provider: "OpenAI" },
    "nova": { gender: "FEMALE", provider: "OpenAI" },
    "shimmer": { gender: "FEMALE", provider: "OpenAI" },
};

export const allVoiceOptions = Object.entries(voices).map(([value, { gender, provider }]) => ({
    value,
    label: `${value} (${gender}, ${provider})`
}));


type VoiceOptionsProps = {
    isGoogleKey: boolean;
    isElevenLabsKey?: boolean;
}
export const voiceOptions = ({ isGoogleKey, isElevenLabsKey = false }: VoiceOptionsProps) => {
    return Object.entries(voices)
        .filter(([_, { provider }]) => {
            if (!isGoogleKey && provider === "Google Cloud") return false;
            if (!isElevenLabsKey && provider === "ElevenLabs") return false;
            return true;
        })
        .map(([value, { gender, provider }]) => ({
            value,
            label: `${value} (${gender}, ${provider})`,
        }));
}


export const isGoogleVoice = (voice: string): boolean => {
    if (!Object.keys(voices).find((v) => v === voice)) {
        return false;
    }

    const voiceInfo = voices[voice as VoiceCode];

    return voiceInfo.provider === "Google Cloud";
};

export const isOpenAIVoice = (voice: string): boolean => {
    if (!Object.keys(voices).find((v) => v === voice)) {
        return false;
    }

    const voiceInfo = voices[voice as VoiceCode];

    return voiceInfo.provider === "OpenAI";
};


export const isElevenLabsVoice = (voice: string): boolean => {
    if (!Object.keys(voices).find((v) => v === voice)) {
        const errorMessage = `Voice ${voice} not found in voices object.`;
        throw new Error(errorMessage);
    }

    const voiceInfo = voices[voice as VoiceCode];

    return voiceInfo.provider === "elevenlabs";
};

export const getVoiceProvider = (voice: string): string => {
    if (!Object.keys(voices).find((v) => v === voice)) {
        return "";
    }
    const voiceInfo = voices[voice as VoiceCode];
    return voiceInfo.provider
};