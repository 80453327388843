import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { ASSISTANT, SEND_MESSAGE, CONVERSATION, GET_CONVERSATION_GROUP_BY_THREAD, GET_CONVERSATION_THREAD_MESSAGES } from 'src/constants/constants';
import { PaginationData } from 'src/types/types';

// Define types
export type ChatMessage = {
    content: string
    role: "user" | "system" | "assistant"
};

export type ChatState = {
    messages: ChatMessage[]
    isLoadingMessage: boolean
    errorMessage: string
    charCount: number
    abortController?: AbortController
    lastBotResponse: string | null
    threadId: string | null
    limitExceeds: boolean;
};

// Define the initial state
const initialState: ChatState = {
    messages: [],
    isLoadingMessage: false,
    errorMessage: "",
    charCount: 0, // textAreaValue
    abortController: undefined,
    lastBotResponse: null,
    threadId: null,
    limitExceeds: false
};

export type SendMessageData = {
    messageData: ChatMessage[]
    openAiAstId: string
    // voice: Voice
    // vector_id: string
    // documentName: string
    // signal?: AbortSignal
    threadId: string | null
}

// Create async thunks for authentication operations
export const sendMessageHandler = createAsyncThunk(
    SEND_MESSAGE,
    async (data: SendMessageData, { rejectWithValue }) => {
        try {
            const response = await api.post(`${CONVERSATION}/send-message`, data);
            return response?.data;
        } catch (error: any) {
            return rejectWithValue(error?.data);
        }
    }
);

export const getAllConversationsGroupByThread = createAsyncThunk(
    GET_CONVERSATION_GROUP_BY_THREAD,
    async (data: PaginationData, { rejectWithValue }) => {
        try {
            const response = await api.get(`${CONVERSATION}/group-by-thread`, {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getConversationThreadMessages = createAsyncThunk(
    GET_CONVERSATION_THREAD_MESSAGES,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${CONVERSATION}/by-thread-id/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }

)


// Create the slice
const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        handleChatErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorMessage = payload;
        },
        handleAbortController: (state, { payload }: PayloadAction<AbortController>) => {
            state.abortController = payload;
        },
        handleIsLoadingMessage: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingMessage = payload;
        },
        handleCharCount: (state, { payload }: PayloadAction<string>) => {
            state.charCount = payload.length;
        },
        handleInitialMessage: (state, { payload }: PayloadAction<string>) => {
            state.messages = [
                {
                    content: payload,
                    role: ASSISTANT,
                }
            ];
        },
        handleNewMessage: (state, { payload }: PayloadAction<ChatMessage>) => {
            const { content, role } = payload
            const lastMessageRole = state.messages?.at(-1)?.role;
            if (lastMessageRole === ASSISTANT && role === ASSISTANT) {

                state.messages[state.messages.length - 1] = payload;

                // If a server message is done loading, set lastLLMResponse.
                if (!state.isLoadingMessage && !state.errorMessage) {
                    state.lastBotResponse = content;
                }

            } else {
                state.errorMessage = "";
                state.messages.push(payload);
            }
        },
        resetMessageHandler: (state) => {
            state.messages = []
        },
        // Optional synchronous actions can be defined here
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessageHandler.pending, (state) => {

            })
            .addCase(sendMessageHandler.fulfilled, (state, action) => {
                if (!state.threadId) {
                    state.threadId = action.payload.data.threadId
                }
            })
            .addCase(sendMessageHandler.rejected, (state, action: PayloadAction<any>) => {
                state.limitExceeds = action?.payload?.limitExceeds;
            })
    },
});

export const { handleChatErrorMessage, handleAbortController, handleCharCount, handleIsLoadingMessage, handleInitialMessage, handleNewMessage, resetMessageHandler } = conversationSlice.actions

export default conversationSlice.reducer;
