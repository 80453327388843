import {
  faCircleNotch,
  faMicrophone,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  ASSISTANT,
  CHAT_BY_USER,
  FULFILLED,
  SEND_MESSAGE,
} from "src/constants/constants";
import { MAX_CHARS, OPENAI_TIMEOUT_MILLISECONDS } from "src/config/modelConfig";
import {
  ChatMessage,
  handleAbortController,
  handleCharCount,
  handleChatErrorMessage,
  handleIsLoadingMessage,
  handleNewMessage,
  sendMessageHandler,
} from "src/store/slices/conversationSlice";
import { RootState, useAppDispatch } from "src/store/store";
import { BotResponse } from "src/types/types";
import { RefContextProps } from "../Context/RefContextProvider";
import { LanguageCode } from "src/constants/languages";
import { getVoiceProvider } from "src/constants/voices";

type VoiceData = {
  voice: string;
  voiceProvider: string;
};

export const ChatTextArea: FC<RefContextProps> = ({ humanoidRef, currentSoundRef }) => {
  const dispatch = useAppDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { avatarData } = useSelector((state: RootState) => state.avatar);
  const {
    messages,
    isLoadingMessage,
    abortController,
    threadId,
    limitExceeds,
  } = useSelector((state: RootState) => state.conversation);
  const { isVolumeOn } = useSelector((state: RootState) => state.setting);
  const voice = avatarData.voice;
  const voiceProvider = getVoiceProvider(voice);
  const voiceData: VoiceData = { voice, voiceProvider };

  const hasText = textAreaRef.current
    ? textAreaRef.current.value.length > 0
    : false;

  const {
    transcript,
    listening,
    resetTranscript,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const listen = async (language: LanguageCode): Promise<void> => {
    await SpeechRecognition.startListening({
      continuous: false,
      language,
    });
  };

  const validateMessageLength = (newMessage: ChatMessage): boolean => {
    if (newMessage.content.length > MAX_CHARS) {
      dispatch(
        handleChatErrorMessage(
          `Please enter a message with ${MAX_CHARS} characters or less.`
        )
      );
      return false;
    }
    return true;
  };

  const handleBotResponse = async (response: BotResponse) => {
    const textContent = response.text;
    const audioContent = response.audio;

    const newMessage: ChatMessage = { content: textContent, role: ASSISTANT };
    dispatch(handleNewMessage(newMessage));

    var audio = new Audio(`data:audio/mpeg;base64,${audioContent}`);

    currentSoundRef.current?.pause();

    humanoidRef.current?.talkAnimationEnd("Received new audio");

    if (!audio || !audioContent) {
      return;
    }

    if (!isVolumeOn) {
      audio.volume = 0;
    }

    // @todo: send google audio from backend

    const onAudioEnd = () => {
      humanoidRef.current?.talkAnimationEnd("onAudioEnd");
    };

    // XXX: pause event is emitted after the pause() method is called or BEFORE an ended or seeking event
    // newAudio.addEventListener("pause", onAudioEnd);
    audio.addEventListener("ended", onAudioEnd);

    audio.play();

    currentSoundRef.current = audio;
    humanoidRef.current?.talkAnimationStart();
  };

  const sendPostRequestWithMultipleMessages = async (
    messageData: ChatMessage[]
  ) => {
    let errorMessage = "";

    if (isLoadingMessage && abortController) {
      // console.log("Aborting previous request.");
      abortController.abort("New request sent - aborting previous.");
    }

    try {
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => {
        // console.log("Request took too long. Aborting.");
        abortController.abort("Request took too long. Aborting.");
      }, OPENAI_TIMEOUT_MILLISECONDS);

      dispatch(handleIsLoadingMessage(true));
      dispatch(handleAbortController(abortController));
      const data = {
        messageData,
        voiceData,
        openAiAstId: avatarData.openAiAstId,
        threadId,
      };

      // console.log("sendMessageData", data)
      //async await for sendbutton loading
      await dispatch(sendMessageHandler(data)).then(async (res: any) => {
        // console.log("sendMessageResponse", res.payload)
        if (res.type === SEND_MESSAGE + FULFILLED) {
          await handleBotResponse(res.payload.data);
        }
      });

      // We have a response! Maybe it's an error, but not worries. We'll handle it below.
      clearTimeout(timeoutId);
    } catch (error) {
      // console.log("CHAT API ERROR: ", error);
      errorMessage = "Error: something went wrong.";

      if (error instanceof Error) {
        if (error.message.includes("abort")) {
          errorMessage =
            "Request aborted due to timeout. This could be due to an incorrect OpenAI API key or internet connectivity issues.";
        } else {
          errorMessage = error.message;
        }
      }
    }
    dispatch(handleChatErrorMessage(errorMessage));
    dispatch(handleIsLoadingMessage(false));
  };

  const sendChatMessage = async () => {
    if (isLoadingMessage) {
      return;
    }

    const newMessage: ChatMessage = {
      content: textAreaRef?.current?.value || "",
      role: CHAT_BY_USER,
    };

    if (textAreaRef?.current && newMessage.content) {
      if (!validateMessageLength(newMessage)) return;

      textAreaRef.current.value = "";
      textAreaRef.current.focus();

      dispatch(handleNewMessage(newMessage));

      let messagesToSendToBackend;
      if (threadId) {
        messagesToSendToBackend = [newMessage];
      } else {
        messagesToSendToBackend = [...messages, newMessage]; //first message
      }

      await sendPostRequestWithMultipleMessages(messagesToSendToBackend);
    }
  };

  // The following useEffect is used to scroll to the bottom of the text area.
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [textAreaRef]);

  // The following useEffect updates the text area value when the speech recognition transcript is updated.
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.value = transcript;
      dispatch(handleCharCount(transcript));
    }
  }, [transcript, dispatch]);

  return (
    <div className="position-relative hstack w-100">
      <textarea
        className="custom-form-control"
        rows={3}
        ref={textAreaRef}
        placeholder={
          isLoadingMessage ? "Loading message..." : "Type message here"
        }
        disabled={limitExceeds || isLoadingMessage}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendChatMessage();
          }
        }}
        onChange={(e) => {
          dispatch(handleCharCount(e.target.value));
        }}
      />
      <div className="position-absolute me-3 end-0">
        <button
          className="send-button btn btn-primary"
          disabled={limitExceeds || isLoadingMessage || listening}
          onClick={() => {
            if (hasText) {
              sendChatMessage();
            } else if (!isMicrophoneAvailable) {
              window.alert("Microphone is not available :(");
            } else if (!browserSupportsSpeechRecognition) {
              window.alert(
                "Speech Recognition API is not supported in your browser :("
              );
            } else {
              listen(voice);
            }
          }}
        >
          {isLoadingMessage ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          ) : (
            <FontAwesomeIcon icon={hasText ? faPaperPlane : faMicrophone} />
          )}
        </button>
      </div>
    </div>
  );
};
