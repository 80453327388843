import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CREATE_ROLE, DELETE_ROLE, GET_ALL_ROLES_AND_PERMS, GET_ALL_ROLES, GET_ROLES_AND_PERMS, IDLE, ROLE, UPDATE_ROLES_AND_PERMS } from "../../constants/constants";
import { handleErrors } from "../../common/commonUtils";
import { api } from "src/common/axiosInstance";
import { PaginationData } from "src/types/types";
import { toast } from "react-toastify";


// Define types
interface UsersState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string | null
}

// Define the initial state
const initialState: UsersState = {
    status: IDLE,
    error: null
}
// Create async thunks for users operations
export const createRole = createAsyncThunk(
    CREATE_ROLE,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await api.post(`${ROLE}/create`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllRolesAndPerms: any = createAsyncThunk(
    GET_ALL_ROLES_AND_PERMS,
    async (data: PaginationData & { id: string }, { rejectWithValue }) => {
        try {
            const pagination: PaginationData = {
                limit: data?.limit,
                pageNumber: data?.pageNumber,
                searchVal: data?.searchVal,
                sortColumn: data?.sortColumn,
                sortType: data?.sortType
            }
            const response = await api.get(`${ROLE}/all/${data.id}`, {
                params: pagination
            });
            return response.data;

        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllRolesByUserId: any = createAsyncThunk(
    GET_ALL_ROLES,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${ROLE}/${id}`);
            return response.data;

        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getRolesAndPermsById: any = createAsyncThunk(
    GET_ROLES_AND_PERMS,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${ROLE}/role-permissions/${id}`);
            return response.data;

        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const updateRolesAndPerms: any = createAsyncThunk(
    UPDATE_ROLES_AND_PERMS,
    async ({ id, data }: { id: string, data: any }, { rejectWithValue }) => {
        try {
            const response = await api.put(`${ROLE}/${id}`, data);
            return response.data;

        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const deleteRolesAndPerms: any = createAsyncThunk(
    DELETE_ROLE,
    async (id: any, { rejectWithValue }) => {
        try {
            const response = await api.delete(`${ROLE}/${id}`);
            return response.data;

        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);


//create the slice
const roleSlice = createSlice({
    name: ROLE,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            //updateRolesAndPerms
            .addCase(updateRolesAndPerms.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(updateRolesAndPerms.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //createRole
            .addCase(createRole.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(createRole.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
    },
});

export const { } = roleSlice.actions
export default roleSlice.reducer;
