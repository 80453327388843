import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CREATE_TENANT, DELETE_TENANT, GET_ALL_TENANTS, GET_TENANT_BY_ID, TENANT, UPDATE_TENANT } from 'src/constants/constants';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { CreateTenant } from 'src/components/Modals/CreateTenantModal';
import { PaginationData } from 'src/types/types';
import { toast } from 'react-toastify';
import { createTenantSubscription } from 'src/services/payment.service';

interface TenantState {
}

// Define the initial state
const initialState: TenantState = {
};

export const createTenantHandler = createAsyncThunk(
    CREATE_TENANT,
    async (data: CreateTenant, { rejectWithValue }) => {
        try {
            const response = await api.post(`${TENANT}/create`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllTenantsHandler = createAsyncThunk(
    GET_ALL_TENANTS,
    async (data: PaginationData, { rejectWithValue }) => {
        try {
            const response = await api.get(`${TENANT}/all`, {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getTenantByIdHandler = createAsyncThunk(
    GET_TENANT_BY_ID,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${TENANT}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const updateTenantHandler = createAsyncThunk(
    UPDATE_TENANT,
    async ({ id, data }: { id: string, data: CreateTenant }, { rejectWithValue }) => {
        try {
            const response = await api.put(`${TENANT}/${id}`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const deleteTenantHandler = createAsyncThunk(
    DELETE_TENANT,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.delete(`${TENANT}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

// Create the slice
const tenantSlice = createSlice({
    name: TENANT,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            //getAllTenantsHandler
            .addCase(getAllTenantsHandler.pending, (state) => {
            })
            .addCase(getAllTenantsHandler.fulfilled, (state) => {
            })
            .addCase(getAllTenantsHandler.rejected, (state, action: PayloadAction<any>) => {

                // toast.error(action.payload)
            })
            //deleteTenantHandler
            .addCase(deleteTenantHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })

            // createTenantHandler
            .addCase(createTenantHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(createTenantHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            // updateTenantHandler
            .addCase(updateTenantHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(updateTenantHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(createTenantSubscription.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action?.payload);
            })
    },
});

export const { } = tenantSlice.actions
export default tenantSlice.reducer;
