export type LanguageCode =
    | 'af'
    | 'eu'
    | 'bg'
    | 'ca'
    | 'ar-MA'
    | 'cs'
    | 'nl-NL'
    | 'en-GB'
    | 'en-US'
    | 'fi'
    | 'fr-FR'
    | 'gl'
    | 'de-DE'
    | 'el-GR'
    | 'he'
    | 'hu'
    | 'is'
    | 'it-IT'
    | 'id'
    | 'ja'
    | 'ko'
    | 'la'
    | 'zh-CN'
    | 'zh-TW'
    | 'zh-HK'
    | 'ms-MY'
    | 'no-NO'
    | 'pl'
    | 'pt-PT'
    | 'pt-BR'
    | 'ro-RO'
    | 'sr-SP'
    | 'sk'
    | 'es-ES'
    | 'sv-SE'
    | 'tr'
    | 'zu';

interface LanguageOption {
    value: LanguageCode;
    label: string;
}

export const languages: LanguageOption[] = [
    { "value": "af", "label": "Afrikaans" },
    { "value": "eu", "label": "Basque" },
    { "value": "bg", "label": "Bulgarian" },
    { "value": "ca", "label": "Catalan" },
    { "value": "ar-MA", "label": "Arabic (Morocco)" },
    { "value": "cs", "label": "Czech" },
    { "value": "nl-NL", "label": "Dutch" },
    { "value": "en-GB", "label": "English (UK)" },
    { "value": "en-US", "label": "English (US)" },
    { "value": "fi", "label": "Finnish" },
    { "value": "fr-FR", "label": "French" },
    { "value": "gl", "label": "Galician" },
    { "value": "de-DE", "label": "German" },
    { "value": "el-GR", "label": "Greek" },
    { "value": "he", "label": "Hebrew" },
    { "value": "hu", "label": "Hungarian" },
    { "value": "is", "label": "Icelandic" },
    { "value": "it-IT", "label": "Italian" },
    { "value": "id", "label": "Indonesian" },
    { "value": "ja", "label": "Japanese" },
    { "value": "ko", "label": "Korean" },
    { "value": "la", "label": "Latin" },
    { "value": "zh-CN", "label": "Mandarin Chinese" },
    { "value": "zh-TW", "label": "Taiwanese" },
    { "value": "zh-HK", "label": "Cantonese" },
    { "value": "ms-MY", "label": "Malaysian" },
    { "value": "no-NO", "label": "Norwegian" },
    { "value": "pl", "label": "Polish" },
    { "value": "pt-PT", "label": "Portuguese" },
    { "value": "pt-BR", "label": "Portuguese (Brasil)" },
    { "value": "ro-RO", "label": "Romanian" },
    { "value": "sr-SP", "label": "Serbian" },
    { "value": "sk", "label": "Slovak" },
    { "value": "es-ES", "label": "Spanish (Spain)" },
    { "value": "sv-SE", "label": "Swedish" },
    { "value": "tr", "label": "Turkish" },
    { "value": "zu", "label": "Zulu" }
]