import { useField } from "formik";
import { FC } from "react";
import DatePicker from "react-datepicker";
import ErrorMessageFormik from "src/components/ErrorMessageFormik";

interface Props {
    name: string;
    minDate?: Date;
    maxDate?: Date;
    labelClasses?: string;
    label?: string;
    datePickerClasses?: string;
    dateFormat?: string;
}

export const FormReactDatePicker: FC<Props> = ({
    name,
    minDate,
    maxDate,
    label,
    labelClasses = 'custom-form-label',
    datePickerClasses = 'custom-form-control',
    dateFormat = 'MMMM d, yyyy'
}) => {

    const [field, meta, helpers] = useField(name);

    const { value } = meta;
    const { setValue } = helpers;

    return (
        <div className=" w-100">
            {
                label && (
                    <label htmlFor={name} className={labelClasses}>
                        {label}
                    </label>
                )
            }
            <DatePicker
                dateFormat={dateFormat}
                name={name}
                selected={value}
                onChange={date => setValue(date)}
                minDate={minDate}
                maxDate={maxDate}
                className={datePickerClasses}
                wrapperClassName="d-inline-block w-100"
            />
            <ErrorMessageFormik name={name} />
        </div>
    )
}