import { Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { createRoleModalHandler, updateRoleIdHandler } from '../../store/slices/modalSlice';
import { RootState, useAppDispatch } from 'src/store/store';
import { FC, useEffect, useState } from 'react';
import { createRole, getRolesAndPermsById, updateRolesAndPerms } from 'src/store/slices/roleSlice';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CREATE_ROLE, FULFILLED, GET_ROLES_AND_PERMS, UPDATE_ROLES_AND_PERMS } from 'src/constants/constants';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormField from '../FormField';
import ErrorMessageFormik from '../ErrorMessageFormik';

interface CreateRoleModalProps {
    getAllRolesPermissionsList: () => void;
}

const CreateRoleModal: FC<CreateRoleModalProps> = ({ getAllRolesPermissionsList }) => {
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const { createRoleModal, updateRoleId } = useSelector((state: RootState) => state.modal);
    const { permissions } = useSelector((state: RootState) => state.permission);
    const { user } = useSelector((state: RootState) => state.auth);
    const userId = user?.user?.uuid;

    const [showLoader, setShowLoader] = useState<boolean>(false);

    interface FormValues {
        role: string;
        permissions: string[];
    }

    // Set the default values with explicit typing
    const defaultValues: FormValues = {
        role: '',
        permissions: [],
    };

    // Use initialValues with the explicit type
    const [initialValues, setInitialValues] = useState<FormValues>(defaultValues);
    const [selectedPermissions, setSelectedPermissions] = useState<any>([]);
    const validationSchema = Yup.object({
        role: Yup.string().required(t("roleValidation.roleRequired")),
        permissions: Yup.array().min(1, (t('roleValidation.perimissionRequired'))),
    });


    const handleSuccess = () => {
        hideModal()
        getAllRolesPermissionsList()
    }

    const hideModal = () => {
        dispatch(createRoleModalHandler(false))
        dispatch(updateRoleIdHandler(null))
        setInitialValues(defaultValues)
        setSelectedPermissions([])
    };

    const handlePermissionChange = (e: React.ChangeEvent<HTMLInputElement>, permission: string, group: string, setFieldValue: (name: string, value: any) => void) => {
        let updatedPermissions = [...selectedPermissions];
        if (e.target.checked) {
            updatedPermissions.push(permission);
        } else {
            updatedPermissions = updatedPermissions.filter((perm) => perm !== permission);
        }
        if (['create', 'update', 'delete'].some((action) => permission.includes(action))) {
            const hasActionChecked = ['create', 'update', 'delete'].some((action) =>
                updatedPermissions.includes(`${group}-${action}`)
            );
            if (hasActionChecked) {
                if (!updatedPermissions.includes(`${group}-read`)) {
                    updatedPermissions.push(`${group}-read`);
                }
            } else {
                updatedPermissions = updatedPermissions.filter((perm) => perm !== `${group}-read`);
            }
        }
        setFieldValue('permissions', updatedPermissions)
        setSelectedPermissions(updatedPermissions);
    };

    const handleSubmit = async (values: typeof defaultValues) => {
        setShowLoader(true)
        const formData = {
            uuid: userId,
            role: values.role,
            permissions: values.permissions,
        };
        try {
            if (updateRoleId) {
                const updatedData = { id: updateRoleId, data: formData };
                await dispatch(updateRolesAndPerms(updatedData)).then((res: any) => {
                    if (res.type === UPDATE_ROLES_AND_PERMS + FULFILLED) {
                        handleSuccess();
                    }
                });
            } else {
                await dispatch(createRole(formData)).then((res: any) => {
                    if (res.type === CREATE_ROLE + FULFILLED) {
                        handleSuccess();
                    }
                })
            }
            setShowLoader(false)
        } catch (err: any) {
            setShowLoader(false)
            toast.error(err.message);
            console.error('Error submitting role:', err);
        }
    };

    const getRoleAndPermissionById = async () => {
        try {
            const res = await dispatch(getRolesAndPermsById(updateRoleId));
            if (res.type === (GET_ROLES_AND_PERMS + FULFILLED)) {
                const rolePermissions = res?.payload?.permissions.map((p: any) => p?.permission);
                const updatedPermissions = [...rolePermissions];
                permissions.forEach((permissionGroup: any) => {
                    const groupName = permissionGroup.name.toLowerCase();
                    const hasActionChecked = ['create', 'update', 'delete'].some((action) =>
                        updatedPermissions?.includes(`${groupName}-${action}`)
                    );
                    if (hasActionChecked && !updatedPermissions.includes(`${groupName}-read`)) {
                        updatedPermissions?.push(`${groupName}-read`);
                    }
                });
                setInitialValues({ role: res.payload.name, permissions: updatedPermissions });
                setSelectedPermissions(updatedPermissions);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (createRoleModal && updateRoleId) {
            getRoleAndPermissionById();
        }
    }, [createRoleModal, updateRoleId]);

    return (
        <Modal centered backdrop="static" scrollable show={createRoleModal} onHide={hideModal} size='lg'>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit({ ...values, permissions: selectedPermissions })}
            >
                {({ setFieldValue }) => (
                    <Form className="modal-content">
                        <Modal.Header closeButton>
                            <Modal.Title>{updateRoleId ? 'Update Role' : 'Create Role'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="vstack gap-3">
                                <FormField type="text" name="role" label="Role" />
                                <h4>Permissions</h4>
                                <div className='row g-3'>
                                    {permissions &&
                                        permissions.length > 0 &&
                                        permissions.map((permissionGroup: any) => (
                                            <div className='col-md-6' key={permissionGroup.name}>
                                                <h5>{permissionGroup.title}</h5>
                                                <div>
                                                    {permissionGroup.permissions.map((perm: any) => {
                                                        return (<div key={perm.name}>
                                                            <label className='hstack gap-2'>
                                                                <Field
                                                                    type="checkbox"
                                                                    name="permissions"
                                                                    value={perm?.name}
                                                                    onChange={(e: any) => handlePermissionChange(e, perm.name, permissionGroup.name.toLowerCase(), setFieldValue)}
                                                                    checked={selectedPermissions?.includes(perm.name)}
                                                                    disabled={
                                                                        perm.name.endsWith('-read') &&
                                                                        ['create', 'update', 'delete'].some((action) =>
                                                                            selectedPermissions?.includes(`${permissionGroup.name.toLowerCase()}-${action}`)
                                                                        )
                                                                    }
                                                                />
                                                                {perm.title}
                                                            </label>
                                                        </div>)
                                                    })}
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                <div className='text-center'>
                                    <ErrorMessageFormik name='permissions' />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <button className="btn btn-primary btn-lg hstack justify-content-center gap-3 me-3" type='submit' disabled={showLoader}>
                                {updateRoleId ? 'Save' : 'Create Role'}
                                {showLoader && <Spinner />}
                            </button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateRoleModal;
