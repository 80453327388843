import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { substractDays } from 'src/common/commonUtils';
import PlanExpired from './PlanExpired';
import PlanActive from './PlanActive';
import { StripeSubscriptionCard } from './CreditCard/StripeSubcription';
import { getSubscriptionDetails } from 'src/store/slices/subscriptionSlice';
import PlanInactive from './PlanInactive';
import { useTranslation } from 'react-i18next';
import { Loader } from 'src/components/Loader';

type PlanStatus = 'plan_expired' | 'plan_active' | 'credit_card' | 'plan_inactive' | ''

const Subscription = () => {
    const { t } = useTranslation('common');

    // Redux State
    const { temporaryExpiredAt: expiredAt, status, isLoading: getSubscriptionLoader } = useSelector(getSubscriptionDetails);


    // Component State
    const [activeTab, setActiveTab] = useState<PlanStatus>('');


    // Validate the tenant's plan will be expired in next 5 days.
    useEffect(() => {
        if (!status) {
            setActiveTab('plan_inactive');
        } else if (expiredAt) {
            const maxPlanExpirationDate = new Date(expiredAt);
            const minPlanExpirationDate = substractDays(new Date(expiredAt), 5);
            const todayDate = new Date();

            if (
                (todayDate >= minPlanExpirationDate && todayDate <= maxPlanExpirationDate)
                || todayDate > maxPlanExpirationDate
            ) {
                setActiveTab('plan_expired');
            }
            else {
                setActiveTab('plan_active');
            }
        }
    }, [status, expiredAt])


    return (
        <div className='container-xl h-page'>
            <div className='vstack gap-4'>
                <div className='text-primary fs-2 mb-4'>{t('common.Subscription')}</div>
                {getSubscriptionLoader && <Loader />}
                {!getSubscriptionLoader && (() => {
                    switch (activeTab) {
                        case 'credit_card':
                            return <StripeSubscriptionCard />
                        case 'plan_expired':
                            return <PlanExpired onPurchase={() => setActiveTab('credit_card')} />
                        case 'plan_active':
                            return <PlanActive />
                        case 'plan_inactive':
                            return <PlanInactive onClick={() => setActiveTab('credit_card')} />
                        default:
                            return <></>
                    }
                })()}
            </div>
        </div>
    )
}

export default Subscription
