import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "src/assets/styles/main.scss"
import { api } from './common/axiosInstance';
import { logoutHandler } from './store/slices/authSlice';
import { toast } from 'react-toastify';
import en from "src/translations/en/common.json";
import de from "src/translations/de/common.json";

i18next.init({
  resources: {
    en: {
      common: en               // 'common' is our custom namespace
    },
    de: {
      common: de
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
  lng: window.localStorage.language,
  fallbackLng: "en",
})



let abortController = new AbortController();

api.interceptors.request.use(
  (req) => {
    const token = store.getState().auth.user?.token || store.getState().auth.admin?.token
    // const showLoader = store.getState().common.showLoader
    if (token) {
      req.headers.Authorization = 'Bearer ' + token;
    }
    req.signal = abortController.signal;
    // !showLoader && dispatch(enableLoader(true))
    return req;
  },
  (err) => {
    if (err?.response?.status === 401) {
      store.dispatch(logoutHandler())
    } else {
      return Promise.reject(err);
    }
  }
);

api.interceptors.response.use(
  (res) => {
    // const showLoader = store.getState().common.showLoader
    // showLoader && dispatch(enableLoader(false));
    return res;
  },
  (err) => {
    // console.log("err", err);
    // const showLoader = store.getState().common.showLoader
    // showLoader && dispatch(enableLoader(false));
    // handleErrors(err)
    if (err?.response?.status === 401) {
      // due to jwt expiry or invalid token while resetpwd
      toast.error(err?.response?.data?.message || "Session Timeout. Please login again");
      // dispatch(sessionExpiredHandler(true))
      setTimeout(() => {
        store.dispatch(logoutHandler())
      }, 2000)

      // Abort all ongoing requests
      abortController.abort();
      abortController = new AbortController();
      return Promise.reject(err);
    } else if (err?.response?.status === 402) {
      toast.error(err?.response?.data?.message || "Please contact your administrator");
      setTimeout(() => {
        store.dispatch(logoutHandler())
      }, 2000)
      // Abort all ongoing requests
      abortController.abort();
      abortController = new AbortController();
      return Promise.reject(err);
    }
    else if (err?.response?.status === 403
      // || err?.response?.status === 500
    ) {
      toast.error(err?.response?.data.message)
      return Promise.reject(err?.response);
    } else {
      return Promise.reject(err);
    }
  }
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
