import { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "src/store/store";
import BotMessage from "./Message/BotMessage";
import UserMessage from "./Message/UserMessage";
import { ChatMessage, handleNewMessage, resetMessageHandler } from "src/store/slices/conversationSlice";
import { ASSISTANT } from "src/constants/constants";


const ChatMessages = memo(() => {
    const dispatch = useAppDispatch()

    const { messages } = useSelector((state: RootState) => state.conversation)
    const { avatarData, isAvatarValidated } = useSelector((state: RootState) => state.avatar)
    // const { modelInfo, didInit } = useSelector((state: RootState) => state.model)
    const endRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [messages]);

    useEffect(() => {
        if (avatarData && isAvatarValidated) {
            dispatch(resetMessageHandler())
            const newMessage: ChatMessage = { content: avatarData.initialMessage, role: ASSISTANT }
            dispatch(handleNewMessage(newMessage))
        }
    }, [avatarData, isAvatarValidated, dispatch])

    return (
        <div className="chat-messages-wrapper">
            {messages?.map((message: ChatMessage, index) => {
                if (message.role === ASSISTANT) {
                    return <BotMessage message={message} key={message.role + index} />;
                } else {
                    return <UserMessage message={message} key={message.role + index} />;
                }
            })}
            <div ref={endRef} /> {/* div for auto scrolling purposes */}
        </div>
    );
});

export default ChatMessages