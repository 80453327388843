import { FC, ReactNode } from 'react'

interface AuthWrapperProps {
    title: string
    bannerClassName: string
    children: ReactNode
}
const AuthWrapper: FC<AuthWrapperProps> = ({ title, bannerClassName, children }) => {
    return (
        <div className='container-fluid vh-100'>
            <div className="row h-100">
                <div className={`col-xl-3 col-lg-4 position-relative ${bannerClassName}`}></div>
                <div className="col-xl-9 col-lg-8">
                    <div className='vstack justify-content-center h-100 px-4 px-lg-5'>
                        <div className="row justify-content-center justify-content-lg-start mb-5">
                            <div className="col-lg-8">
                                <div className='vstack gap-4'>
                                    <div className='text-primary fs-2'>{title}</div>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthWrapper