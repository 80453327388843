import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { getConversationThreadMessages } from "src/store/slices/conversationSlice";
import { FULFILLED, GET_CONVERSATION_THREAD_MESSAGES } from "src/constants/constants";
import { Chat } from "./Chat/Chat";
import { Spinner } from "react-bootstrap";

interface Props {
    conversationId: string;
}
interface IConversationDetails {
    userData: {
        uuid: string;
        fName: string;
        lName: string;
    };
    avatarData: {
        uuid: string;
        name: string;
    }
}

export const ConversationThreadMessages: FC<Props> = ({
    conversationId
}) => {

    // Redux State.
    const dispatch = useAppDispatch();
    const [loader, setLoader] = useState<boolean>(false);

    const [conversationDetails, setConversationDetails] = useState<IConversationDetails | null>(null);
    const [messages, setMessages] = useState([]);



    async function getConversations(id: string) {
        setLoader(true);
        const res = await dispatch(getConversationThreadMessages(id));
        if (res.type === GET_CONVERSATION_THREAD_MESSAGES + FULFILLED) {
            const data = res?.payload;
            if (data?.success) {
                const resData = data?.data;
                setMessages(resData?.conversations);
                setConversationDetails({
                    userData: resData?.userData,
                    avatarData: resData?.avatarData
                })
            }
        }
        setLoader(false);
    }


    // Get the conversations
    useEffect(() => {
        getConversations(conversationId);
        //eslint-disable-next-line
    }, []);

    if (loader) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner />
            </div>
        )
    }

    return (
        <>
            {
                conversationDetails && messages?.length > 0 && (
                    <Chat
                        messages={messages}
                        avatarData={conversationDetails?.avatarData}
                        userData={conversationDetails?.userData}
                    />
                )
            }
        </>
    )
}