import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthWrapper from "./AuthWrapper";
import PasswordField from "../../components/PasswordField";
import { useAppDispatch } from "src/store/store";
import { Link, useParams } from "react-router-dom";
import { ICheckPassword, IResetPassword, logoutHandler, resetPasswordHandler } from "src/store/slices/authSlice";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { FULFILLED, RESET_PASSWORD, SIGNIN } from "src/constants/constants";


const ResetPassword = () => {
    const dispatch = useAppDispatch();
    let { token } = useParams()

    const [showLoader, setShowLoader] = useState<boolean>(false)

    //formik starts here
    const initialValues: ICheckPassword = {
        newPassword: "",
        confirmPassword: ""
    };

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required("This field is required").transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
            .test(
                "len",
                "Password must be between 6 to 40 characters",
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
                }
            ).test(
                "no-spaces",
                "Spaces are not allowed",
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ).notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password'),


        confirmPassword: Yup.string()
            .required("This field is required").transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
            .test(
                "len",
                "Password must be between 6 to 40 characters",
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
                }
            ).test(
                "no-spaces",
                "Spaces are not allowed",
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ).oneOf([Yup.ref('newPassword')], 'Passwords must match')
    });
    //formik ends here


    //functions starts here
    const handleResetPassword = async (formValue: ICheckPassword) => {
        if (!token) return
        setShowLoader(true)
        const { newPassword } = formValue
        const data: IResetPassword = { token, newPassword }
        await dispatch(resetPasswordHandler(data)).then((res: any) => {
            if (res.type === RESET_PASSWORD + FULFILLED) {
                setShowLoader(false)
                setTimeout(() => {
                    dispatch(logoutHandler())
                }, 1000)
            } else {
                setShowLoader(false)
            }
        })
    };

    return (
        <AuthWrapper title="Reset Password" bannerClassName="banner-2">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleResetPassword}
            >
                <Form className='vstack gap-4'>
                    <PasswordField name="newPassword" label="Password" />
                    <PasswordField name="confirmPassword" label="Confirm Password" />

                    <button className='btn btn-primary btn-lg mt-3 hstack justify-content-center gap-3' type='submit' disabled={showLoader}>
                        Set new password
                        {showLoader ? <Spinner /> : ''}
                    </button>
                </Form>
            </Formik>
            <div className='hstack gap-2 px-1'>
                <span>or</span>
                <Link to={SIGNIN}>Sign in</Link>
            </div>
        </AuthWrapper>
    );
}

export default ResetPassword