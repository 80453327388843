import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getLoggedInUserDetails } from "src/store/slices/userSlice";
import { RootState, useAppDispatch } from "src/store/store";

const UserLayout = () => {

    // Redux State
    const dispath = useAppDispatch();
    const { isUser, user } = useSelector((state: RootState) => state.auth);
    const isOwner = user?.user?.roles?.[0]?.name === "OWNER";

    async function fetchLoggedInUserDetails() {
        await dispath(getLoggedInUserDetails(null));
    }

    // Get the logged in data.
    useEffect(() => {
        if (isUser && isOwner) {
            fetchLoggedInUserDetails();
        }
        //eslint-disable-next-line
    }, [isUser, isOwner])

    return (
        <>
            <Outlet />
        </>
    )
}

export default UserLayout;