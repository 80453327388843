import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getDifferences, phoneRegExp } from "src/common/commonUtils";
import { FULFILLED, UPDATE_TENANT_KEYS } from "src/constants/constants";
import { RootState, useAppDispatch } from "src/store/store";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormField from "src/components/FormField";
import { updateTenantKeysHandler, updateUserHandler } from "src/store/slices/userSlice";
import PasswordField from "src/components/PasswordField";
import { logoutHandler } from "src/store/slices/authSlice";
import { Loader } from "src/components/Loader";
import { LanguageCode, languages } from "src/constants/languages";
import FormSelectField from "src/components/FormSelectField";
import { Spinner } from "react-bootstrap";

export type EditUser = {
    fName: string
    lName: string
    contact: string
    lang: LanguageCode
}

const Profile = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common');

    const tenantKeysImplementation = false

    const { user } = useSelector((state: RootState) => state.auth)
    const userId = user?.user?.uuid
    const userEmail = user?.user?.email
    const isOwner = user?.user?.roles[0]?.name === "OWNER"

    const [showManageKeys, setShowManageKeys] = useState(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const defaultValues: EditUser = {
        fName: user.user.fName,
        lName: user.user.lName,
        lang: user.user.lang,
        contact: user.user.contact,
    }

    const validationSchema = Yup.object().shape({
        fName: Yup.string().min(3, t("validation.firstNameMin")).max(50, t("validation.firstNameMax")).required(t("validation.required")),
        lName: Yup.string().nullable().min(3, t("validation.lastNameMin")).max(50, t("validation.lastNameMax")),
        contact: Yup.string().required("This field is required").matches(phoneRegExp, t("validation.invalidContact")),
    })

    const handleSubmit = async (formValue: EditUser) => {
        if (!userId) return
        setShowLoader(true)
        console.log(formValue, "formValue-----")

        const differences = getDifferences(defaultValues, formValue)
        if (differences === false) {
            setTimeout(() => {
                setShowLoader(false)
            }, 500)
            return
        }

        const updateData = { id: userId, data: differences }
        await dispatch(updateUserHandler(updateData))
        setShowLoader(false)
    }


    const hideManageKeys = () => {
        setShowManageKeys(false)
    }

    return (
        <div className='container-xl h-page'>
            <div className="row gy-5">
                <div className="col-md-6">
                    <div className="hstack gap-3 flex-wrap justify-content-between">
                        <div className='text-primary fs-2 mb-4'>{t('common.Profile')}</div>
                        {tenantKeysImplementation && isOwner && <button className="btn btn-primary" onClick={() => setShowManageKeys(!showManageKeys)}>Manage Keys</button>}
                    </div>
                    <Formik
                        initialValues={defaultValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        <Form className='vstack gap-4'>
                            <FormField type="text" name="fName" label="First Name" />
                            <FormField type="text" name="lName" label="Last Name" />
                            {userEmail &&
                                <div>
                                    <label className='custom-form-label'>Email</label>
                                    <div className='custom-form-control lh-lg disabled'>
                                        {userEmail}
                                    </div>
                                </div>
                            }
                            <FormSelectField options={languages} name="lang" label="Language" />
                            <FormField type="text" name="contact" label="Contact" />

                            <button className='btn btn-primary btn-lg mt-3 hstack justify-content-center gap-3' type='submit' disabled={showLoader}>
                                Save
                                {showLoader ? <Spinner /> : ''}
                            </button>
                        </Form>
                    </Formik>
                </div>
                {tenantKeysImplementation && isOwner && showManageKeys &&
                    <div className="col-md-6">
                        <TenantForm hideManageKeys={hideManageKeys} />
                    </div>
                }
            </div>
        </div>
    )
}

export default Profile


export type EditTenantKeys = {
    openAIApiKey: string,
    googleVoiceCode: string,
}

const TenantForm = ({ hideManageKeys }: { hideManageKeys: () => void }) => {
    const dispatch = useAppDispatch()

    const defaultValues: EditTenantKeys = {
        openAIApiKey: '',
        googleVoiceCode: '',
    }

    const [showLoader, setShowLoader] = useState(false)

    const validationSchema = Yup.object({
        openAIApiKey: Yup.string()
            .test(
                'at-least-one-field-required',
                'At least one field is required',
                function (value) {
                    const { googleVoiceCode } = this.parent; // Access other field in parent context
                    return value?.trim() || googleVoiceCode?.trim(); // At least one should be filled
                }
            ),

        googleVoiceCode: Yup.string()
            .test(
                'at-least-one-field-required',
                'At least one field is required',
                function (value) {
                    const { openAIApiKey } = this.parent; // Access other field in parent context
                    return value?.trim() || openAIApiKey?.trim(); // At least one should be filled
                }
            ),
    });

    const handleSubmit = (formValues: EditTenantKeys) => {
        setShowLoader(true)
        let data: any = {}

        if (formValues.openAIApiKey.trim() !== '') {
            data.openAIApiKey = formValues.openAIApiKey
        }
        if (formValues.googleVoiceCode.trim() !== '') {
            data.googleVoiceCode = formValues.googleVoiceCode
        }

        dispatch(updateTenantKeysHandler(data)).then((res: any) => {
            hideForm()
        })
    }

    const hideForm = () => {
        setShowLoader(false)
        hideManageKeys()
        setTimeout(() => {
            dispatch(logoutHandler())
        }, 3000)
    }

    return (
        <div className="position-relative h-100">
            {showLoader &&
                <Loader />
            }
            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <Form className='vstack gap-4' autoComplete="off">
                    <PasswordField autoComplete='new-password' name="openAIApiKey" label="OpenAi Api Key" />
                    <PasswordField autoComplete='new-password' name="googleVoiceCode" label="Google Voice Api Key" />
                    <button className='btn btn-primary btn-lg mt-3' type='submit'>Save</button>
                    You will be redirected to login screen after saving due to security purpose.
                </Form>
            </Formik>
        </div>
    )
}