import { useSelector } from "react-redux";
import { ChatMessage } from "src/store/slices/conversationSlice";
import { RootState } from "src/store/store";
import MessageWrapper from "./MessageWrapper";
import TypewriterComponent from "typewriter-effect";

type BotMessageProps = {
    message: ChatMessage;
};

const BotMessage = ({ message }: BotMessageProps) => {
    const { avatarData } = useSelector((state: RootState) => state.avatar)

    return (
        <MessageWrapper name={avatarData.name}>
            <TypewriterComponent
                onInit={(typewriter) => {
                    typewriter.typeString(message.content)
                        .callFunction((state) => {
                            state.elements.cursor.remove();
                        })
                        .start();
                }}
                options={{
                    delay: 50
                }}
            />
        </MessageWrapper>
    );
};
export default BotMessage