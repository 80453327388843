import { FC } from "react";
import UserMessage from "src/pages/AvatarCanvas/Chat/Message/UserMessage";
import { MessageContent } from "src/types/conversation.type";

interface Props {
    messages: MessageContent[];
    userData: {
        uuid: string;
        fName: string;
        lName: string;
    };
    avatarData: {
        uuid: string;
        name: string;
    }
}

export const Chat: FC<Props> = ({
    messages,
    avatarData,
    userData
}) => {
    return (
        <div className="canvas-wrapper h-100">
            <div className="canvas-parent">
                <div className="chat-wrapper w-100 position-relative">
                    <div className="chat-messages-wrapper">
                        {
                            messages?.length > 0 && messages?.map((msg, i) => {
                                return (
                                    <UserMessage
                                        key={`chat-msg-${i}`}
                                        message={{ content: msg?.message, role: msg?.role }}
                                        userName={msg?.role === 'user' ? `${userData?.fName ?? 'Anonymous'} ${userData?.lName ?? 'User'}` : avatarData?.name}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}