import { Form, Formik } from 'formik'
import { Modal, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { createUserModalHandler, updateUserIdHandler } from 'src/store/slices/modalSlice'
import { RootState, useAppDispatch } from 'src/store/store'
import FormField from '../FormField'
import * as Yup from "yup";
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { CREATE_USER, FULFILLED, GET_USER_BY_ID, UPDATE_USER } from 'src/constants/constants'
import { createUserHandler, getUserByIdHandler, updateUserHandler } from 'src/store/slices/userSlice'
import FormSelectField from '../FormSelectField'
import { commonStatus, Option } from 'src/types/types'
import { getDifferences, phoneRegExp } from 'src/common/commonUtils'
import { ENUM_COMMON_STATUS } from 'src/types/enum'

export type CreateUser = {
    fName: string
    lName: string
    email: string
    contact: string
    status: ENUM_COMMON_STATUS
    lang: string
    roleId?: string
}
interface CreateUserModalProps {
    getAllUsersList: () => void
    allRoles: Option[]
}
const CreateUserModal: FC<CreateUserModalProps> = ({ getAllUsersList, allRoles }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common');

    const { user } = useSelector((state: RootState) => state.auth)
    const { createUserModal, updateUserId } = useSelector((state: RootState) => state.modal)
    const userId = user?.user?.uuid


    const defaultValues: CreateUser = {
        fName: '',
        lName: '',
        email: '',
        status: ENUM_COMMON_STATUS.ACTIVE,
        contact: '',
        lang: 'en',
        roleId: allRoles?.length > 0 ? allRoles[0].value : '',
    }

    const [initialValues, setInitialValues] = useState<CreateUser>(defaultValues)
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        fName: Yup.string().min(3, t("validation.firstNameMin")).max(50, t("validation.firstNameMax")).required(t("validation.required")),
        lName: Yup.string().nullable().min(3, t("validation.lastNameMin")).max(50, t("validation.lastNameMax")),
        email: Yup.string().required(t("validation.required")).email(t("validation.invalidEmail"),)
            .test(
                'hyphen-not-allowed',
                t("validation.invalidEmail"),
                (val) => {
                    const result = val.startsWith('-');
                    return !result
                }
            ),
        contact: Yup.string().required("This field is required").matches(phoneRegExp, t("validation.invalidContact")),
        lang: Yup.string().required(t("validation.required")),
    })

    const handleSubmit = (formValue: CreateUser) => {
        setShowLoader(true);
        if (updateUserId) {
            const differences = getDifferences(initialValues, formValue)
            if (differences === false) {
                setTimeout(() => {
                    hideModal()
                }, 1000)
                setShowLoader(false);
            } else {
                const updateData = { id: updateUserId, data: differences }
                dispatch(updateUserHandler(updateData)).then((res: any) => {
                    if (res.type === (UPDATE_USER + FULFILLED)) {
                        handleSuccess()
                    }
                    setShowLoader(false);
                })
            }
        } else {
            const createData = { ...formValue, userId: userId }
            dispatch(createUserHandler(createData)).then((res: any) => {
                if (res.type === (CREATE_USER + FULFILLED)) {
                    handleSuccess()
                }
                setShowLoader(false);
            })
        }
    }

    const handleSuccess = () => {
        hideModal()
        getAllUsersList()
    }

    const hideModal = () => {
        dispatch(createUserModalHandler(false))
        setTimeout(() => {
            dispatch(updateUserIdHandler(null));
            setInitialValues(defaultValues);
        }, 200);
    }

    const getUserDetails = () => {
        if (updateUserId) {
            dispatch(getUserByIdHandler(updateUserId)).then((res: any) => {
                if (res.type === (GET_USER_BY_ID + FULFILLED)) {
                    const payload = res?.payload?.data
                    const data: CreateUser = {
                        fName: payload.fName,
                        lName: payload.lName,
                        email: payload.email,
                        status: payload.status,
                        contact: payload.contact,
                        lang: payload.lang,
                        roleId: payload?.roles?.[0]?.uuid,
                    };
                    setInitialValues(data)
                }
            })
        }
    }

    // Get the User Details for update form action.
    useEffect(() => {
        if (createUserModal && updateUserId) {
            getUserDetails()
        }
    }, [createUserModal, updateUserId])

    // Set the roleId in the initial values after gettting the allRoles.
    useEffect(() => {
        if (!updateUserId && !initialValues?.roleId && allRoles?.length > 0) {
            setInitialValues({ ...initialValues, roleId: allRoles?.length > 0 ? allRoles[0].value : '' });
        }
    }, [initialValues, allRoles, updateUserId])

    return (
        <Modal centered backdrop="static" scrollable show={createUserModal} onHide={hideModal} size='lg'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <Form className='modal-content'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {updateUserId ? 'Update User details' : 'Enter User details'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="vstack gap-3">
                            <FormField type="text" name="fName" label="First Name" />
                            <FormField type="text" name="lName" label="Last Name" />
                            <FormField type="email" name="email" label="Email" disabled={updateUserId} />
                            <FormSelectField name='status' label='Status' options={commonStatus} />
                            <FormField type="text" name="contact" label="Contact" />
                            <FormField type="text" name="lang" label="Language" />
                            <FormSelectField name='roleId' label='Role' options={allRoles} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <button className='btn btn-primary btn-lg hstack justify-content-center gap-3' type='submit' disabled={showLoader}>
                            {updateUserId ? 'Save' : 'Create User'}
                            {showLoader && <Spinner />}
                        </button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
    )
}

export default CreateUserModal