import { DOTS, usePaginationData } from "src/hooks/usePagination";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";


interface IPaginationProps {
    onPageChange: (page: number) => void;
    totalCount: number;
    siblingCount: number;
    currentPage: number;
    pageSize: number;
}

export const PaginationComponent: FC<IPaginationProps> = (props) => {

    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const parsedPageSize = parseInt(pageSize as unknown as string, 10) || 1;

    const paginationRange = usePaginationData({
        currentPage,
        totalCount,
        siblingCount,
        pageSize: parsedPageSize
    });
    const { t } = useTranslation('common');

    // console.log("paginationRange: ", paginationRange);

    // if (currentPage === 0 || paginationRange.length < 2) {
    //     return null;
    // }

    const onNext = () => {
        if ((parsedPageSize * currentPage) >= totalCount) {
            return;
        }
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        if (Number(currentPage) === 1) {
            return;
        }
        onPageChange(currentPage - 1);
    };

    // let lastPage = paginationRange[paginationRange.length - 1];
    const recordCount = (): React.JSX.Element => {
        if (totalCount > 10) {
            const startRecord = (currentPage - 1) * parsedPageSize + 1;
            const endRecord = Math.min(currentPage * parsedPageSize, totalCount);

            return (
                <div>
                    {t("pagination.lblStartItem")} {startRecord} {t("pagination.lblBetween")} {endRecord} {t("pagination.lblEndItem")} {totalCount}{" "}
                    {totalCount === 1 ? t("pagination.lblTotalRecord") : t("pagination.lblTotalRecords")}
                </div>
            );
        } else if (totalCount > 0 && totalCount <= 10) {
            return (
                <div>
                    {totalCount}{" "}
                    {totalCount === 1
                        ? t("pagination.record")
                        : t("pagination.records")}{" "}
                    {t("pagination.found")}
                </div>
            );
        } else {
            return <div>{t("pagination.noRecordFound")}</div>;
        }
    };


    return (
        <div className="hstack gap-1 flex-wrap justify-content-end">
            {paginationRange?.length > 0 && recordCount()}
            <ul
                className="pagination"
            >
                {
                    paginationRange?.length > 0 && (
                        <li className="page-item">
                            <button className="page-link" onClick={onPrevious}>&laquo;</button>

                        </li>
                    )
                }
                {paginationRange.map((pageNumber, i) => {
                    if (pageNumber === DOTS) {
                        return (
                            <li className="page-item" key={`pagination-el-${pageNumber}-${i}`}>
                                <button
                                    className="page-link"
                                >...</button>
                            </li>
                        )
                    }

                    return (
                        <li key={`pagination-el-${pageNumber}-${i}`} className={`page-item ${Number(pageNumber) === Number(currentPage) ? 'active' : ''} `}>
                            <button
                                className="page-link"
                                onClick={() => onPageChange(Number(pageNumber))}
                            >{pageNumber}</button>
                        </li>

                    );
                })}
                {
                    paginationRange?.length > 0 && (
                        <li className="page-item">
                            <button className="page-link" onClick={onNext}>&raquo;</button>

                        </li>
                    )
                }
            </ul>
        </div>
    );
};