import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { TableColumn } from "src/types/types";

const roleTableColumn: TableColumn[] = [
    {
        label: "Roles",
        accessor: "name",
        sortable: true
    },
    {
        label: "Actions",
        accessor: "actions",
        sortable: false
    }
]

export const useRoleTableColumns = () => {

    // Redux State
    const { user } = useSelector((state: RootState) => state?.auth);

    // Hook State
    const [columns, setColumns] = useState<TableColumn[]>(roleTableColumn);

    //Manipulate the columns
    useEffect(() => {
        const permissions: string[] = user?.permissions;
        // Remove the actions column.
        if (
            permissions
            && !permissions?.includes('role-update')
            && !permissions?.includes('role-delete')
        ) {
            const updatedCols = [...columns].filter(el => el?.accessor !== 'actions');
            setColumns(updatedCols);
        }
        //eslint-disable-next-line
    }, [user]);

    return {
        columns
    }
}