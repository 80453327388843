import { faArrowUp, faArrowDown, faShare, faChevronDown, faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Actions from "src/components/Actions"
import CreateAvatarModal from "src/components/Modals/CreateAvatarModal"
import CreateTenantModal from "src/components/Modals/CreateTenantModal"
import { PaginationComponent } from "src/components/PaginationCom"
import { AVATAR_LABEL_VALUE, DELETE_AVATAR, DELETE_TENANT, FULFILLED, GET_ALL_AVATARS, GET_ALL_TENANTS, GET_CONVERSATION_GROUP_BY_THREAD } from "src/constants/constants"
import { useDebounce } from "src/hooks/useDebounce"
import { deleteAvatarHandler, getAllAvatarsHandler, getAvatarLabelValue, } from "src/store/slices/avatarSlice"
import { createAvatarModalHandler, createTenantModalHandler, updateTenantIdHandler, updateAvatarIdHandler } from "src/store/slices/modalSlice"
import { getAllTenantsHandler, deleteTenantHandler } from "src/store/slices/tenantSlice"
import { Avatar, Option, PaginationData, SortOrder, TableColumn, TabType } from "src/types/types"
import { useAppDispatch } from "src/store/store"
import { useSelector } from "react-redux"
import { getSettingData } from "src/store/slices/settingsSlice"
import { setActiveTab, } from "src/store/slices/settingsSlice"
import { getAllConversationsGroupByThread } from "src/store/slices/conversationSlice"
import { ConversationPaginateData, IConversationsGroupByThreadRes } from "src/types/conversation.type"
import { CommonModal } from "src/components/Modals/Modal"
import { ConversationThreadMessages } from "src/components/Conversations/ConversationThreadMessages"
import { ShareAvatar } from "src/components/Avatar/ShareAvatar"
import { useAvatarTableColumns } from "./hooks/useAvatarTable.Column"
import { useTranslation } from "react-i18next"

type Tenant = {
  uuid: string
  name: string
  email: string
  lang: string,
  status: string,
}


const pageLimit = 10;
const tenantTableColumns: TableColumn[] = [
  {
    label: "name",
    accessor: "name",
    sortable: true
  },
  {
    label: "email",
    accessor: "email",
    sortable: true
  },
  {
    label: "lang",
    accessor: "lang",
    sortable: true
  },
  {
    label: "status",
    accessor: "status",
    sortable: true
  },
  {
    label: "actions",
    accessor: "actions",
    sortable: false
  },
]
const conversationTableColumns: TableColumn[] = [
  {
    label: 'Avatar Name',
    accessor: 'avatar.name',
    sortable: false
  },
  {
    label: 'User Name',
    accessor: 'user.fName',
    sortable: false
  },
  {
    label: "actions",
    accessor: "actions",
    sortable: false
  },
];
const AdminDashboard = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common');
  const { activeTab } = useSelector(getSettingData);

  // Component State
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [avatars, setAvatars] = useState<Avatar[]>([])
  const [conversations, setConversations] = useState<IConversationsGroupByThreadRes[]>([])
  const [actionLoader, setActionLoader] = useState<string | null>(null)
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [sorting, setSorting] = useState<{ field: string; order: SortOrder | string }>({
    field: "",
    order: "ASC"
  });
  const [conversationUUID, setConversationUUID] = useState<string | null>(null);
  const [shareAvatar, setShareAvatar] = useState<{ avatarCode: string; tenantCode: string } | null>(null);
  const [avatarLabelValue, setAvatarLabelValue] = useState<Option[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<string>("");

  // Custom Hooks
  const deboucedValue = useDebounce(search, 700);
  const { columns: avatarTableColumns } = useAvatarTableColumns();

  const getAllTenantsList = (overridePaginationData?: PaginationData) => {
    const pagination: PaginationData = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...overridePaginationData
    }
    dispatch(getAllTenantsHandler(pagination)).then((res: any) => {
      if (res.type === (GET_ALL_TENANTS + FULFILLED)) {
        setTenants(res.payload.data)
        setCurrentPage(res?.payload?.currentPage)
        setTotalCount(res?.payload?.totalItems)
      }
    })
  }

  const getAllAvatarsList = (overridePaginationData?: PaginationData) => {
    const pagination: PaginationData = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...overridePaginationData
    }
    dispatch(getAllAvatarsHandler(pagination)).then((res: any) => {
      if (res.type === (GET_ALL_AVATARS + FULFILLED)) {
        setAvatars(res.payload.data)
        setCurrentPage(res?.payload?.currentPage)
        setTotalCount(res?.payload?.totalItems)
      }
    })
  }

  const getAllConversationList = async (overridePaginationData?: ConversationPaginateData) => {
    const pagination: ConversationPaginateData = {
      limit: pageLimit,
      pageNumber: currentPage,
      searchVal: deboucedValue,
      ...((sorting?.field && sorting?.order) && {
        sortColumn: sorting?.field,
        sortType: sorting?.order
      }),
      ...(selectedAvatar && { avatarUuid: selectedAvatar }),
      ...overridePaginationData
    }

    const res = await dispatch(getAllConversationsGroupByThread(pagination));

    if (res && res?.type === GET_CONVERSATION_GROUP_BY_THREAD + FULFILLED) {
      if (res?.payload?.success) {
        setConversations(res.payload?.data.data);
        setCurrentPage(res?.payload?.data?.currentPage)
        setTotalCount(res?.payload?.data?.totalItems)
      }
    }
  }

  const fetchAvatarLabelValue = async () => {
    const res = await dispatch(getAvatarLabelValue(null));

    if (res?.type === AVATAR_LABEL_VALUE + FULFILLED) {
      const payload = res?.payload;
      if (payload?.success) {
        setAvatarLabelValue(payload?.data);
      }
    }
  }

  // Fetch Data of Avatar/Tenant based on the active tab.
  useEffect(() => {
    if (activeTab === 'avatar') {
      getAllAvatarsList()
    }
    else if (activeTab === 'tenant') {

      getAllTenantsList()
    } else if (activeTab === 'conversation') {
      getAllConversationList();
    }
    // eslint-disable-next-line
  }, [deboucedValue, activeTab, selectedAvatar]);

  // Fetch the avatar's label-value for conversation tab.
  useEffect(() => {
    if (activeTab === 'conversation') {
      fetchAvatarLabelValue();
    }
    //eslint-disable-next-line
  }, [activeTab])


  const editTenant = (tenantId: string) => {
    dispatch(updateTenantIdHandler(tenantId))
    dispatch(createTenantModalHandler(true))
  }

  const deleteTenant = (tenantId: string) => {
    setActionLoader(tenantId)
    dispatch(deleteTenantHandler(tenantId)).then((res: any) => {
      if (res.type === (DELETE_TENANT + FULFILLED)) {
        let page = currentPage;
        if (tenants?.length === 1 && currentPage > 1) {
          page = page - 1;
          setCurrentPage(page);
        }
        getAllTenantsList({ pageNumber: page });
      }
      setActionLoader(null)
    })
  }


  const editAvatar = (avatarId: string) => {
    dispatch(updateAvatarIdHandler(avatarId))
    dispatch(createAvatarModalHandler(true))
  }

  const deleteAvatar = (avatarId: string) => {
    setActionLoader(avatarId)
    dispatch(deleteAvatarHandler(avatarId)).then((res: any) => {
      if (res.type === (DELETE_AVATAR + FULFILLED)) {
        let page = currentPage;
        if (avatars?.length === 1 && currentPage > 1) {
          page = page - 1;
          setCurrentPage(page);
        }
        getAllAvatarsList({ pageNumber: page });
      }
      setActionLoader(null)
    })
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  }

  const handleCreateEntity = (entity: TabType) => {
    if (entity === 'avatar') {
      dispatch(createAvatarModalHandler(true))
    }
    else {
      dispatch(createTenantModalHandler(true))
    }
  }

  const toggleActiveTab = (tab: TabType) => {
    dispatch(setActiveTab(tab));
    resetPaginationData();
  }

  const handlePageChange = (page: number) => {
    if (Number(page) === Number(currentPage)) {
      return;
    }
    setCurrentPage(page);
    if (activeTab === 'avatar') {
      getAllAvatarsList({ pageNumber: page });
    }
    else if (activeTab === 'tenant') {
      getAllTenantsList({ pageNumber: page });
    }
    else if (activeTab === 'conversation') {
      getAllConversationList({ pageNumber: page });
    }
  }

  const handleSorting = (accessor: string) => {
    let sortOrder: SortOrder | string = sorting?.order;
    let sortField = accessor;
    if (accessor === sorting?.field && sorting?.order === 'ASC') {
      sortOrder = 'DESC'
    }
    else if (accessor !== sorting?.field) {
      sortOrder = 'ASC';
    }
    else if (
      accessor === sorting?.field
      && sorting?.order === 'DESC'
    ) {
      sortOrder = "";
      sortField = "";
    }
    else {
      sortOrder = 'ASC';
    }

    setSorting({ ...sorting, order: sortOrder, field: sortField });
    if (activeTab === 'avatar') {
      getAllAvatarsList({ sortColumn: sortField, sortType: sortOrder });
    }
    else if (activeTab === 'tenant') {

      getAllTenantsList({ sortColumn: sortField, sortType: sortOrder });
    } else {
      getAllConversationList({ sortColumn: sortField, sortType: sortOrder });
    }
  }

  const resetPaginationData = () => {
    setCurrentPage(1);
    setSearch("");
    setSorting({ order: 'ASC', field: "" });
  }

  const activeColumnSortFlag = (accessor: string) => {
    if (accessor === sorting?.field && sorting?.order === 'ASC') {
      return <FontAwesomeIcon className="ms-1" icon={faArrowUp} />
    }
    else if (accessor === sorting?.field && sorting?.order === 'DESC') {
      return <FontAwesomeIcon className="ms-1" icon={faArrowDown} />
    }
    else {
      return <></>
    }
  }

  const handleConversationThreadId = (uuid: string) => {
    if (uuid) setConversationUUID(uuid);
  }

  const handleShareAvatar = (tenantCode: string, avatarCode: string) => {
    if (tenantCode && avatarCode) setShareAvatar({ ...shareAvatar, tenantCode: tenantCode, avatarCode: avatarCode });
  }

  const handleAvatarDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e?.target?.value;
    setSelectedAvatar(value);
  }


  return (
    <>
      <div className='container-xl h-page'>
        <div className='text-primary fs-2 mb-4'>{t('common.Dashboard')}</div>
        {/* Tab List Start */}
        <ul className="nav nav-underline border-bottom mb-3" id="admin-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'tenant' ? 'active' : ''}`} type="button" onClick={() => toggleActiveTab('tenant')} >Tenants</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'avatar' ? 'active' : ''}`} type="button" onClick={() => toggleActiveTab('avatar')} >Avatars</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'conversation' ? 'active' : ''}`} type="button" onClick={() => toggleActiveTab('conversation')} >Conversations</button>
          </li>
        </ul>
        {/* Tab List End */}

        <div className="tab-content" id="admin-tabContent">

          <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="tenants-tab" tabIndex={0}>
            <div className="vstack gap-4">
              <div className="hstack gap-3 justify-content-between">
                <div>
                  {/* Search Input */}
                  <div className="d-flex gap-2">
                    <input
                      type="search"
                      className="custom-form-control"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                    {
                      activeTab === 'conversation' && (
                        <div className="position-relative w-100">
                          <select
                            className="form-control custom-form-control text-truncate"
                            value={selectedAvatar}
                            onChange={handleAvatarDropdownChange}
                          >
                            <option value={""}>All Avatar</option>
                            {
                              avatarLabelValue?.length > 0 && avatarLabelValue?.map((avtr) => {
                                return (
                                  <option key={avtr?.value} value={avtr?.value}>{avtr?.label}</option>
                                )
                              })
                            }

                          </select>
                          <FontAwesomeIcon icon={faChevronDown} className="position-absolute translate-middle-y top-50 end-0 me-3 text-black-50" />
                        </div>
                      )
                    }
                  </div>
                </div>
                {
                  (
                    activeTab === 'avatar'
                    || activeTab === 'tenant'
                  ) && (
                    <button type="button" className="btn btn-primary" onClick={() => handleCreateEntity(activeTab)}>Create {activeTab === 'tenant' ? 'Tenant' : 'Avatar'}</button>
                  )
                }
              </div>
              {
                activeTab === 'tenant' && (
                  <div className="table-responsive rounded">
                    <table className="table table-light">
                      <thead>
                        <tr>
                          {tenantTableColumns?.map((el, i) => <th
                            key={`th-${el.accessor}-${i}`}
                            onClick={() => el?.sortable && handleSorting(el.accessor)}
                          >
                            {el?.label}
                            {activeColumnSortFlag(el?.accessor)}
                          </th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tenants && tenants?.length === 0 && (
                            <tr>
                              <td colSpan={5}>No Tenants Found</td>
                            </tr>
                          )
                        }
                        {
                          tenants && tenants.length > 0 && tenants?.map((tenant: Tenant) => {
                            return (
                              <tr key={tenant.uuid}>
                                <td>{tenant.name}</td>
                                <td>{tenant.email}</td>
                                <td>{tenant.lang}</td>
                                <td>{tenant.status}</td>
                                <td>
                                  <Actions id={tenant.uuid} editFn={editTenant} deleteFn={deleteTenant} actionLoader={actionLoader} />
                                </td>
                              </tr>
                            )
                          })
                        }
                        <tr></tr>
                      </tbody>
                    </table>
                    <PaginationComponent
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      pageSize={pageLimit}
                      siblingCount={1}
                      totalCount={totalCount}
                    />
                  </div>
                )
              }
              {
                activeTab === 'avatar' && (
                  <div className="table-responsive rounded">
                    <table className="table table-light">
                      <thead>
                        <tr>
                          {avatarTableColumns?.map((el, i) => <th
                            key={`th-${el.accessor}-${i}`}
                            onClick={() => el?.sortable && handleSorting(el.accessor)}
                          >
                            {el?.label}
                            {activeColumnSortFlag(el?.accessor)}
                          </th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          avatars && avatars?.length === 0 && (
                            <tr>
                              <td colSpan={4}>No Avatars Found</td>
                            </tr>
                          )
                        }
                        {
                          avatars && avatars.length > 0 && avatars?.map((avatar: Avatar) => {
                            return (
                              <tr key={avatar.uuid}>
                                <td>{avatar.name}</td>
                                <td>{avatar.role}</td>
                                <td>{avatar.createdByAdmin ? 'You' : 'Tenant user'}</td>
                                <td>
                                  <div className="hstack justify-content-end gap-3">
                                    <Actions id={avatar.uuid} editFn={editAvatar} deleteFn={deleteAvatar} actionLoader={actionLoader} />
                                    <div className='action-btn'>
                                      <Link to={`/bot/${avatar.tenantCode}/${avatar.avatarCode}`} className="btn bg-secondary-subtle action-btn" title="Preview">
                                        <FontAwesomeIcon icon={faEye} size="lg" className='text-secondary' />
                                      </Link>
                                    </div>
                                    <div className='action-btn'>
                                      <button className="btn bg-secondary-subtle action-btn" title="Share" onClick={() => handleShareAvatar(avatar.tenantCode, avatar.avatarCode)}>
                                        <FontAwesomeIcon icon={faShare} size="lg" className='text-secondary' />
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                      <PaginationComponent
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        pageSize={pageLimit}
                        siblingCount={1}
                        totalCount={totalCount}
                      />
                    </div>
                  </div>
                )
              }
              {
                activeTab === 'conversation' && (
                  <div className="table-responsive rounded">
                    <table className="table table-light">
                      <thead>
                        <tr>
                          {conversationTableColumns?.map((el, i) => <th
                            key={`th-${el.accessor}-${i}`}
                            onClick={() => el?.sortable && handleSorting(el.accessor)}
                          >
                            {el?.label}
                            {activeColumnSortFlag(el?.accessor)}
                          </th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          conversations && conversations?.length === 0 && (
                            <tr>
                              <td colSpan={4}>No Conversations Found</td>
                            </tr>
                          )
                        }
                        {
                          conversations && conversations.length > 0 && conversations?.map((conversation: IConversationsGroupByThreadRes) => {
                            return (
                              <tr key={conversation.uuid}>
                                <td>{conversation?.avatar?.name}</td>
                                <td>{conversation?.user?.fName ?? 'Anonymous'} {conversation?.user?.lName ?? 'User'}</td>
                                <td>
                                  <div className="hstack justify-content-end gap-3">
                                    {/* <Actions id={avatar.uuid} editFn={editAvatar} deleteFn={deleteAvatar} actionLoader={actionLoader} /> */}
                                    <div className='action-btn'>
                                      <button onClick={() => handleConversationThreadId(conversation?.uuid)} className="btn bg-secondary-subtle action-btn" title="Preview">
                                        <FontAwesomeIcon icon={faEye} size="lg" className='text-secondary' />
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                      <PaginationComponent
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        pageSize={pageLimit}
                        siblingCount={1}
                        totalCount={totalCount}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <CreateTenantModal getAllTenantsList={getAllTenantsList} />
      <CreateAvatarModal getAllAvatarsList={getAllAvatarsList} />

      {/* Conversation Modal */}
      <CommonModal
        title="Conversation History"
        isOpen={!!conversationUUID}
        onClose={() => setConversationUUID(null)}
        onConfirm={() => null}
        showFooter={false}
      >
        {conversationUUID && <ConversationThreadMessages conversationId={conversationUUID} />}
      </CommonModal>

      {/* Share Avatar Modal */}
      <CommonModal
        isOpen={!!(shareAvatar && Object?.keys(shareAvatar)?.length > 0)}
        title="Share Avatar"
        onConfirm={() => null}
        onClose={() => setShareAvatar(null)}
        showFooter={false}
        scrollable={false}
      >
        {
          shareAvatar && (
            <ShareAvatar
              tenantCode={shareAvatar?.tenantCode}
              avatarCode={shareAvatar?.avatarCode}
            />
          )
        }
      </CommonModal>
    </>
  )
}

export default AdminDashboard