import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react'
import { useSelector } from 'react-redux';
import { getSubscriptionDetails } from 'src/store/slices/subscriptionSlice';

interface IPlanInactive {
    onClick: () => void;
}

const PlanInactive: FC<IPlanInactive> = ({
    onClick
}) => {
    // const { subscriptionAmount } = useSelector((state: RootState) => state.auth?.user?.user?.tenantUser);
    const { amount: subscriptionAmount } = useSelector(getSubscriptionDetails);

    return (
        <div className="card custom-card">
            <div className="card-body vstack gap-4 align-items-center">
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    size='4x'
                    className="text-primary"
                />
                <h5 className="card-title fs-3 text-primary">Get Started Today</h5>
                <p className="fs-5">
                    Unlock all our features and services with our <span className='fs-4 fw-semibold'>$<span>{subscriptionAmount?.split('.')?.[0]}</span>.<span className='fs-6 text-muted'>{subscriptionAmount?.split('.')?.[1]}</span></span>/month plan.
                </p>
                <button className="btn btn-primary btn-lg" onClick={onClick}>
                    Activate Now
                </button>
            </div>
        </div>
    )
}

export default PlanInactive
