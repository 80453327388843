import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { USER_DASHBOARD, PROFILE, SIGNIN, ADMIN_DASHBOARD, SUBSCRIPTION } from 'src/constants/constants'
import { logoutHandler } from 'src/store/slices/authSlice'
import { RootState } from 'src/store/store'

const Header = () => {
    const dispath = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation('common');

    const { user, admin, isUser, isAdmin } = useSelector((state: RootState) => state.auth);
    const isOwner = user?.user?.roles[0]?.name === "OWNER"
    let token = user?.token || admin?.token;
    const forceChangePassword = user?.user?.forceChngPwd;

    const [showHeader, setShowHeader] = useState<boolean>(false)

    const handleLogout = () => {
        dispath(logoutHandler())
        navigate(SIGNIN);
    }

    useEffect(() => {
        const show = token && (isAdmin || isUser) && !forceChangePassword && !window.location.pathname.includes("bot")
        setShowHeader(show)
    }, [token, isAdmin, isUser, forceChangePassword, window.location.pathname])

    if (!showHeader) {
        return <></>
    }
    return (
        <div className='container-xl py-3 z-3 position-relative'>
            <div className='hstack gap-3 flex-wrap'>
                {isAdmin && <Link to={ADMIN_DASHBOARD}>{t('common.Dashboard')}</Link>}
                {isUser &&
                    <>
                        <Link to={PROFILE}>{t('common.Profile')}</Link>
                        <Link to={USER_DASHBOARD}>{t('common.Dashboard')}</Link>
                        {isOwner && <Link to={SUBSCRIPTION}>{t('common.Subscription')}</Link>}
                    </>
                }

                <div className='flex-fill'></div>
                <button className='btn btn-secondary' onClick={handleLogout}>Logout</button>
            </div>
        </div>
    )
}

export default Header