import { Field } from "formik";
import ErrorMessageFormik from "src/components/ErrorMessageFormik";

interface FormFieldProps {
    type: string
    name: string
    label: string
    className?: string
    rows?: number
    disabled?: any
}

const FormField: React.FC<FormFieldProps> = ({ type, name, label, className, rows, disabled }) => {

    return (
        <div className={className}>
            <label htmlFor={name} className='custom-form-label'>{label}</label>
            {type === 'textarea' ?
                <Field as={'textarea'} rows={rows} id={name} name={name} disabled={disabled} className="custom-form-control" />
                :
                <Field type={type} id={name} name={name} disabled={disabled} className="custom-form-control" />
            }
            <ErrorMessageFormik name={name} />
        </div>
    );
};

export default FormField