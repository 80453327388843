import { faClipboard, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

interface Props {
    tenantCode: string;
    avatarCode: string;
}

const frameSize = [
    {
        label: 'Large',
        value: 'lg',
        height: 600,
        width: 800
    },
    {
        label: 'Medium',
        value: 'md',
        height: 450,
        width: 600
    },
    {
        label: 'Small',
        value: 'sm',
        height: 300,
        width: 400
    }
]

export const ShareAvatar: FC<Props> = ({
    tenantCode,
    avatarCode
}) => {

    const [activeTab, setActiveTab] = useState<'share_link' | 'embed_link'>('embed_link');
    const [selectedFrameSize, setSelectedFrameSize] = useState<{ label: string; value: string; height: number; width: number; }>(frameSize[0]);
    const iFrameRef = useRef<HTMLIFrameElement>(null);
    const iframeLink = `${window.location.origin}/bot/${encodeURIComponent(tenantCode)}/${encodeURIComponent(avatarCode)}`

    const handleCopyHtml = (type: 'iframe' | 'text') => {
        if (type === 'iframe') {
            if (iFrameRef?.current) {
                const iframeHTML = iFrameRef?.current?.outerHTML;

                if (iframeHTML) {
                    navigator.clipboard.writeText(iframeHTML).then(
                        () => {
                            toast.success('Copied to clipboard', { position: 'bottom-center' });
                        },
                        (err) => {
                            toast.error(err?.message, { position: 'bottom-center' });
                        }
                    );
                }

            }
        }
        else {
            if (iframeLink) {
                navigator.clipboard.writeText(iframeLink).then(
                    () => {
                        toast.success('Copied to clipboard', { position: 'bottom-center' });
                    },
                    (err) => {
                        toast.error(err?.message, { position: 'bottom-center' });
                    }
                );
            }
        }
    }

    return (
        <div>
            <ul className="nav nav-underline border-bottom mb-3">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${activeTab === 'embed_link' ? 'active' : ''}`} onClick={() => setActiveTab('embed_link')} >Embed an Avatar</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${activeTab === 'share_link' ? 'active' : ''}`} onClick={() => setActiveTab('share_link')}  >Send a link</button>
                </li>
            </ul>

            {
                activeTab === 'embed_link' && (
                    <>
                        <div className="d-flex gap-1 align-items-center justify-content-between">
                            <Dropdown>
                                <Dropdown.Toggle variant="primary">
                                    {selectedFrameSize?.label}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        frameSize?.map((fSize, i) => {
                                            return (
                                                <Dropdown.Item
                                                    key={`frame-size-${i}`}
                                                    onClick={() => setSelectedFrameSize(fSize)}
                                                    className={`${selectedFrameSize?.value === fSize?.value ? 'active' : ''}`}
                                                >{fSize.label}</Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <input
                                type="text"
                                className="custom-form-control w-50"
                                readOnly
                                value={
                                    `<iframe title="share-avatar-${tenantCode}-${avatarCode}" width="${selectedFrameSize?.width}" height="${selectedFrameSize.height}" src="${iframeLink}"></iframe>`
                                } />
                            <button
                                className='btn btn-primary'
                                type='button'
                                onClick={() => handleCopyHtml('iframe')}
                            >
                                COPY HTML
                            </button>
                        </div>
                        <div className="d-flex mt-4 justify-content-center">
                            <iframe
                                className="border border-2"
                                ref={iFrameRef}
                                title={`share-avatar-${tenantCode}-${avatarCode}`}
                                width={selectedFrameSize?.width}
                                height={selectedFrameSize?.height}
                                src={iframeLink} />
                        </div>
                    </>
                )
            }
            {
                activeTab === 'share_link' && (
                    <div className="d-flex flex-column justify-content-start p-2 gap-2">
                        <h6>
                            Sharable Link
                        </h6>
                        <div className="hstack gap-2">
                            <div
                                className="custom-form-control hstack disabled pe-4"
                            >
                                {iframeLink}
                            </div>
                            <button
                                className='btn btn-primary hstack gap-2 rounded-4'
                                type='button'
                                onClick={() => handleCopyHtml('text')}
                            >
                                <FontAwesomeIcon size="lg" icon={faCopy} />
                                <span>Copy</span>
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}