import {
  ArcRotateCamera,
  BackgroundMaterial,
  Color3,
  CubeTexture,
  Engine,
  EnvironmentHelper,
  HemisphericLight,
  Mesh,
  MeshBuilder,
  Nullable,
  Scene,
  Texture,
} from "babylonjs";
import { createCamera, v3 } from "./babylonUtils";
import { CameraConfig } from "src/types/types";

const loadBackground = (scene: Scene): Nullable<EnvironmentHelper> => {
  var options = {
    createSkybox: false,
    groundColor: Color3.Black(),
    groundSize: 2,
  };

  const environmentHelper = scene.createDefaultEnvironment(options);

  return environmentHelper;
};

const enableCollisions = (scene: Scene, camera: ArcRotateCamera) => {
  const invisibleGround = MeshBuilder.CreateGround(
    "Invisibleground",
    { width: 50, height: 50 },
    scene
  );

  invisibleGround.isVisible = false;
  invisibleGround.checkCollisions = true;

  camera.checkCollisions = true;

  scene.collisionsEnabled = true;
};

let isLoading = true;

export const initBabylon = (
  setIsLoading: (isLoading: boolean) => void,
  cameraConfig: CameraConfig,
  canvasRef: HTMLCanvasElement,
  skyboxInput: string | null
): Scene => {
  setIsLoading(true);
  // console.log("Initializing scene...");

  // Get the canvas DOM element
  // const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;
  const canvas = canvasRef;

  // Simulate WebGL not supported error:
  //canvas.getContext = () => {};

  if (canvas === null) {
    console.error("Could not get canvas with canvasRef :(");
  }

  // Load the 3D engine
  let engine: Engine;

  engine = new Engine(canvas, true, {
    preserveDrawingBuffer: true,
    stencil: true,
  });

  // function customLoadingScreen() {
  //   console.log("customLoadingScreen creation");
  // }
  // customLoadingScreen.prototype.displayLoadingUI = function () {};
  // customLoadingScreen.prototype.hideLoadingUI = function () {};
  // var loadingScreen = new customLoadingScreen();
  // engine.loadingScreen = loadingScreen;

  // XXX: NOTE: This is really important to tell Babylon.js to use decomposeLerp and matrix interpolation
  BABYLON.Animation.AllowMatricesInterpolation = true;

  // const cameraConfig = {
  //   alpha: config.cameraConfig.alpha,
  //   beta: config.cameraConfig.beta,
  //   radius: config.cameraConfig.radius,
  //   target: v3(config.cameraConfig.positionX, config.cameraConfig.positionY, config.cameraConfig.positionZ)
  // }
  var scene = createScene(engine, cameraConfig, canvas, skyboxInput);

  // Handle animation group blending and loop animations by default.
  scene.animationPropertiesOverride = new BABYLON.AnimationPropertiesOverride();
  scene.animationPropertiesOverride.enableBlending = true;
  scene.animationPropertiesOverride.blendingSpeed = 0.05;
  scene.animationPropertiesOverride.loopMode = 1;
  scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

  engine.runRenderLoop(function () {
    // NOTE: The following executeWhenReady makes sure we only show stuff when everything is:
    // - Done loading
    // - Done rendering (including shaders and stuff)

    scene.executeWhenReady(() => {
      if (isLoading) {
        setIsLoading(false);
        isLoading = false;
      }
      scene.render();
    }, true);
  });

  // the canvas/window resize event handler
  window.addEventListener("resize", function () {
    engine.resize();
  });

  return scene;
};

// CreateScene function that creates and return the scene
const createScene = function (
  engine: Engine,
  cameraConfig: CameraConfig,
  canvas: HTMLCanvasElement,
  skyboxInput: string | null
) {
  // Create a basic BJS Scene object
  const scene = new Scene(engine);
  // Create a FreeCamera, and set its position to {x: 0, y: 5, z: -10}
  // const camera = new ArcRotateCamera("Steve", Math.PI / 2, 0, 4, v3(), scene);

  // const camera = createCameraWithAxesInCorner(scene);
  const camera = createCamera(scene, cameraConfig);

  // Attach the camera to the canvas
  camera.attachControl(canvas, true);

  //to disable zooming
  // camera.lowerRadiusLimit = camera.upperRadiusLimit = camera.radius

  // Create a basic light, aiming 0, 1, 0 - meaning, to the sky
  new HemisphericLight("light1", v3(0, 1, 0), scene);

  // const box = MeshBuilder.CreateBox("box", { size: 1 }, scene);

  // const environmentHelper = loadBackground(scene);

  if (skyboxInput) {
    // Create a skydome
    const skydome = MeshBuilder.CreateBox(
      "sky",
      { size: 1000, sideOrientation: Mesh.BACKSIDE },
      scene
    );
    skydome.position.y = 500;
    skydome.isPickable = false;
    skydome.receiveShadows = true;

    const texture = CubeTexture.CreateFromPrefilteredData(skyboxInput, scene);
    scene.environmentTexture = texture;

    // Sets the skydome in ground projection mode
    const sky = new BackgroundMaterial("skyMaterial", scene);
    sky.reflectionTexture = scene.environmentTexture.clone();
    if (sky.reflectionTexture) {
      sky.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    }
    sky.enableGroundProjection = true;
    sky.projectedGroundRadius = 20;
    sky.projectedGroundHeight = 3;
    skydome.material = sky;
  } else {
    loadBackground(scene);
  }

  enableCollisions(scene, camera);

  // if (!isBabylonInspectorShowing() && process.env.REACT_APP_SHOW_BABYLON_INSPECTOR) {
  //   import("babylonjs-inspector");
  //   // overlay: true does not create a parent div for the canvas. It just adds the inspector elements as siblings.
  //   scene.debugLayer.show({ overlay: true });
  // }

  // Return the created scene
  return scene;
};
