import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { GET_ALL_PERMISSIONS, PERMISSION } from 'src/constants/constants';


// Define types
interface PermissionState {
    permissions: any

}

// Define the initial state
const initialState: PermissionState = {
    permissions: {},
};

// Create async thunks for authentication operations
export const getAllPermissionsHandler = createAsyncThunk(
    GET_ALL_PERMISSIONS,
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get(PERMISSION + '/all');
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);


// Create the slice
const permissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPermissionsHandler.pending, (state) => {

            })
            .addCase(getAllPermissionsHandler.fulfilled, (state, action) => {
                state.permissions = action.payload
            })
            .addCase(getAllPermissionsHandler.rejected, (state, action: PayloadAction<any>) => {

            })

    }
});

export const { } = permissionSlice.actions
export default permissionSlice.reducer;