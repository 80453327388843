import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getLoggedInUserDetails } from "./userSlice";

export enum SUBSCRIPTION_STATUS {
    CREATED = 'created',
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    TRIALING = 'trialing',
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    CANCELED = 'canceled',
    UNPAID = 'unpaid'
}

export interface ISubscription {
    amount: string | null;
    status: SUBSCRIPTION_STATUS | null;
    temporaryExpiredAt?: string | Date | null;
    actualStartDate: string | Date | null;
    currentPeriodStartDate: string | Date | null;
    currentPeriodEndDate: string | Date | null;
    freeTrialEndDate: string | Date | null;
    cancellationDate: string | Date | null;
    isLoading: boolean;
}

const initialState: ISubscription = {
    amount: null,
    actualStartDate: null,
    cancellationDate: null,
    currentPeriodEndDate: null,
    currentPeriodStartDate: null,
    freeTrialEndDate: null,
    status: null,
    temporaryExpiredAt: null,
    isLoading: false
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setSubscriptionDetails: (state, { payload }: PayloadAction<Partial<ISubscription>>) => {
            return (state = {
                ...state,
                ...payload
            });
        }
    },
    extraReducers: (builder) => {
        // Set subscription data
        builder
            .addCase(getLoggedInUserDetails.pending, (state) => {
                return (state = {
                    ...state,
                    isLoading: true
                });
            })
            .addCase(getLoggedInUserDetails.fulfilled, (state, { payload }: PayloadAction<{ subscription: ISubscription }>) => {
                return (state = {
                    ...state,
                    ...payload?.subscription,
                    isLoading: false,
                });
            })
            .addCase(getLoggedInUserDetails.rejected, (state, { payload }: PayloadAction<any>) => {
                // toast.error(payload);
                return (state = {
                    ...state,
                    isLoading: false,
                });
            })
    }
});

export const {
    setSubscriptionDetails
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
export const getSubscriptionDetails = (state: RootState) => state?.subscription;