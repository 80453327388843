import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "src/common/axiosInstance";
import { handleErrors } from "src/common/commonUtils";
import { STRIPE_PAYMENT, TENANT_SUBSCRIPTION_CREATE } from "src/constants/constants";

export const createTenantSubscription = createAsyncThunk(
    TENANT_SUBSCRIPTION_CREATE,
    async (paymentMethodId: string, { rejectWithValue }) => {
        try {
            const response = await api.post(`${STRIPE_PAYMENT}${TENANT_SUBSCRIPTION_CREATE}`, {
                paymentMethodId
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);